import { IonChip } from '@ionic/react';
import { IPropsItemChips } from './props.item-chips';
import './style.scss';
export const ItemChips: React.FC<IPropsItemChips> = ({
    text,
    selected,
    onClick,
}) => {
    return (
        <IonChip
            onClick={onClick && onClick}
            className={'item-chips ' + (selected ? '--selected' : '')}
        >
            {text}
        </IonChip>
    );
};
