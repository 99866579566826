import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
    IonSpinner,
    useIonToast,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button';
import ItemInput from '../../components/item-input';
import { Toast } from '../../components/toast';
import { FooterPage } from '../../layout/footer-page';
import { HeaderSubPage } from '../../layout/Header/header-sub-page';
import { LoadingCenter } from '../../layout/loading-center';
import { setUserFullNameGlobalHeaders } from '../../modules/global-data';
import { connect } from '../../redux/connect';
import { setUserProfile } from '../../redux/user/user.actions';
import apis from '../../services';
import './style.scss';
interface DispatchProps{
    setUserProfile:typeof setUserProfile
}
const EditProfile: React.FC<any> = ({setUserProfile,userProfile}) => {
    const { t } = useTranslation('common');
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    const [dataUser, setDataUser] = useState<any>({
        data: null,
        loading: true,
        errorMessage: null,
    });
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [fileUpload, setFileUpload] = useState<any>();
    const [loadingImageUpload, setLoadingImageUpload] =
        useState<boolean>(false);
 
  
    const [present] = useIonToast();
    const getDataProfile = () => {
        apis.getProfileInfo()
            .then((response) => {
                setDataUser({
                    data: response.data?.account,
                    loading: false,
                    errorMessage: null,
                });
                setValue('first_name', response.data?.account.first_name);
                setValue('last_name', response.data?.account.last_name);
            })
            .catch(() => {
                setDataUser({ data: null, loading: false, errorMessage: null });
            });
    };
    useEffect(() => {
        getDataProfile();
    }, []);
    const getData = (_feildName: string) => {
        if (dataUser.loading == false && dataUser.errorMessage == null) {
            if (dataUser.data != null) {
                return dataUser.data[_feildName];
            }
        }
    };

    const onSubmit = (data: { first_name: string; last_name: string }) => {
        setLoadingSubmit(true);
        apis.updateProfileInfo({
            first_name: data.first_name,
            last_name: data.last_name,
        })
            .then((response) => {
                
                userProfile.fullName = data.first_name+" "+data.last_name;
                setUserFullNameGlobalHeaders(data.first_name+" "+data.last_name)

                setUserProfile(userProfile)
                Toast(present, response.data.success);
                setLoadingSubmit(false);
            })
            .catch(() => {
                setLoadingSubmit(false);

                Toast(present, t('Error_Occurred'));
            });
    };
    const uploadProfilePicutre = (fileInput: any) => {
        setLoadingImageUpload(false);
        if (!fileInput || !fileInput.files) {
            return;
        }
        if (fileInput.files.length === 0) {
            /*   setLoadingImg(false); */
            return;
        }
        const formData = new FormData();
        formData.append('picture_file', fileInput.files[0]);

        apis.uploadProfilePicutre(formData).then((res) => {
            getDataProfile();
            userProfile.avatar = res.data.path;
        setUserProfile(userProfile)
            setLoadingImageUpload(false);
        });
    };
    return (
        <IonPage className="edit-profile">
            <HeaderSubPage
                defaultHref="/page/settings"
                title={t('Edit_Profile')}
            ></HeaderSubPage>
            <IonContent className="content-color ion-padding">
                {dataUser.loading === false && dataUser.data != null ? (
                    <>
                        {' '}
                        <div className="image-div">
                            {loadingImageUpload ? (
                                <IonSpinner className="loading-upload-image"></IonSpinner>
                            ) : (
                                ''
                            )}

                            <div className="border-area">
                                <img
                                style={{pointerEvents:"auto"}}
                                    onClick={() => fileUpload.click()}
                                    className="image"
                                    src={
                                        dataUser.data.picture
                                            ? dataUser.data.picture?.path
                                            : '/assets/avatar.png'
                                    }
                                />

                                <input
                                    ref={(file: any) => setFileUpload(file)}
                                    hidden={true}
                                    onChange={(value: any) =>
                                        uploadProfilePicutre(value.target)
                                    }
                                    type="file"
                                />
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <IonRow>
                                <IonCol
                                    className="ion-no-padding ion-padding-end"
                                    size="6"
                                >
                                    <ItemInput
                                        error={
                                            errors && errors.last_name
                                                ? true
                                                : false
                                        }
                                        register={register('first_name', {
                                            required: true,
                                        })}
                                        lines="none"
                                        defaultValue={getData('first_name')}
                                        lable={t('First_Name')}
                                    />
                                </IonCol>
                                <IonCol className="ion-no-padding" size="6">
                                    <ItemInput
                                        error={
                                            errors && errors.last_name
                                                ? true
                                                : false
                                        }
                                        defaultValue={getData('last_name')}
                                        register={register('last_name', {
                                            required: true,
                                        })}
                                        lines="none"
                                        lable={t('List_Name')}
                                    />
                                </IonCol>
                            </IonRow>
                        </form>
                    </>
                ) : (
                    <LoadingCenter></LoadingCenter>
                )}
            </IonContent>
            <FooterPage>
                <Button
                    disabled={loadingSubmit}
                    loading={loadingSubmit}
                    text={t('Save_Changes')}
                    fill="fill"
                    onClick={handleSubmit(onSubmit)}
                    expand="full"
                    color="none"
                />
            </FooterPage>
        </IonPage>
    );
};

export default connect<{}, any, DispatchProps>({
    mapStateToProps: (state) => ({
        userProfile:state.user.isLoggedin &&
        state.user.userProfile != null &&
        state.user.userProfile != undefined &&
        state.user?.userProfile

        
    }),
    mapDispatchToProps: {
        setUserProfile,
  
     
    },
    component: React.memo(EditProfile),
});

