import {
    IonBadge,
    IonIcon,
    IonRoute,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
} from '@ionic/react';
import { heartOutline } from 'ionicons/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { useStorage } from '../../hooks/use-storage';
import CartBag from '../../pages/tab-demo-page/cart/cart-bag';
import Home from '../../pages/tab-demo-page/home/home';
import Profile from '../../pages/tab-demo-page/profile';
import Wishlist from '../../pages/tab-demo-page/wishlist/wishlist';
import Community from '../../pages/tab-demo-page/wow-community/community';
import './style.scss';
const TabDemo: React.FC = () => {
    // const statsCount = useSelector((state: any) => state?.statsCount);
    const { notificationStatus } = useStorage();

    return (
        <IonTabs className="tabs-demo">
            <IonRouterOutlet id="min">
                <Redirect from="/tab" to="/tab/home" exact />
                <IonRoute path="/tab/home" exact render={() => <Home></Home>} />
                <IonRoute
                    path="/tab/profile"
                    exact
                    render={() => <Profile></Profile>}
                />
                <IonRoute
                    path="/tab/wishlist"
                    exact
                    render={() => <Wishlist></Wishlist>}
                />
                <IonRoute
                    path="/tab/community"
                    exact
                    render={() => <Community></Community>}
                />
                <IonRoute
                    path="/tab/cart"
                    exact
                    render={() => <CartBag></CartBag>}
                />
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
                <IonTabButton href={'/tab/home'} tab="home">
                    <IonIcon
                        icon={
                            process.env.PUBLIC_URL +
                            '/assets/svg/icon-tabs/home-active.svg'
                        }
                    />
                </IonTabButton>

                <IonTabButton href="/tab/community" tab="community">
                    <IonIcon
                        icon={
                            process.env.PUBLIC_URL +
                            '/assets/svg/icon-tabs/community.svg'
                        }
                    />
                </IonTabButton>

                <IonTabButton href="/tab/cart" tab="cart">
                    <IonIcon
                        icon={
                            process.env.PUBLIC_URL +
                            '/assets/svg/icon-tabs/cart.svg'
                        }
                    />
                    {notificationStatus?.cart_count > 0 ? (
                        <IonBadge color="secondary">
                            {notificationStatus?.cart_count}
                        </IonBadge>
                    ) : (
                        ''
                    )}
                </IonTabButton>

                <IonTabButton href="/tab/wishlist" tab="wishlist">
                    <IonIcon icon={heartOutline} />
                    {notificationStatus?.wishlist_count > 0 ? (
                        <IonBadge color="secondary">
                            {notificationStatus?.wishlist_count}
                        </IonBadge>
                    ) : (
                        ''
                    )}
                </IonTabButton>

                <IonTabButton href={'/tab/profile'} tab="profile">
                    <IonIcon
                        icon={
                            process.env.PUBLIC_URL +
                            '/assets/svg/icon-tabs/user.svg'
                        }
                    />
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    );
};
export default TabDemo;
