import {
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonPage,
    useIonToast,
} from '@ionic/react';
import moment from 'moment';
import { Deeplinks } from '@ionic-native/deeplinks';
import React, { createRef, useEffect, useState } from 'react';

import { Refresher } from '../../../components/refresher';
import { Toast } from '../../../components/toast';
import { useInternetState } from '../../../hooks/useinternet-state';
import { ConnectionLost } from '../../../layout/errors/connection-lost';
import { ErrorRequest } from '../../../layout/errors/error-request';
import { HeaderMain } from '../../../layout/Header/header-main';
import { LoadingCenter } from '../../../layout/loading-center';
import apis from '../../../services';
import BestSelling from './component/best-selling';
import { HeaderSection } from './component/header-section';
import { ListCategory } from './component/list-category/index';
import RecentlyArrived from './component/recently-arrived';
import SliderItemHotOffers from './component/slider-item-hot-offers';
import { StoryModal } from './component/stories';
import TickerBar from './component/ticker-bar';
import { useTranslation } from 'react-i18next';
import { StoriesSlider } from './component/stories-slider';
import { getGlobalHeaders } from '../../../modules/global-data';
const Home: React.FC = () => {
    const { t } = useTranslation('common');
    const { internet }: any = useInternetState();
    const [present, dismiss] = useIonToast();
    const [loading, setLoading] = useState(false);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
    const refModalStories = createRef<any>();
    const lang = { lang_tag: getGlobalHeaders().lang_tag ?? 'ar' };
    // const lang = useSelector((state: any) => state.user?.settings);
    const [category, setCategory] = useState<any>({
        data: [],
        loading: true,
        errorMessage: null,
    });
    const [storyToday, setStoryToday] = useState<any>({
        data: [],
        loading: true,
        errorMessage: null,
    });
    const [recentlyArrived, setRecentlyArrived] = useState<any>({
        data: [],
        loading: true,
        errorMessage: null,
    });
    const [hotOffers, setHotOffers] = useState<any>({
        data: [],
        loading: true,
        errorMessage: null,
    });

    const [bestSelling, setBestSelling] = useState<any>({
        data: [],
        loading: true,
        errorMessage: null,
    });
    const [newsLines, setNewsLine] = useState({
        data: [],
        loading: true,
        errorMessage: null,
    });

    const callRequest = async () => {
        apis.getCategories()
            .then((respones) => {
                setCategory({
                    data: respones.data,
                    loading: false,
                    errorMessage: null,
                });
            })
            .catch((e) => {
                setCategory({
                    data: [],
                    loading: false,
                    errorMessage: e,
                });
            });

        apis.getNewsLines()
            .then((respones) => {
                setNewsLine({
                    data: respones.data,
                    loading: false,
                    errorMessage: null,
                });
            })
            .catch((e) => {
                newsLines.loading = false;
                newsLines.errorMessage = e?.message;
                setNewsLine(newsLines);
            });
        const getCategories = async () =>
            apis
                .getStoryToday()
                .then((respone) => {
                    setStoryToday({
                        data: respone.data,
                        loading: false,
                        errorMessage: null,
                    });
                })
                .catch((error) => {
                    setStoryToday({
                        loading: false,
                        errorMessage: 'error Request',
                    });
                });
        getCategories();
        apis.getProductByTag('recently-arrived')
            .then((respone) => {
                setRecentlyArrived({
                    data: respone.data,
                    loading: false,
                    errorMessage: null,
                });
                if (
                    respone.data.products.current_page ===
                    respone.data.products.last_page
                ) {
                    setDisableInfiniteScroll(true);
                } else {
                    setDisableInfiniteScroll(false);
                }
            })
            .catch((error) => {
                console.log('error reqesut' + error.message);
                setRecentlyArrived({
                    loading: false,
                    errorMessage: 'error Request',
                });
            });
        apis.getProductByTag('hot-offers')
            .then((respone) => {
                setHotOffers({
                    data: respone.data,
                    loading: false,
                    errorMessage: null,
                });
            })
            .catch((error) => {
                /*  alert(error.response?.data?.message); */
                console.log('error reqesut2' + error);
                setHotOffers({ loading: false, errorMessage: 'error Request' });
            });
        apis.getProductByTag('best-selling')
            .then((respone) => {
                setBestSelling({
                    data: respone.data,
                    loading: false,
                    errorMessage: null,
                });
            })
            .catch((error) => {
                setBestSelling({
                    loading: false,
                    errorMessage: 'error Request',
                });
            });
    };
    useEffect(() => {
        setLoading(true);
        callRequest().finally(() => {
            setLoading(false);
        });
    }, []);
    const onReloadRequest = () => {
        setLoading(true);
        callRequest()
            .finally(() => {
                setLoading(false);
                Toast(present, t('Error_Occurred'), 1500, 'error');
            })
            .catch(() => {});
    };
    useEffect(() => {
        Deeplinks.route({}).subscribe(
            (match: any) => {
                // match.$route - the route we matched, which is the matched entry from the arguments to route()
                // match.$args - the args passed in the link
                // match.$link - the full link data
                console.log('Successfully matched route', match);
            },
            (nomatch: any) => {
                // nomatch.$link - the full link data
                console.error("Got a deeplink that didn't match", nomatch);
            }
        );
    }, [Deeplinks]);
    const ShowModalStories = (data: any) => {
        if (refModalStories?.current && data) {
            let dataStory: any = [];
            data.map((data: any, index: number) => {
                dataStory.push({
                    id: data.id,
                    slug: data?.product?.slug,
                    url: data.post_media?.path,
                    type:
                        data.post_media.mime == 'video/mp4' ? 'video' : 'image',
                    header: {
                        heading: data.title,
                        subheading: moment(data.created_at).fromNow(),
                        profileImage:
                            data.category?.logo.path ??
                            'https://picsum.photos/100/100',
                    },
                });
            });
            refModalStories.current.ShowModalStories(
                dataStory
                //     {
                //         url: data.post_media?.path,
                //         type:
                //             data.post_media.mime == 'video/mp4' ? 'video' : 'image',
                //         header: {
                //             heading: data.title,
                //             subheading: moment(data.created_at).fromNow(),
                //             profileImage:
                //                 data.category?.logo.path ??
                //                 'https://picsum.photos/100/100',
                //         },
                //     },
                // ]
            );
        }
    };
    const searchNext = (event: any) => {
        if (!recentlyArrived?.data?.products?.data) {
            event.target.complete();
            setDisableInfiniteScroll(true);
            return;
        }
        let dataProducts: any = [];
        dataProducts = [...recentlyArrived.data.products.data];
        if (
            recentlyArrived.data?.products.current_page <
            recentlyArrived.data.products?.last_page
        ) {
            apis.getProductByTag(
                `recently-arrived`,
                `?page=${recentlyArrived.data?.products.current_page + 1}`
            ).then((response) => {
                for (let i = 0; i < response.data.products.data.length; i++) {
                    dataProducts.push(response.data.products.data[i]);
                }
                //sett(response.data.products.data.length);
                response.data.products.data = dataProducts;
                setRecentlyArrived({
                    data: response.data,
                    loading: false,
                    errorMessage: null,
                });
                event.target.complete();
            });
        } else {
            event.target.disabled = true;
            setDisableInfiniteScroll(true);
        }
    };

    return (
        <IonPage>
            <HeaderMain title={t('Home')} subTitle={t('Welcome_Guest')} />

            {internet ? (
                storyToday.errorMessage == null &&
                recentlyArrived.errorMessage == null &&
                hotOffers.errorMessage == null &&
                bestSelling.errorMessage == null &&
                newsLines.errorMessage == null ? (
                    <IonContent className="content-color">
                        <Refresher Refresh={callRequest} />
                        {storyToday.loading == false &&
                        bestSelling.loading == false &&
                        hotOffers.loading == false &&
                        recentlyArrived.loading == false &&
                        newsLines.loading == false ? (
                            <>
                                <br />
                                <StoriesSlider
                                    onClick={ShowModalStories}
                                    stories={Object.values(storyToday.data)}
                                />
                                {/* <ListCategory
                                    onClick={ShowModalStories}
                                    category={Object.values(storyToday.data)}
                                /> */}

                                <ListCategory
                                    category={category.data?.categories}
                                ></ListCategory>
                                {newsLines.data.length > 0 ? (
                                    <TickerBar listNews={newsLines.data} />
                                ) : (
                                    ''
                                )}
                                {bestSelling.data.products?.data?.length >
                                    0 && (
                                    <HeaderSection
                                        routerLink={
                                            '/page/all-product/best-selling'
                                        }
                                        title={t('Best_Selling')}
                                    />
                                )}
                                <BestSelling
                                    lang={lang?.lang_tag}
                                    item={bestSelling.data.products?.data}
                                />
                                {hotOffers.data.products?.data?.length > 0 && (
                                    <HeaderSection
                                        routerLink={
                                            '/page/all-product/hot-offers'
                                        }
                                        title={t('Hot_Offers')}
                                    />
                                )}
                                <SliderItemHotOffers
                                    lang={lang?.lang_tag}
                                    item={hotOffers.data.products?.data}
                                />
                                {recentlyArrived.data.products?.data?.length >
                                    0 && (
                                    <HeaderSection
                                        routerLink={
                                            '/page/all-product/recently-arrived'
                                        }
                                        title={t('Recently_Arrived')}
                                    />
                                )}
                                <RecentlyArrived
                                    lang={lang?.lang_tag}
                                    sizeSm="12"
                                    sizeXs="12"
                                    sizeLg="4"
                                    sizeMd="6"
                                    sizeXl="3"
                                    item={recentlyArrived.data.products?.data}
                                />
                            </>
                        ) : (
                            <LoadingCenter />
                        )}
                        <IonInfiniteScroll
                            threshold="100px"
                            hidden={false}
                            disabled={disableInfiniteScroll}
                            onIonInfinite={(e: CustomEvent<void>) =>
                                searchNext(e)
                            }
                        >
                            <IonInfiniteScrollContent
                                loadingText={t('loadingMore')}
                            ></IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                    </IonContent>
                ) : (
                    <ConnectionLost
                        loading={loading}
                        onReloadRequset={onReloadRequest}
                    />
                )
            ) : (
                <ErrorRequest
                    loading={loading}
                    onReloadRequset={onReloadRequest}
                ></ErrorRequest>
            )}
            <StoryModal ref={refModalStories} />
        </IonPage>
    );
};
export default React.memo(Home);
