import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { InfiniteScroll } from '../../components/Infinite-scroll';
import { Refresher } from '../../components/refresher';
import { HeaderSubPage } from '../../layout/Header/header-sub-page';
import { LoadingCenter } from '../../layout/loading-center';
import { getGlobalHeaders } from '../../modules/global-data';
import apis from '../../services';
import CardItem from '../tab-demo-page/home/component/model/card-item';
import './style.scss';
interface IParams {
    id: string;
}
const AllProduct: React.FC = () => {
    const [listData, setListData] = useState<any>({
        data: [],
        loading: true,
        errorMessage: null,
    });
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);

    const { id } = useParams<IParams>();
    const featchData = async () => {
        await apis
            .getProductByTag(id)
            .then((respone) => {
                setListData({
                    data: respone.data,
                    loading: false,
                    errorMessage: null,
                });
                if (
                    respone.data.products.current_page ===
                    respone.data.products.last_page
                ) {
                    setDisableInfiniteScroll(true);
                } else {
                    setDisableInfiniteScroll(false);
                }
            })
            .catch((e) => {
                listData.errorMessage = e;
                listData.loading = false;
                setListData(listData);
            });
    };
    useEffect(() => {
        featchData();
    }, []);

    const lang = getGlobalHeaders().lang_tag ?? 'ar';
    const searchNext = (event: any) => {
        if (
            listData.data?.products.current_page <
                listData.data?.products.last_page &&
            listData.data?.products.from != null
        ) {
            let dataOld = [...listData.data.products.data];
            setDisableInfiniteScroll(false);
            apis.getProductByTag(
                `${id}`,
                `?page=${listData.data?.products.current_page + 1}`
            ).then((response) => {
                for (let i = 0; i < response.data.products.data.length; i++) {
                    dataOld.push(response.data.products.data[i]);
                }
                response.data.products.data = dataOld;
                setListData({
                    data: response.data,
                    loading: false,
                    errorMessage: null,
                });

                event.target.complete();
                setDisableInfiniteScroll(false);
            });
        } else {
            event.target.disabled = true;
        }
    };
    return (
        <IonPage className="all-product ">
            <HeaderSubPage title={''} defaultHref="/tab/home"></HeaderSubPage>
            <IonContent className="content-color">
                <Refresher Refresh={featchData}></Refresher>
                {listData.loading == false ? (
                    <IonGrid>
                        <IonRow>
                            {listData.data?.products?.data.map(
                                (data: any, index: number) => {
                                    return (
                                        <IonCol
                                            key={index}
                                            sizeXs={'6'}
                                            sizeSm={'6'}
                                            sizeMd={'4'}
                                            sizeLg={'3'}
                                        >
                                            <CardItem
                                                lang={lang}
                                                type="sm"
                                                slug={data.slug}
                                                name={data.name}
                                                category={''}
                                                img={
                                                    data.base_image?.path ?? ''
                                                }
                                                is_dis={
                                                    data.special_price == null
                                                        ? 0
                                                        : 1
                                                }
                                                is_wishlist={false}
                                                price={
                                                    data.price
                                                        ?.inCurrentCurrency
                                                        ?.amount
                                                }
                                                price_discount={
                                                    data.selling_price
                                                        ?.inCurrentCurrency
                                                        ?.amount
                                                }
                                            />
                                        </IonCol>
                                    );
                                }
                            )}
                        </IonRow>
                    </IonGrid>
                ) : (
                    <LoadingCenter></LoadingCenter>
                )}
                <InfiniteScroll
                    disableInfiniteScroll={disableInfiniteScroll}
                    searchNext={searchNext}
                ></InfiniteScroll>
            </IonContent>
        </IonPage>
    );
};
export default React.memo(AllProduct);
