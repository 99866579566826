import {
    IonBadge,
    IonButton,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonPage,
    useIonToast,
} from '@ionic/react';
import {
    chatbubbleEllipsesOutline,
    heartOutline,
    shareSocialOutline,
} from 'ionicons/icons';
import React, {
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { ButtonWithIcon } from '../../components/button-text-icon';
import { FooterPage } from '../../layout/footer-page';
import { HeaderProduct } from './component/header-product';
import Button from '../../components/button/index';
import './style.scss';
import { Quantity } from './component/quantity';
import { SizeChip } from './component/size-chip';
import { ColorListPiker } from './component/color-list-piker';
import { ProductDetails } from './component/product-details';
import { HeaderPorduct } from '../../layout/Header/header-product';
import { Modal } from '../../components/modal';
import apis from '../../services';
import { useHistory, useParams } from 'react-router';
import { LoadingCenter } from '../../layout/loading-center';
import { Toast } from '../../components/toast';
import { BodyReviews } from './component/body-review';
import { addToCartDispatch } from '../../redux/cart/cart.actions';
import {
    addToWishlistDispatch,
    deleteFromWishlistDispatch,
} from '../../redux/favorite/favorite.actions';
import { BodyShowImages } from '../../layout/body-show-images';
import {
    decreaseToStatsWishlist,
    increaseToStatsCart,
    increaseToStatsWishlist,
} from '../../redux/stats/stats.actions';
import { useTranslation } from 'react-i18next';
import { HeaderSection } from '../tab-demo-page/home/component/header-section';
import SliderItemHotOffers from '../tab-demo-page/home/component/slider-item-hot-offers';
import { useQuery } from '../../hooks/use-query';
import { PHONE_NUMBER_SUPPORT } from '../../helpers/cons';
import { SliderImage } from '../../components/slider-img';

import { getGlobalHeaders } from '../../modules/global-data';
import { useAuth } from '../../hooks/use-auth';
import { useStorage } from '../../hooks/use-storage';
import { connect } from '../../redux/connect';
import { Share } from '../../helpers/util/native/share';
interface DispatchProps {
    addToWishlistDispatch: typeof addToWishlistDispatch;
    deleteFromWishlistDispatch: typeof deleteFromWishlistDispatch;
    decreaseToStatsWishlist: typeof decreaseToStatsWishlist;
    increaseToStatsCart: typeof increaseToStatsCart;
    increaseToStatsWishlist: typeof increaseToStatsWishlist;
    addToCartDispatch: typeof addToCartDispatch;
}
let reviews = 0;
const Product: React.FC<any> = ({
    addToWishlistDispatch,
    deleteFromWishlistDispatch,
    decreaseToStatsWishlist,
    increaseToStatsCart,
    increaseToStatsWishlist,
    addToCartDispatch,
    wishlistLocalStorage,
    statsCount,
}) => {
    // useSelector((state: any) => state?.statsCount);

    const { t } = useTranslation('common');
    const refComment = useRef<any>();
    const { slug }: any = useParams<string>();
    let query = useQuery();
    const { isAuthenticated, dispatch } = useAuth();

    const [present, dismiss] = useIonToast();
    const [laoding, setLoading] = useState(false);
    const [likeWishlistCount, setLikeWishlistCount] = useState(0);
    const [options, setOptions] = useState<any>({});
    const [ta, ta1] = useState(1);
    const [numberImage, setNumberImage] = useState(0);

    const isLogin = isAuthenticated;

    //  useSelector(
    //     (state: any) => state?.wishlistData
    // );
    const lang = { lang_tag: getGlobalHeaders().lang_tag ?? 'ar' };
    const [isInUserWishlist, setIsInUserWishlist] = useState(false);

    const refShowIamges = useRef<any>();
    const [product, setProduct] = useState<any>({
        data: {},
        loading: true,
        errorMessage: null,
    });
    const [related, setRelated] = useState([]);
    const onShowComment = () => {
        if (refComment.current) {
            refComment.current.showModal();
        }
    };
    const history = useHistory();
    const slideRef = useRef<any | null>(null);

    const updateVariantsQty = (selectedOptionId:any = null)=>{
        if(product.data?.options){
            product.data?.options.forEach((option:any) => {
                if(!selectedOptionId || option.id !== selectedOptionId){
                    option.values.forEach((optionVal:any) => {
                        optionVal.enabled = hasAnyQtyInAllVarients(optionVal);
                    });
                }
            });
            //objArray.map(a => a.foo);
        }
    }
    const disableSoldVarients = (selectedOptionValId :any,selectedOptionId :any)=>{
        updateVariantsQty(selectedOptionId);

        product.data?.variants2.forEach((variant:any) => {
            if(variant.qty <= 0){
                let variantValues = JSON.parse(variant.values);
                if (variantValues.filter(function(variantValue:any) { return variantValue.valueId === selectedOptionValId; }).length > 0) {
                    variantValues.forEach((variantValue:any) => {
                        if(variantValue.valueId !== selectedOptionValId){
                            //Disable these values 
                            product.data?.options.forEach((option:any) => {
                                option.values.forEach((optionVal:any) => {
                                    if(optionVal.id === variantValue.valueId){
                                        optionVal.enabled = false;
                                    }
                                });
                            });
                        }
                    });
                }
            }
        });
    }
    const hasAnyQtyInAllVarients = (optionVal :any)=>{
        let hasAnyQty = false;
        product.data?.variants2.forEach((variant:any) => {
            if(variant.qty > 0 ){
                let variantValues = JSON.parse(variant.values);
                variantValues.forEach((variantValue:any) => {
                    if(variantValue.valueId === optionVal.id){
                        hasAnyQty = true;
                    }
                });
            }
        });
        return hasAnyQty;
    }
    const equalsIgnoreOrder = (a :any, b:any) => {
        if (a.length !== b.length) return false;
        const uniqueValues = Array.from(new Set([...a, ...b]));
        for (const v of uniqueValues) {
          const aCount = a.filter((e:any) => e === v).length;
          const bCount = b.filter((e:any) => e === v).length;
          if (aCount !== bCount) return false;
        }
        return true;
      }
    useEffect(() => {
        if (!query.get('related_slug') && query.get('related_slug') === null) {
            apis.getProduct(slug)
                .then((respone) => {
                    if (respone.data?.product) {
                        if (isLogin) {
                            setIsInUserWishlist(
                                respone.data.product.isInUserWishlist
                            );
                            setLikeWishlistCount(
                                respone.data.product.wishlists_count
                            );
                        } else {
                            if (
                                wishlistLocalStorage.find(
                                    (x: { id: string }) =>
                                        x.id === respone.data?.product.id
                                )?.id === respone.data?.product.id
                            ) {
                                setLikeWishlistCount(
                                    respone.data.product.wishlists_count + 1
                                );
                                setIsInUserWishlist(true);
                            } else {
                                setLikeWishlistCount(
                                    respone.data.product.wishlists_count
                                );
                                setIsInUserWishlist(false);
                            }
                        }
                        reviews = respone.data.review.count;
                        setProduct({
                            data: respone.data.product,
                            loading: false,
                            errorMessage: null,
                        });
                        setRelated(respone.data.relatedProducts);
                    }
                })
                .catch((error) => {
                    Toast(present, t('Error_Occurred'), 1400, 'error');
                    setProduct({
                        loading: false,
                        errorMessage: 'error In get',
                    });
                });
        }
    }, []);
    useEffect(()=>{
        updateVariantsQty();
    },[product]);
    const onSelectOption = (optionsValue: any, key: string, reference: any) => {
        disableSoldVarients(optionsValue[key],key);
      /*  console.log({
            optionsValue,
            key,
            reference,
            selectedOptions:options
        })*/
        if (reference) {
            if (slideRef.current && product.data?.files) {
                const slideFileIndex = product.data?.files.findIndex(
                    (item: any) => item?.path == reference
                );
                slideRef.current.slideTo(slideFileIndex);
            }
        }
        if (optionsValue[key] == options[key]) {
            ta1(2);
        } else {
            ta1((re) => re + 1);
            setOptions(Object.assign(options, optionsValue));
        }
    };

    const [quantity, setQuantity] = useState(1);

    const onDecrease = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };
    const onIncrease = () => {
        if (
            (!product.data?.qty && product.data?.qty !== 0) ||
            product.data?.qty > quantity
        ) {
            setQuantity(quantity + 1);
        }
    };

    const addToCart = (product_id: number) => {
        if (isLogin) {
            if (product.data && product.loading == false) {
                setLoading(true);
                apis.addItemToCart({
                    product_id: product_id,
                    qty: quantity,
                    options: options,
                })
                    .then((respone) => {
                        if (respone.data) {
                            Toast(present, t('Added_Successfully'), 1400);
                        }
                        increaseToStatsCart();
                    })
                    .finally(() => {
                        setLoading(false);
                    })
                    .catch((error) => {
                        Toast(present, t('Error_Occurred'), 1400, 'error');
                    });
                if (
                    product.data?.options.length == Object.keys(options).length
                ) {
                    if (product.data?.qty > quantity) {
                    }
                }
            }
        } else {
            increaseToStatsCart();
            addToCartDispatch(product.data, quantity, options);
            Toast(present, t('Added_Successfully'), 1400);
        }
    };
    const addProductWishlist = (productId: number) => {
        if (isLogin) {
            if (!isInUserWishlist) {
                setLikeWishlistCount((pre) => pre + 1);
                setIsInUserWishlist(true);

                apis.addWishlistProduct(productId)
                    .then(() => {
                        increaseToStatsWishlist(1);
                    })
                    .catch((error) => {
                        Toast(present, t('Error_Occurred'), 1400, 'error');
                    });
            } else {
                setLikeWishlistCount((pre) => pre - 1);
                setIsInUserWishlist(false);
                apis.deleteWishlistProduct(productId)
                    .then(() => {
                        decreaseToStatsWishlist();
                    })
                    .catch((error) => {
                        console.log(error);
                        Toast(present, t('Error_Occurred'), 1400, 'error');
                    });
            }
        } else {
            var wih = [];
            for (let i = 0; i < wishlistLocalStorage.length; i++) {
                if (wishlistLocalStorage[i].id === product.data.id) {
                    wih.push(wishlistLocalStorage[i].id);
                }
            }
            if (wih.length == 0) {
                try {
                    addToWishlistDispatch(product.data);
                    setLikeWishlistCount((pre) => pre + 1);
                    setIsInUserWishlist(!isInUserWishlist);
                    increaseToStatsWishlist(0.5);
                } catch (e) {
                    console.log(e);
                }
            } else {
                setLikeWishlistCount((pre) => pre - 1);
                setIsInUserWishlist(!isInUserWishlist);
                deleteFromWishlistDispatch(product.data);
                decreaseToStatsWishlist();
            }
        }
    };

    const showModalImage = (index: number, images: any) => {
        if (refShowIamges.current) {
            refShowIamges.current.showModal();
            setNumberImage(index);
        }
    };

    const onClickOpenRelated = (idSlug: string) => {
        history.replace(`/page/product/${slug}?related_slug=` + idSlug, {
            preventLastLocation: true,
        });
    };
    useLayoutEffect(() => {
        if (query.get('related_slug') && query.get('related_slug') !== null) {
            setProduct({ laoding: true, data: {}, errorMessage: null });
            apis.getProduct(query.get('related_slug') ?? '')

                .then((respone) => {
                    if (respone.data?.product) {
                        if (isLogin) {
                            setIsInUserWishlist(
                                respone.data.product.isInUserWishlist
                            );
                            setLikeWishlistCount(
                                respone.data.product.wishlists_count
                            );
                        } else {
                            if (
                                wishlistLocalStorage.find(
                                    (x: { id: string }) =>
                                        x.id === respone.data?.product.id
                                )?.id === respone.data?.product.id
                            ) {
                                setLikeWishlistCount(
                                    respone.data.product.wishlists_count + 1
                                );
                                setIsInUserWishlist(true);
                            } else {
                                setLikeWishlistCount(
                                    respone.data.product.wishlists_count
                                );
                                setIsInUserWishlist(false);
                            }
                        }
                        reviews = respone.data.review.count;

                        setProduct &&
                            setProduct({
                                data: respone.data.product,
                                loading: false,
                                errorMessage: null,
                            });
                        setRelated(respone.data.relatedProducts);
                    }
                })
                .catch((error) => {
                    Toast(present, t('Error_Occurred'), 1400, 'error');
                    setProduct({
                        loading: false,
                        errorMessage: 'error In get',
                    });
                });
        }
    }, [query.get('related_slug')]);
    const onShare = (slug: string, name: string) => {
        Share(
            name ?? ' product',
            'share product : ' + name,
            'https://turkishwow.com/page/product/' + slug
        );
    };
    const refLink = useRef<any>();

    return (
        <IonPage className="product">
            <HeaderPorduct defaultHref="/tab/home" title={''} />
            <IonContent fullscreen className="content-color">
                {product.loading == false && product.data ? (
                    <React.StrictMode>
                        <div>
                            <SliderImage
                                ref={slideRef}
                                onShowImage={showModalImage}
                                files={product.data?.files}
                            />
                            <IonItem
                                color="none"
                                lines="none"
                                className="item-footer"
                            >
                                <ButtonWithIcon
                                    onClick={() =>
                                        addProductWishlist(product.data.id)
                                    }
                                    icon={heartOutline}
                                    text={likeWishlistCount.toString()}
                                    state={isInUserWishlist}
                                />

                                <ButtonWithIcon
                                    onClick={onShowComment}
                                    icon={chatbubbleEllipsesOutline}
                                    text={reviews?.toString()}
                                    state={false}
                                />
                                <ButtonWithIcon
                                    onClick={() =>
                                        onShare(slug, product.data?.name)
                                    }
                                    icon={shareSocialOutline}
                                    text="0"
                                    state={false}
                                />
                            </IonItem>
                        </div>
                        <div className="ion-padding">
                            <HeaderProduct
                                lang={lang.lang_tag}
                                is_discount={
                                    product.data?.special_price == null ? 0 : 1
                                }
                                price={product.data?.price?.formatted}
                                discount_price={
                                    product.data?.selling_price?.formatted
                                }
                                category={product.data?.categories[0]?.name}
                                title={product.data?.name ?? t('No_title')}
                            ></HeaderProduct>
                            <div>
                                <h2 className="title">{t('Quantity')}</h2>
                                <Quantity
                                    lang={lang.lang_tag}
                                    value={quantity}
                                    onDecrease={onDecrease}
                                    onIncrease={onIncrease}
                                />
                            </div>
                            {product.data?.options.map(
                                (data: any, index: number) => {
                                    var sizeOption =
                                        product.data?.options.length;
                                    return (
                                        <div key={index}>
                                            <h2 className="ion-text-capitalize title">
                                                {' '}
                                                {data.name}
                                            </h2>
                                            {data.values.filter((vv :any)=> vv.enabled).map(
                                                (
                                                    dataValue: any,
                                                    index: number
                                                ) => {
                                                    return !dataValue.code ? (
                                                        <SizeChip
                                                            onClick={() =>
                                                                onSelectOption(
                                                                    {
                                                                        [dataValue.option_id]:
                                                                            dataValue.id,
                                                                    },
                                                                    dataValue.option_id,
                                                                    dataValue.reference
                                                                )
                                                            }
                                                            key={index}
                                                            name={
                                                                dataValue.label
                                                            }
                                                            state={
                                                                options[
                                                                    dataValue
                                                                        .option_id
                                                                ] ==
                                                                dataValue.id
                                                                    ? 'active'
                                                                    : ''
                                                            }
                                                        ></SizeChip>
                                                    ) : (
                                                        <ColorListPiker
                                                            lang={lang.lang_tag}
                                                            onClick={() =>
                                                                onSelectOption(
                                                                    {
                                                                        [dataValue.option_id]:
                                                                            dataValue.id,
                                                                    },
                                                                    dataValue.option_id,
                                                                    dataValue.reference
                                                                )
                                                            }
                                                            color={
                                                                dataValue.code
                                                            }
                                                            check={
                                                                options[
                                                                    dataValue
                                                                        .option_id
                                                                ] ==
                                                                dataValue.id
                                                                    ? true
                                                                    : false
                                                            }
                                                            enabled={dataValue.enabled ? true : false}
                                                            key={index}
                                                        />
                                                    );
                                                }
                                            )}
                                            {/*         <SizeChip name="XL" state="active"></SizeChip> */}
                                            {/*               <SizeChip name="MD" state=""></SizeChip> */}
                                        </div>
                                    );
                                }
                            )}
                            {/*     <div>
        <h2 className="title"> size</h2>
          <SizeChip name="XL" state="active"></SizeChip>
          <SizeChip name="MD" state=""></SizeChip>

        </div> */}
                            {/*      <div>
        <h2 className="title">color</h2>
          <ColorListPiker color="red"/>
          <ColorListPiker color="#EE417F" />
          <ColorListPiker color="#00d0ff" check={true} />
        </div> */}
                            <div>
                                <br />
                                {product.data?.attributes.length > 0 ? (
                                    <h2 className="title">
                                        {t('Product_Attributes')}
                                    </h2>
                                ) : (
                                    ''
                                )}

                                {product.data?.attributes.map(
                                    (data: any, index: number) => {
                                        return (
                                            <div key={index}>
                                                <p>
                                                    <IonBadge
                                                        className="badge-attributes"
                                                        color="light"
                                                    >
                                                        {data.name}:{' '}
                                                    </IonBadge>{' '}
                                                    {data.values.map(
                                                        (
                                                            dataValue: any,
                                                            ind: number
                                                        ) => {
                                                            return (
                                                                <IonBadge
                                                                    color="pink"
                                                                    className="badge-value"
                                                                    key={ind}
                                                                >
                                                                    {dataValue.value +
                                                                        ''}
                                                                </IonBadge>
                                                            );
                                                        }
                                                    )}
                                                </p>
                                            </div>
                                        );
                                    }
                                )}

                                {product.data.description.length > 0 ? (
                                    <h2 className="title">
                                        {t('Product_Detalis')}
                                    </h2>
                                ) : (
                                    ''
                                )}

                                <ProductDetails
                                    dangerouslySetInnerHTML={{
                                        __html: product.data?.description,
                                    }}
                                />
                            </div>
                            <IonItem
                                onClick={() => refLink.current.click()}
                                lines="none"
                                color="none"
                                style={{
                                    background: '#f9f9f9',
                                    borderBottom: '1.2px solid #e0e0e0',
                                }}
                            >
                                <IonLabel>
                                    <h2 className="item-content-h">
                                        {t('contentWithSupport')}{' '}
                                    </h2>
                                    <p className="item-content-p">whatsup</p>
                                </IonLabel>
                                <IonIcon
                                    icon={
                                        process.env.PUBLIC_URL +
                                        '/assets/svg/whatsapp.svg'
                                    }
                                ></IonIcon>
                            </IonItem>
                            <a
                                hidden
                                ref={refLink}
                                href={`https://api.whatsapp.com/send?phone=${PHONE_NUMBER_SUPPORT}&text=${slug}اريد الاستفسار عن المنتج التالي `}
                            ></a>
                            {related.length > 0 && (
                                <HeaderSection title={t('related_product')} />
                            )}
                            <SliderItemHotOffers
                                onClick={onClickOpenRelated}
                                lang={lang.lang_tag}
                                item={related}
                            />
                        </div>
                    </React.StrictMode>
                ) : (
                    <LoadingCenter />
                )}
            </IonContent>
            {product.loading == false && product.data ? (
                <FooterPage>
                    <div style={{ display: 'flex' }}>
                        <IonButton
                            routerLink="/tab/cart"
                            color="light"
                            hidden={!(statsCount?.cart_count > 0)}
                        >
                            <IonIcon
                                icon={
                                    process.env.PUBLIC_URL +
                                    '/assets/svg/icon-tabs/cart.svg'
                                }
                            />
                            {statsCount?.cart_count > 0 ? (
                                <IonBadge color="secondary">
                                    {statsCount?.cart_count}
                                </IonBadge>
                            ) : (
                                ''
                            )}
                        </IonButton>

                        {product.data?.in_stock ? (
                            <Button
                                width="100%"
                                loading={laoding}
                                disabled={
                                    laoding == false
                                        ? product.data?.options.length ==
                                          Object.keys(options).length
                                            ? false
                                            : true
                                        : true
                                }
                                text={
                                    product.data?.options.length ==
                                    Object.keys(options).length
                                        ? t('ADD_TO_BAG')
                                        : Object.keys(options).length === 0
                                        ? 'Select ' +
                                          product.data?.options[0]?.name
                                        : // : product.data?.options?.filter(
                                          //       (x: any) =>
                                          //           x.id !==
                                          //           Object.keys(options)[0]
                                          //   )?.name

                                          'Select ' +
                                          product.data?.options?.find(
                                              (x: any) =>
                                                  x.id !==
                                                  Number(
                                                      Object.keys(options)[0]
                                                  )
                                          )?.name
                                }
                                color="none"
                                onClick={() => addToCart(product.data?.id)}
                                height={38}
                                expand="full"
                            />
                        ) : (
                            <Button
                                expand="full"
                                width="100%"
                                background="#6A7EC4"
                                disabled={true}
                                color="none"
                                text={t('Out_of_stock')}
                            ></Button>
                        )}
                    </div>
                </FooterPage>
            ) : (
                ''
            )}
            {product.loading == false && product.data ? (
                <Modal
                    isLoggedin={isLogin}
                    userProfile={{ id: '1' }} //userProfile
                    Body={BodyReviews}
                    idProduct={product.data?.id}
                    ref={refComment}
                ></Modal>
            ) : (
                ''
            )}
            {useMemo(() => {
                return (
                    <Modal
                        Body={BodyShowImages}
                        numberImage={numberImage}
                        image={product.data?.files}
                        ref={refShowIamges}
                    ></Modal>
                );
            }, [numberImage, product])}
        </IonPage>
    );
};
export default connect<{}, any, DispatchProps>({
    mapStateToProps: (state) => ({
        darkMode: state.user.darkMode,
        userProfile: state.user.userProfile,
        isAuthenticated: state.user.isLoggedin,
        wishlistLocalStorage: state.wishlistData,
        localLogin:
            state.user.isLoggedin &&
            state.user.userProfile != null &&
            state.user.userProfile != undefined &&
            state.user.userProfile.localLogin == true,
        statsCount: state.statsCount,
    }),
    mapDispatchToProps: {
        addToWishlistDispatch,
        deleteFromWishlistDispatch,
        decreaseToStatsWishlist,
        increaseToStatsCart,
        increaseToStatsWishlist,
        addToCartDispatch,
    },
    component: React.memo(Product),
});
