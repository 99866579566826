import { IonContent, IonText } from "@ionic/react";
import React from "react";
import { ReactSVG } from "react-svg";
import { FooterPage } from "../../footer-page";
import Button from "../../../components/button";
import { useTranslation } from "react-i18next";
export const ErrorRequest: React.FC<any> = ({ loading, onReloadRequset }) => {
  const { t } = useTranslation("common");
  return (
    <>
      <IonContent className="content-color">
        <div className="order-placed">
          <ReactSVG
            wrapper="span"
            className="wrapper-class-name"
            evalScripts="always"
            beforeInjection={(svg) => {
              svg.classList.add("svg-class-name");
              svg.setAttribute("style", "width: 200px");
              svg.setAttribute("style", "height: 200px");
            }}
            src={process.env.PUBLIC_URL + "/assets/svg/delivery-box.svg"}
          />
          {/*             <ReactSVG  className="img" src={process.env.PUBLIC_URL + "/assets/svg/internet-connection.svg"} />
           */}{" "}
          <div className="title">
            <IonText className="text">{t("Request_Error")}</IonText>
          </div>
          {/*    <div className="subtitle">
            <IonText className="text">Error Requests !</IonText>
          </div> */}
        </div>
      </IonContent>
      <FooterPage>
        <Button
          loading={loading}
          onClick={onReloadRequset}
          expand="full"
          background="#6A7EC4"
          color="none"
          text={t("TRY_AGAIN")}
        ></Button>
      </FooterPage>
    </>
  );
};
