import authApis from './auth-apis';
import accountApi from './account-apis';
import productsApis from './products-apis';
import categorysApis from './categories-apis';
import commentsApis from './comments-apis';
import tagsApis from './tags-apis';
import axios from 'axios';
import { getGlobalHeaders } from '../modules/global-data';
import cartApis from './cart-apis';
import wishlistApis from './wishlist-apis';
import checkoutApis from './checkout-apis';
import storyApis from './story-apis';
import locationApis from './location-apis';
import newsLineApis from './news-line';
export const axiosApi = axios.create({
    baseURL: `https://turkishwow.com/api/`,
    headers: {
        Authorization:
            getGlobalHeaders().access_token || localStorage.getItem('token')
                ? 'Bearer ' + getGlobalHeaders().access_token ??
                  localStorage.getItem('token')
                : undefined,
        Accept: 'application/json',
    },

    withCredentials: false,
});

const apis = {
    ...authApis,
    ...accountApi,
    ...productsApis,
    ...categorysApis,
    ...commentsApis,
    ...tagsApis,
    ...cartApis,
    ...wishlistApis,
    ...checkoutApis,
    ...storyApis,
    ...locationApis,
    ...newsLineApis,
};
export default apis;
