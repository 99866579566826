import axios from "axios";
import { axiosApi } from ".";
interface AddCommentToProductParameter {
  body: string;
}
const getProductComments = (productId: number) => {
  return axiosApi.get(`/comments/${productId}`);
};
const addCommentToProduct = (
  productId: number,
  body: AddCommentToProductParameter
) => {
  return axiosApi.post(`/comments/${productId}`, body);
};

const commentsApis = { getProductComments, addCommentToProduct };
export default commentsApis;
