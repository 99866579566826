import { IonContent, IonSlides, IonSlide } from '@ionic/react';
import { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderModal } from '../Header/header-modal';

export const BodyShowImages: React.FC<{
    count: number;
    onDismiss: () => void;
    onIncrement: () => void;
    props: any;
}> = ({ count, onDismiss, onIncrement, props }) => {
    const { t } = useTranslation("common");
    const refSlider = useRef<any>(null);
    useLayoutEffect(() => {
        if (refSlider.current) {
            setTimeout(() => {
                refSlider?.current?.update().then(() => {});
            }, 400);
        }
    }, []);
    console.log(props.image);
    return (
        <>
            {' '}
            <HeaderModal onClose={onDismiss} title={t("show_Image")} />
            <IonContent className="content-color ">
                <IonSlides
                    options={{
                        initialSlide: props.numberImage,
                        cubeEffect: {
                            shadow: true,
                            slideShadows: true,
                            shadowOffset: 20,
                            shadowScale: 0.94,
                        },
                    }}
                    ref={refSlider}
                >
                    {props.image &&
                        props.image.map((data: any, index: number) => {
                            return (
                                <IonSlide key={index}>
                                    <img
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                        src={data.path}
                                    />
                                </IonSlide>
                            );
                        })}
                </IonSlides>
            </IonContent>
        </>
    );
};
