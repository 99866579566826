import {
    IonCol,
    IonContent,
    IonFooter,
    IonPage,
    IonRow,
    IonToolbar,
    useIonToast,
    isPlatform,
    IonModal,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { ButtonLableIcon } from '../../components/button-lable-icon';
import { HeaderContent } from '../../components/content-header';
import ItemInput from '../../components/item-input';
import { HeaderStarted } from '../../layout/Header/header-started.tsx';
import Button from '../../components/button';

import { useForm } from 'react-hook-form';
import apis from '../../services';
import { useDispatch } from 'react-redux';
import { setIsLoggedIn, setUserProfile } from '../../redux/user/user.actions';
import { setStorageOnlyData } from '../../storage/management-Storage';
import { Toast } from '../../components/toast';
import { FooterStarted } from '../../layout/footer-started';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';
import { connect } from '../../redux/connect';
import history from '../../helpers/history';
import { Modal } from '../../components/modal';
import { VerificationComponent } from '../verification/verification-component';
interface DispatchProps {
    setUserProfile: typeof setUserProfile;
    setIsLoggedIn: typeof setIsLoggedIn;
}
const SignUp: React.FC<any> = ({
    setUserProfile,
    setIsLoggedIn,
    userProfile,
}) => {
    const { t } = useTranslation('common');
    const [showKeyBorad, setShowKeyBorad] = useState(true);
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        if (Capacitor.isPluginAvailable('Keyboard')) {
            try {
                if (isPlatform('ios') || isPlatform('android')) {
                    Keyboard.addListener('keyboardWillShow', (info) => {
                        setShowKeyBorad(false);
                    });
                    Keyboard.addListener('keyboardWillHide', () => {
                        setShowKeyBorad(true);
                    });
                }
            } catch (e) {
                console.log('sdfsdf');
            }
        }
    }, [Keyboard]);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({});
    const [present, dismiss] = useIonToast();
    const [loading, setLoading] = useState(false);
    // const dispatch = useDispatch();
    const onDismiss = (goback: boolean) => {
        setShowModal(false);
    };
    const onSubmit = (data: {
        first_name: string;
        last_name: string;
        email?: string;
        phone: string;
        password: string;
        password_confirmation: string;
        privacy_policy: boolean | true;
    }) => {
        setLoading(true);
        apis.CreateUser({
            last_name: data.last_name,
            password: data.password,
            password_confirmation: data.password,
            privacy_policy: true,
            first_name: data.first_name,
            email: undefined,
            phone: data.phone,
        })
            .then((respone) => {
                localStorage.setItem('token', respone.data.auth.token);
                localStorage.setItem('user_id', respone.data.user.id);
                localStorage.setItem(
                    'fullname',
                    respone.data.user.first_name +
                        ' ' +
                        respone.data.user.last_name
                );
                setUserProfile({
                    main_phone: data.phone,
                    email: data?.email,
                    fullName: data.first_name + ' ' + data.last_name,
                    localLogin: true,
                    user_id: respone.data.user.id,
                    phone_verified: respone.data.user.phone_verified,
                    social_id: respone.data.user.social_id,
                });

                setStorageOnlyData({
                    access_token: respone.data.auth.token,
                    refresh_token: 'data.refresh_token',
                    user_id: respone.data.user.id,
                    type_user: 'user',
                });
                setShowModal(true);
                setIsLoggedIn(true);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Toast(
                    present,
                    (error.response?.data?.errors?.password?.[0] ?? '') +
                        ' ' +
                        (error.response?.data?.errors?.phone?.[0] ?? '') ??
                        t('Error_Occurred'),
                    1800,
                    'error'
                );
            });
    };
    return (
        <IonPage>
            <IonContent className="content-color ion-padding" fullscreen>
                <HeaderStarted link="/login" />
                <HeaderContent
                    content={t('Fill_the_following_data_start_shopping')}
                    title={t('Welcome_New')}
                />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonRow>
                        <IonCol
                            className="ion-no-padding ion-padding-end"
                            size="6"
                        >
                            <ItemInput
                                error={
                                    errors && errors.last_name ? true : false
                                }
                                register={register('first_name', {
                                    required: true,
                                })}
                                lines="none"
                                lable={t('First_Name')}
                            />
                        </IonCol>
                        <IonCol className="ion-no-padding" size="6">
                            <ItemInput
                                error={
                                    errors && errors.last_name ? true : false
                                }
                                register={register('last_name', {
                                    required: true,
                                })}
                                lines="none"
                                lable={t('List_Name')}
                            />
                        </IonCol>
                    </IonRow>

                    <ItemInput
                        error={errors && errors.phone ? true : false}
                        lines="none"
                        register={register('phone', { required: true })}
                        lable={t('Phone_Number')}
                    />
                    {/*       <ItemInput
                        error={errors && errors.email ? true : false}
                        lines="none"
                        type="email"
                        {...register('email', {
                            required: false,
                            pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'invalid email address',
                            },
                        })}
                        lable={'Email'}
                    /> */}
                    <ItemInput
                        error={errors && errors.password ? true : false}
                        lines="none"
                        register={register('password', { required: true })}
                        type="password"
                        lable={t('Password')}
                    />
                    <ButtonLableIcon
                        routerLink="/login"
                        content={t('Already_have_an_account')}
                    />
                    <Button
                        disabled={loading}
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                        height={'48px'}
                        color="none"
                        text={t('Sign_up')}
                        expand="full"
                    />
                </form>
                {showKeyBorad ? (
                    <IonFooter color="none">
                        <IonToolbar color="none">
                            <FooterStarted
                                setShowModal={setShowModal}
                                title={t('Or_sign_up_with_social_account')}
                            />
                        </IonToolbar>
                    </IonFooter>
                ) : (
                    ''
                )}
            </IonContent>
            {/* <IonModal
                isOpen={showModal}
                backdropDismiss={false}
                keyboardClose={false}
                cssClass="my-custom-class"
            >
                <VerificationComponent
                    userProfile={userProfile}
                    onDismiss={onDismiss}
                    setUserProfile={setUserProfile}
                    setIsLoggedIn={setIsLoggedIn}
                />
            </IonModal> */}
        </IonPage>
    );
};

export default connect<{}, any, DispatchProps>({
    mapStateToProps: (state) => ({
        darkMode: state.user.darkMode,
        userProfile: state.user.userProfile,
        isAuthenticated: state.user.isLoggedin,
    }),
    mapDispatchToProps: {
        setUserProfile,
        setIsLoggedIn,
    },
    component: React.memo(SignUp),
});
