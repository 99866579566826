import { axiosApi } from '.';

const getWishlistProducts = () => {
    return axiosApi.get('/wishlist/products');
};
const deleteWishlistProduct = (productId: number) => {
    return axiosApi.delete(`/wishlist/${productId}`);
};
const addWishlistProduct = (productId: number) => {
    return axiosApi.post(`/wishlist/${productId}`);
};
const addWishlistProducts = (body: { product_ids: Array<string> }) => {
    return axiosApi.post('/wishlist/products', body);
};
const wishlistApis = {
    getWishlistProducts,
    deleteWishlistProduct,
    addWishlistProduct,
    addWishlistProducts,
};
export default wishlistApis;
