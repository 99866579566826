import { useSelector } from 'react-redux';
import { getGlobalHeaders } from '../../modules/global-data';
import { InputProps } from './props';
import './style.scss';
export const Input: React.FC<InputProps> = ({
    type,
    required,
    lable,
    defaultValue,
    register,
    lange,
    ...props
}) => {
    const lang = getGlobalHeaders().lang_tag;
    return (
        <div className="input-floating-item">
            <div className="floating-label">
                <input
                    {...register}
                    defaultValue={defaultValue}
                    required={required}
                    className="floating-input"
                    type={type}
                    placeholder=" "
                />

                <span className="highlight" />
                <label
                    style={{
                        right: lang === 'ar' ? '0px' : '',
                        left: lang === 'ar' ? 'unset' : '',
                    }}
                >
                    {lable}
                </label>
            </div>
        </div>
    );
};
