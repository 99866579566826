import { IonCol, IonRow } from '@ionic/react'
import React from'react'
export const GridIamge:React.FC<any>=React.memo(({images})=>{
    const sizeCol=(length:number)=>{
/* if(length==0&&images.length==1){
    console.log("sdf")
    return "one-image"
}
if(length==0&&images.length==2){
    console.log("sdf")
    return "one-helf"
}
if(length==1&&images.length==2){
return "one-helf"
}
if(length==2&&images.length==3){
    return "three-image"
}
if(length==2&&images.length>=4){

    return "two-image"
}
if(length==3&&images.length>=4){

    return "two-image"
} */
if(images.length==1){
   if(length==0&&images.length){

    return "one-image"
}
}
if(images.length==2){
    if(length==0){
        console.log("sdf")
        return "one-helf"
    }
    if(length==1){
    return "one-helf"
    }
}
if(images.length==3){
    if(length<=1){
 
        return "two-image"
    }
    if(length==2){
        return"one-helf"
    }
    
}
if(images.length==4){
   
        return "two-image"
    
   

}
    }
    return(
    <IonRow>
        {images.map((data:any,index:number)=>{
return <div key={index} /* className="col-padding" */  className={sizeCol(index)}>
    <img   className={sizeCol(index)} src={data}/>
</div>
        })}

    </IonRow>
    )
})
