import {
    IonButton,
    IonContent,
    IonFooter,
    IonPage,
    useIonLoading,
    useIonToast,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button from '../../components/button';
import ItemInput from '../../components/item-input';
import { Refresher } from '../../components/refresher';
import { Select } from '../../components/select-item';
import { Toast } from '../../components/toast';
import { FooterPage } from '../../layout/footer-page';
import { HeaderSubPage } from '../../layout/Header/header-sub-page';
import { LoadingCenter } from '../../layout/loading-center';
import { getGlobalHeaders } from '../../modules/global-data';
import { connect } from '../../redux/connect';
import apis from '../../services';
interface DispatchProps {}
const AddNewAddress: React.FC<any> = ({ userProfile }) => {
    const { t } = useTranslation('common');
    const [present, dismiss] = useIonToast();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [governates, setGovernates] = useState<any>({
        data: [],
        loading: true,
        erorMessage: null,
    });
    const [countries, setCountries] = useState<any>({
        data: [],
        loading: true,
        erorMessage: null,
    });

    const [presentLoading, dismissLoading] = useIonLoading();
    const [countriesId, setCountriesId] = useState(-1);
    const [governatesId, setGovernatesId] = useState(-1);
    const [cityId, setCityId] = useState(-1);
    const [city, setCity] = useState<any>({
        data: [],
        loading: true,
        erorMessage: null,
    });
    useEffect(() => {
        // apis.getCountries().then((response) => {
        //     setCountries({
        //         data: response.data,
        //         loading: false,
        //     });
        // });
        getGovernatesCountries();
    }, []);
    const getGovernatesCountries = () => {
        //setCountriesId(id);
        presentLoading({
            message: 'Loading...',
            duration: 4000,
        });
        apis.getAllGovernates()
            .then((response) => {
                setGovernates({
                    data: response.data,
                    loading: false,
                });
            })
            .finally(() => {
                dismissLoading();
            });
    };
    const getCity = (id: number) => {
        setCountriesId(
            governates?.data.find((x: any) => x.id === id)?.country_id ?? 0
        );
        setGovernatesId(id);
        presentLoading({
            message: 'Loading...',
            duration: 4000,
        });
        apis.getCitiesOfGovernate(id)
            .then((response) => {
                setCity({
                    data: response.data.cities,
                    loading: false,
                });
            })
            .finally(() => {
                dismissLoading();
            });
    };
    const handelAddAddress = async (data: { address_1: string }) => {
        if (
            governatesId != -1 &&
            countriesId != -1 &&
            cityId != -1 &&
            governatesId &&
            countriesId &&
            cityId
        ) {
            setLoading(true);
            await apis
                .addAddress({
                    first_name: userProfile.fullName,
                    last_name: t('Empty'),
                    city_id: cityId,
                    state_id: governatesId,
                    country_id: countriesId,
                    customer_id: getGlobalHeaders().user_id,
                    address_1: data.address_1 ?? t('Empty'),
                    city:
                        governates.data.find((x: any) => x.id == governatesId)
                            ?.name_ar ?? 'city',
                    state: city.data.find((x: any) => x.id == cityId).name_ar,
                    zip: '000',
                    country:
                        countries.data.find((x: any) => x.id == countriesId)
                            ?.name_ar ?? 'demo',
                    country_name: countries.data.find(
                        (x: any) => x.id == countriesId
                    )?.name_ar,

                    state_name: city.data.find((x: any) => x.id == cityId)
                        .name_ar,
                })
                .then((respone) => {
                    if (respone.data) {
                        setLoading(false);
                        Toast(present, t('Saved_Successfully'), 1500);
                        setTimeout(() => {
                            history.replace(
                                '/page/settings/shipping-addresses'
                            );
                        }, 1510);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    Toast(present, t('Failed_To_Save'), 1500, 'error');
                });
        } else {
            Toast(present, t('Please_fill_fields'), 1500, 'error');
        }
    };
    const Refresh = () => {
        try {
        } catch (e) {}
    };

    return (
        <IonPage>
            <HeaderSubPage
                title={t('Add_New_Address')}
                defaultHref="/page/settings/shipping-addresses"
            ></HeaderSubPage>
            <IonContent className="content-color ion-padding">
                {governates.loading === false ? (
                    <>
                        {' '}
                        {/* <Select
                            onChange={getGovernatesCountries}
                            options={countries.data}
                            label={t('District')}
                        /> */}
                        <Select
                            disabled={governates.data.length > 0 ? false : true}
                            onChange={getCity}
                            options={governates.data}
                            label={t('Governorate')}
                        />
                        <Select
                            disabled={city.data.length > 0 ? false : true}
                            onChange={setCityId}
                            options={city.data}
                            label={t('City')}
                        />
                        <form onSubmit={handleSubmit(handelAddAddress)}>
                            <ItemInput
                                error={
                                    errors && errors.address_1 ? true : false
                                }
                                register={register('address_1', {
                                    required: true,
                                })}
                                lines="none"
                                type="text"
                                lable={t('Address_Details')}
                            />
                        </form>
                    </>
                ) : (
                    <LoadingCenter></LoadingCenter>
                )}
            </IonContent>
            <FooterPage>
                <Button
                    loading={loading}
                    disabled={loading}
                    onClick={handleSubmit(handelAddAddress)}
                    text={t('Save')}
                    expand="full"
                    color="none"
                />
            </FooterPage>
        </IonPage>
    );
};

export default connect<{}, any, DispatchProps>({
    mapStateToProps: (state) => ({
        darkMode: state.user.darkMode,

        userProfile:
            state.user.isLoggedin &&
            state.user.userProfile != null &&
            state.user.userProfile != undefined &&
            state.user?.userProfile,
    }),
    mapDispatchToProps: {},
    component: React.memo(AddNewAddress),
});
