import {
    IonCol,
    IonContent,
    IonGrid,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonPage,
    IonRow,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { HeaderSubPage } from '../../layout/Header/header-sub-page';
import { LoadingCenter } from '../../layout/loading-center';
import apis from '../../services';
import CardItem from '../tab-demo-page/home/component/model/card-item';
import '../all-product/style.scss';

import { useTranslation } from 'react-i18next';
import { getGlobalHeaders } from '../../modules/global-data';
import { BagEmpty } from '../../layout/errors/bag-empty';
interface IParams {
    value: string;
}
const FilterProducts: React.FC = () => {
    const { t } = useTranslation('common');
    const { value }: IParams = useParams();
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);

    const [listProduct, setListProduct] = useState<any>({
        data: [],
        loading: true,
        errorMesssage: null,
    });
    useEffect(() => {
        apis.getProductsByCategory(value)
            .then((response) => {
                setListProduct({
                    data: response.data,
                    loading: false,
                    errorMesssage: null,
                });
            })
            .catch((e) => {
                setListProduct({
                    data: [],
                    loading: false,
                    errorMesssage: null,
                });
            });
    }, []);
    const searchNext = (event: any) => {
        if (!listProduct?.data?.products?.data) {
            event.target.complete();
            setDisableInfiniteScroll(true);
            return;
        }
        let dataProducts: any = [];
        dataProducts = [...listProduct.data.products.data];
        if (
            listProduct.data?.products.current_page <
            listProduct.data?.products.last_page
        ) {
            apis.getProductsByCategory(
                value,
                `?page=${listProduct.data?.products.current_page + 1}`
            ).then((response) => {
                for (let i = 0; i < response.data.products.data.length; i++) {
                    dataProducts.push(response.data.products.data[i]);
                }
                //sett(response.data.products.data.length);
                response.data.products.data = dataProducts;
                setListProduct({
                    data: response.data,
                    loading: false,
                    errorMessage: null,
                });
                event.target.complete();
            });
        } else {
            setDisableInfiniteScroll(true);
            event.target.disabled = true;
        }
    };
    return (
        <IonPage className="all-product">
            <HeaderSubPage
                defaultHref="/tab/home"
                title={t('Filter') + ` ${value}`}
            ></HeaderSubPage>
            <IonContent className="content-color">
                {listProduct.loading == false ? (
                    listProduct.data?.products?.data?.length > 0 ? (
                        <IonGrid>
                            <IonRow>
                                {listProduct.data?.products?.data?.map(
                                    (data: any, index: number) => {
                                        return (
                                            <IonCol
                                                key={index}
                                                sizeXs={'6'}
                                                sizeSm={'6'}
                                                sizeMd={'4'}
                                                sizeLg={'3'}
                                            >
                                                <CardItem
                                                    lang={
                                                        getGlobalHeaders()
                                                            .lang_tag ?? 'ar'
                                                    }
                                                    type="sm"
                                                    slug={data.slug}
                                                    name={data.name}
                                                    category={''}
                                                    img={
                                                        data.base_image?.path ??
                                                        ''
                                                    }
                                                    is_dis={
                                                        data.special_price ==
                                                        null
                                                            ? 0
                                                            : 1
                                                    }
                                                    is_wishlist={false}
                                                    price={
                                                        data.price
                                                            .inCurrentCurrency
                                                            ?.amount
                                                    }
                                                    price_discount={
                                                        data.selling_price
                                                            .inCurrentCurrency
                                                            ?.amount
                                                    }
                                                />
                                            </IonCol>
                                        );
                                    }
                                )}
                            </IonRow>
                        </IonGrid>
                    ) : (
                        <BagEmpty />
                    )
                ) : (
                    <LoadingCenter></LoadingCenter>
                )}
                <IonInfiniteScroll
                    threshold="100px"
                    hidden={false}
                    disabled={disableInfiniteScroll}
                    onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}
                >
                    <IonInfiniteScrollContent
                        loadingText={t('loadingMore')}
                    ></IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonContent>
        </IonPage>
    );
};
export default FilterProducts;
