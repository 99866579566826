import {
    IonButton,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonModal,
    IonSlide,
    IonSlides,
} from '@ionic/react';
import { url } from 'inspector';
import { arrowBack, chevronBack } from 'ionicons/icons';
import { forwardRef, useImperativeHandle, useState } from 'react';
import Stories from 'react-insta-stories';
import Button from '../../../../../components/button';
import { InputComment } from '../../../wow-community/component/Body-comments-list/model/input-comment';
import './style.scss';
import { useAuth } from '../../../../../hooks/use-auth';
let Storys: any = [{}];
export const StoryModal: React.FC<any> = forwardRef(({}, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const { isAuthenticated } = useAuth();
    // useSelector((state: any) => state?.user?.isLoggedin);
    useImperativeHandle(ref, () => {
        return {
            ShowModalStories: ShowModalStories,
        };
    });
    const ShowModalStories = (data: any) => {
        setIsOpen(true);
        Storys = data;
        console.log(data);
    };
    const [index, setIndex] = useState(0);

    return isOpen ? (
        <IonModal
            isOpen={isOpen}
            swipeToClose={true}
            onDidDismiss={() => {
                setIsOpen(false);
            }}
        >
            <IonContent>
                <Stories
                    onStoryStart={(data: any) => setIndex(data)}
                    stories={Storys}
                    storyContainerStyles={{ width: '100%', height: '100%' }}
                    width={'100%'}
                    height={'100%'}
                />
                <IonButton
                    className="storyModal"
                    mode="ios"
                    fill="clear"
                    shape="round"
                    onClick={() => setIsOpen(false)}
                    style={{
                        position: 'absolute',
                        right: '10px',
                        top: '15px',
                        zIndex: '1000',
                        width: '48px',
                    }}
                >
                    <IonIcon
                        style={{ color: 'white' }}
                        slot="icon-only"
                        icon={chevronBack}
                    />
                </IonButton>
                {Storys && Storys[index] && Storys[index]?.slug ? (
                    <IonButton
                        routerLink={'/page/product/' + Storys[index]?.slug}
                        className="storyModalProduct"
                        mode="ios"
                        fill="clear"
                        onClick={() => setIsOpen(false)}
                        shape="round"
                        style={{
                            position: 'absolute',

                            bottom: '10px',
                            padding: '5px',
                            zIndex: '1000',
                        }}
                    >
                        عرض المنتج
                    </IonButton>
                ) : (
                    ''
                )}
            </IonContent>

            {isAuthenticated && (
                <IonFooter>
                    <InputComment
                        refresh={() => console.log()}
                        idProduct={Storys[index]?.id}
                    />
                </IonFooter>
            )}
        </IonModal>
    ) : (
        <></>
    );
});
