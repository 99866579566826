import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonRange,
    IonText,
    IonTitle,
} from '@ionic/react';
import { sunny } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { FooterPage } from '../../../../footer-page';
import { HeaderModal } from '../../../header-modal';
import { Color } from './components/color';
import { ItemChips } from './components/item-chips';
import Button from '../../../../../components/button';
import apis from '../../../../../services';
import { addToValueFilterDispatch } from '../../../../../redux/filter/filter.actions';
import { LoadingCenter } from '../../../../loading-center';
import { useTranslation } from 'react-i18next';
export const BodyFilterProducts: React.FC<{
    count: number;
    onDismiss: () => void;
    onIncrement: () => void;
    props: any;
}> = ({ count, onDismiss, onIncrement, props }) => {
    const { t } = useTranslation('common');
    const [categotyList, setCategoty] = useState<any>({
        data: [],
        loading: false,
        errorMessage: null,
    });
    useEffect(() => {
        // apis.getCategories()
        //     .then((response) => {
        //         props.dispatch(
        //             addToValueFilterDispatch(response.data?.categories)
        //         );
        //         setCategoty({
        //             data: response.data?.categories,
        //             loading: false,
        //             errorMessage: null,
        //         });
        //     })
        //     .catch((e) => {
        //         setCategoty({
        //             loading: false,
        //             errorMessage: e,
        //         });
        //     });
    }, []);
    const handelSubmit = () => {
        props.history.push(`/page/filter-products/${selectFilter}`);
        onDismiss();
    };
    const category = [
        'All',
        'Summer',
        'Winter',
        'Baby',
        'Women',
        'Long T-Shirts',
        'Night',
    ];
    const size = ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'];
    const [selectFilter, setSelectFilter] = useState(null);

    const [rangeValue, setRangeValue] = useState<{
        lower: number;
        upper: number;
    }>({ lower: 0, upper: 0 });
    var color = [
        '#FFFFFF',
        '#C69C6D',
        '#846DC6',
        '#6DC684',
        '#E76D6D',
        '#000000',
        '#E76D6D',
        ' #6DC684',
    ];

    return (
        <>
            {' '}
            <HeaderModal onClose={onDismiss} title={t('Filter')} />
            <IonContent
                style={{ direction: props.lang === 'ar' ? 'rtl' : 'ltr' }}
                className="content-color ion-padding"
            >
                <IonText>{t('Categories')}</IonText>
                <br />
                {props.filter?.length > 0 ? (
                    props.filter.map((data: any, index: number) => {
                        return (
                            <ItemChips
                                selected={
                                    data.name === selectFilter ? true : false
                                }
                                onClick={() => setSelectFilter(data.name)}
                                id={data.id}
                                key={index}
                                text={data.name}
                            />
                        );
                    })
                ) : categotyList.loading === false ? (
                    categotyList.data?.map((data: any, index: number) => {
                        return (
                            <ItemChips
                                selected={
                                    data.name === selectFilter ? true : false
                                }
                                onClick={() => setSelectFilter(data.name)}
                                id={data.id}
                                key={index}
                                text={data.name}
                            />
                        );
                    })
                ) : (
                    <LoadingCenter></LoadingCenter>
                )}

                {/*  <IonTitle>Color</IonTitle>
               {color.map((data, index) => {
                    return <Color color={data} key={index} />;
                })}

                <IonItem color="none" lines="none">
                    <IonRange
                        color="pink"
                        dualKnobs={true}
                        min={0}
                        max={60}
                        step={4}
                        snaps={true}
                        onIonChange={(e) =>
                            setRangeValue(e.detail.value as any)
                        }
                    />
                </IonItem>

                <IonTitle>Size</IonTitle>
                {size.map((data, index) => {
                    return <ItemChips key={index} text={data} />;
                })} */}
            </IonContent>
            {selectFilter != null ? (
                <FooterPage>
                    <Button
                        onClick={handelSubmit}
                        expand="full"
                        color="none"
                        text={t('APPLY_FILTER')}
                    />
                </FooterPage>
            ) : (
                ''
            )}
        </>
    );
};
