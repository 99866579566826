import { IonIcon, IonInput, IonItem } from "@ionic/react";
import { searchSharp } from "ionicons/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { IPropsSearchInput } from "./props.search-input";
import "./style.scss";
export const SearchInput: React.FC<IPropsSearchInput> = ({ onKeyPress }) => {
  const { t } = useTranslation("common");

  return (
    <IonItem color="none" lines="inset" className="item-sarech">
      <IonIcon className="icon-search" icon={searchSharp} />
      <input
        onKeyUp={onKeyPress}
        type="search"
        placeholder={t("Search_clothes")}
        className="input-search"
      />
    </IonItem>
  );
};
