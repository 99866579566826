const facebookAppId = 2678001539171465;

export function initFacebookSdk() {
    return new Promise(resolve => {
        // wait for facebook sdk to initialize before starting the react app
        window.fbAsyncInit = function() {
            window.FB.init({
              appId: facebookAppId,
              cookie: true, // enable cookies to allow the server to access the session
              xfbml: true, // parse social plugins on this page
              version: 'v5.0' // use graph api current version
            });
          };
        // Load the SDK asynchronously
        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk')); 
    });
}
