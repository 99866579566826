import { IonContent, IonList, IonPage, IonToggle } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ItemToggle } from '../../components/item-toggle';
import { HeaderSubPage } from '../../layout/Header/header-sub-page';
import { getLangTag } from '../../modules/global-data';
import { ListMenu } from '../tab-demo-page/profile/component/list-menu';
import { ItemChange } from './component/item-change';
import './style.scss';
const Settings: React.FC = () => {
    const { t } = useTranslation('common');
    var menu = [
        {
            type: 'list-change',
            title: t('Edit_Profile'),
            routerLink: '/page/settings/edit-profile',
            description: ' ',
        },
        {
            type: 'list-change',
            title: t('CURRENT_LANGUAGE'),
            description: t('Language'),
            routerLink: '/page/settings/change-language',
        },
        {
            type: 'list-change',
            title: '••••••••',
            description: t('Password'),
            routerLink: '/page/settings/change-password',
        },
        {
            type: 'list',
            title: t('Your_Address'),
            description: t('Location'),
            routerLink: '/page/settings/shipping-addresses',
        },
        // {
        //     type: 'list-toggle',
        //     title: t("Enabled"),
        //     description: t("Receive_Notifications"),
        // },
        // {
        //     type: 'list-toggle',
        //     title: t("Enabled"),
        //     description: t("Receive_special_offers"),
        // },
        // {
        //     type: 'list-toggle',
        //     title: t("Disable"),
        //     description: t("Order_Tracking_Notifications"),
        // },
    ];

    return (
        <IonPage className="setting-page">
            <HeaderSubPage
                title={t('Settings')}
                defaultHref="/tab/profile"
            ></HeaderSubPage>
            <IonContent fullscreen className="content-color">
                <IonList className="list" color="none">
                    {menu.map((data, index: number) => {
                        return data.type == 'list' ? (
                            <ListMenu
                                key={index}
                                title={data.title}
                                routerLink={data.routerLink}
                                description={data.description}
                            />
                        ) : data.type == 'list-toggle' ? (
                            <ItemToggle
                                isCheck={false}
                                description={data.description}
                                key={index}
                            />
                        ) : (
                            <ItemChange
                                key={index}
                                routerLink={data.routerLink}
                                title={data.title}
                                descripton={data.description}
                            />
                        );
                    })}
                </IonList>
            </IonContent>
        </IonPage>
    );
};
export default React.memo(Settings);
