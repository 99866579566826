import React from 'react';
import { setFilterData } from '../../storage/management-Storage';
import { ADD_VALUE_FILTER_VALUE, LOAD_FILTER_VALUE } from './filter.actions';

const initState: any = [];
const filterReducer = (state = initState, action: any) => {
    switch (action.type) {
        case LOAD_FILTER_VALUE:
            state=action.payload
            return state
        case ADD_VALUE_FILTER_VALUE:
            setFilterData( action.payload)
            state = action.payload;
            return state;
        default:
            return state;
    }
};

export default filterReducer;
