import { IonItemDivider, IonText } from "@ionic/react"
import './style.scss'
export const ItemDivider:React.FC<{
    title:string
}>=({title})=>{



    return(<IonItemDivider className="item-divider" mode="ios">

        <IonText slot="start" className="title">{title}</IonText>
    </IonItemDivider>)
}