import { axiosApi } from '.';

export const getNewsLines = () => {
    return axiosApi.get('/newslines');
};
export const getNewsLine = (id: string) => {
    return axiosApi.get(`/newslines/${id}`);
};
const newsLineApis = { getNewsLines, getNewsLine };
export default newsLineApis;
