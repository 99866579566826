import { IonButton, IonContent, IonText } from "@ionic/react"
import { useTranslation } from "react-i18next"
import { ReactSVG } from "react-svg"
import  Button from "../../../components/button"
import { FooterPage } from "../../footer-page"

export const FavoriteEmpty:React.FC=()=>{
  const { t } = useTranslation("common");


return(
<>
<IonContent className="content-color">
<div className="order-placed">

                 <ReactSVG
              wrapper="span"
              className="wrapper-class-name"
              evalScripts="always"
              beforeInjection={(svg) => {
                svg.classList.add("svg-class-name");
                svg.setAttribute("style", "width: 200px");
              }}

              src={process.env.PUBLIC_URL + "/assets/svg/Group 12333.svg"}
            />
{/*             <ReactSVG  className="img" src={process.env.PUBLIC_URL + "/assets/svg/internet-connection.svg"} />
 */}            <div className="title">
              <IonText className="text">
              {t("Your_Favorite_List_is_Empty")}            </IonText>
            </div>
            <div className="subtitle">
              <IonText className="text">
               {t("Please_choose_products_and_add_them_to_the_basket")}
              </IonText>
            </div>
            </div>
    </IonContent>
    <FooterPage>
        <Button   expand="full" background="#6A7EC4" color="none" text={t("DISCOVER_PRODUCTS")}></Button>
    </FooterPage>
    </>
)

}