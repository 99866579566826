import { IonButton, IonIcon, IonLabel } from '@ionic/react';
import { addOutline, removeOutline } from 'ionicons/icons';
import React from 'react';
import { IPropsQuantity } from './props.quantity';
import './style.scss';
export const Quantity: React.FC<IPropsQuantity> = React.memo(
    ({ onDecrease, onIncrease, value, lang }) => {
        return (
            <div className="qty-selector">
                <IonButton
                    onClick={onDecrease && onDecrease}
                    fill="clear"
                    style={{}}
                >
                    <IonIcon
                        className={`icon-quintity ${
                            lang === 'ar' ? 'right' : 'left'
                        }`}
                        icon={removeOutline}
                    />
                </IonButton>
                <IonLabel>{value}</IonLabel>
                <IonButton onClick={onIncrease && onIncrease} fill="clear">
                    <IonIcon
                        className={`icon-quintity ${
                            lang === 'ar' ? 'left' : 'right'
                        }`}
                        icon={addOutline}
                    />
                </IonButton>
            </div>
        );
    }
);
