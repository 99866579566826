import { Storage } from '@capacitor/storage';

/* import { ICard } from "./RecentSearch/card.state"; */
import {
    CARD_PRODUCT,
    DARK_MODE,
    FILTER_CATEGORY,
    HAS_ACTIVED_IN,
    HAS_LOGGED_IN,
    HAS_SEEN,
    NOTIFICATION_SECTIONS,
    RECENT_SEARCH,
    SETTINGS,
    STORAGE_ONLY,
    USER_PROFILE,
    WISHLIST_PRODUCT,
} from '../helpers/storage-key';
import {
    resetGlobalHeaders,
    setLangTagGlobalHeaders,
    setStorageOnlyGlobalHeaders,
    setUserGlobalHeaders,
} from '../modules/global-data';
import { IUserProfile } from './models/user-profile';
import { IStorageOnly } from './models/storage-only';
import { ISettingsConf } from './models/setting';

const dataUrl = '/assets/data/data.json';
const locationsUrl = '/assets/data/locations.json';

export const getConfData = async () => {
    const response = await Promise.all([fetch(dataUrl), fetch(locationsUrl)]);
    const responseData = await response[0].json();
    const schedule = responseData.schedule[0];

    const data = {};
    return data;
};

export const getUserData = async () => {
    const response = await Promise.all([
        Storage.get({ key: SETTINGS }),
        Storage.get({ key: STORAGE_ONLY }),
        Storage.get({ key: USER_PROFILE }),
        Storage.get({ key: HAS_LOGGED_IN }),
        Storage.get({ key: DARK_MODE }),
        Storage.get({ key: HAS_SEEN }),
        Storage.get({ key: CARD_PRODUCT }),
        Storage.get({ key: NOTIFICATION_SECTIONS }),
        Storage.get({ key: WISHLIST_PRODUCT }),
        Storage.get({ key: FILTER_CATEGORY }),
    ]);
    const settings = JSON.parse(
        (await response[0].value) || '{ "lang_tag" : "ar" }'
    );
    var storageOnly = JSON.parse((await response[1].value) + '');
    setStorageOnlyGlobalHeaders(storageOnly);

    const userProfile = JSON.parse((await response[2].value) + '');
    if (userProfile) {
        setUserGlobalHeaders(
            (userProfile as IUserProfile)?.user_id,
            (userProfile as IUserProfile)?.type_user,
            (userProfile as IUserProfile)?.fullName,
            (userProfile as IUserProfile).avatar,
            (userProfile as IUserProfile).main_phone,
            (userProfile as IUserProfile).reviews_count,
            (userProfile as IUserProfile).order_count,
            (userProfile as IUserProfile).addresses_count,
            (userProfile as IUserProfile).password,
            (userProfile as IUserProfile).phone_verified
        );
    }
    setLangTagGlobalHeaders(settings?.lang_tag ?? 'ar');

    const isLoggedin = (await response[3].value) === 'true';
    const darkMode = (await response[4].value) === 'true';
    const hasSeen = JSON.parse((await response[5].value) + '');
    const notificationStatus = JSON.parse((await response[7].value) + '');
    const wishlitProductsData = JSON.parse((await response[8].value) + '');
    const filterCategory = JSON.parse((await response[9].value) + '');
    const data = {
        settings,
        userProfile,
        isLoggedin,
        darkMode,
        hasSeen,
        wishlitProductsData,
        notificationStatus,
        filterCategory,
    };
    return data;
};

export const getFilterData = async () => {
    const data = await Storage.get({ key: FILTER_CATEGORY });
    return JSON.parse(data.value + '');
};
export const setFilterData = async (data: any) => {
    await Storage.set({
        key: FILTER_CATEGORY,
        value: JSON.stringify(data),
    });
};
export const getCardProductsData = async () => {
    const data = await Storage.get({ key: CARD_PRODUCT });
    return JSON.parse(data.value + '');
};
export const setCardProductsData = async (data: any) => {
    await Storage.set({
        key: CARD_PRODUCT,
        value: JSON.stringify(data),
    });
};
export const getWishlistData = async () => {
    const data = await Storage.get({ key: WISHLIST_PRODUCT });
    return JSON.parse(data.value + '');
};
export const setWishlistData = async (dataWishlistProduct: any) => {
    await Storage.set({
        key: WISHLIST_PRODUCT,
        value: JSON.stringify(dataWishlistProduct),
    });
};

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
    await Storage.set({
        key: HAS_LOGGED_IN,
        value: JSON.stringify(isLoggedIn),
    });
};
export const setNotificationsSections = async (data: any) => {
    await Storage.set({
        key: NOTIFICATION_SECTIONS,
        value: JSON.stringify(data),
    });
};
export const setDarkModeData = async (darkMode: boolean) => {
    await Storage.set({ key: DARK_MODE, value: JSON.stringify(darkMode) });
};

export const setHasSeenData = async (hasSeen?: any) => {
    if (!hasSeen) {
        await Storage.remove({ key: HAS_SEEN });
    } else {
        await Storage.set({ key: HAS_SEEN, value: JSON.stringify(hasSeen) });
    }
};

export const setSettingsData = async (settings?: ISettingsConf) => {
    if (!settings) {
        await Storage.remove({ key: SETTINGS });
    } else {
        await Storage.set({ key: SETTINGS, value: JSON.stringify(settings) });
    }
};

export const setStorageOnlyData = async (storageOnly?: IStorageOnly) => {
    setStorageOnlyGlobalHeaders(storageOnly);
    if (!storageOnly) {
        await Storage.remove({ key: STORAGE_ONLY });
        localStorage.removeItem('token');
        localStorage.removeItem('social_user');
        localStorage.removeItem('user_id');
        localStorage.removeItem('username');
        localStorage.removeItem('verify_username');
        localStorage.removeItem('fullname');

        resetGlobalHeaders();
    } else {
        await Storage.set({
            key: STORAGE_ONLY,
            value: JSON.stringify(storageOnly),
        });
    }
};

export const setUserProfileData = async (userProfile?: IUserProfile) => {
    if (!userProfile) {
        await Storage.remove({ key: USER_PROFILE });
    } else {
        await Storage.set({
            key: USER_PROFILE,
            value: JSON.stringify(userProfile),
        });
    }
};
