import {
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
    IonText,
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ReactSVG } from 'react-svg';
import Button from '../../components/button';
import { FooterPage } from '../../layout/footer-page';
import { HeaderSubPage } from '../../layout/Header/header-sub-page';
import './style.scss';
const OrderPlaced: React.FC = () => {
    const { t } = useTranslation("common");
    const { id }: any = useParams();
    return (
        <IonPage>
            <HeaderSubPage
                defaultHref="/page/checkout"
                title={t("Order_Placed")}
            ></HeaderSubPage>
            <IonContent className="content-color">
                <div className="order-placed">
                    {/*     <ReactSVG
              wrapper="span"
              className="wrapper-class-name"
              evalScripts="always"
              beforeInjection={(svg) => {
                svg.classList.add("svg-class-name");
                svg.setAttribute("style", "width: 200px");
              }}
              src={process.env.PUBLIC_URL + "/assets/svg/Group46088.svg"}
            /> */}
                    <img
                        className="img"
                        src={
                            process.env.PUBLIC_URL +
                            '/assets/svg/Group46088.svg'
                        }
                    />
                    <div className="title">
                        <IonText className="text">{t("Relax_and_wait")}</IonText>
                    </div>
                    <div className="subtitle">
                        <IonText className="text">
                            {t("Relax_and_wait_We_have_already_received")}
                        </IonText>
                    </div>
                </div>
            </IonContent>
            <FooterPage>
                <IonRow>
                    <IonCol>
                        <Button
                            expand="full"
                            text={t("TRACK_ORDER")}
                            routerLink={`/page/my-orders/${id}`}
                            color="none"
                        />
                    </IonCol>
                    <IonCol>
                        <Button
                            routerLink="/tab/home"
                            fontColor="#EB4381"
                            border="0.8462259769439697px solid #EE417F"
                            background="linear-gradient(90deg, rgba(238,65,127,0.19) 0%, rgba(238,65,127,0.19) 25%, rgba(238,65,127,0.19091386554621848) 100%)"
                            fill="clear"
                            expand="full"
                            text={t("CONTINUE")}
                            color="none"
                        />
                    </IonCol>
                </IonRow>
            </FooterPage>
        </IonPage>
    );
};
export default React.memo(OrderPlaced);
