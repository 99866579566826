import { IonIcon, IonRouterLink } from "@ionic/react";
import { arrowForward } from "ionicons/icons";
import React from "react";
import { IPropsButtonLableIocn } from "./props.button-lable-icon";
import './style.scss'
export const ButtonLableIcon:React.FC<IPropsButtonLableIocn>=({content,routerLink})=>{
return(
<div className="btn-lable"><IonRouterLink routerLink={routerLink && routerLink} >
<p>{content} <IonIcon color="pink"  icon={arrowForward}/></p>
</IonRouterLink>
</div>)
}