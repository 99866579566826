import {
    IonContent,
    IonPage,
    IonText,
    IonThumbnail,
    useIonToast,
} from '@ionic/react';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from '../../components/button/index';
import ItemInput from '../../components/item-input';
import { Toast } from '../../components/toast';
import { FooterPage } from '../../layout/footer-page';
import { HeaderSubPage } from '../../layout/Header/header-sub-page';
import apis from '../../services';
const ChangePasswordReset: React.FC = () => {
    const { t } = useTranslation('common');
    const history = useHistory();
    const {
        register,
        watch,
        formState: { errors },
        handleSubmit,
    } = useForm();
    const password = useRef({});
    const [present, dismiss] = useIonToast();
    const [loading, setLoading] = useState(false);
    password.current = watch('newPassword', '');
    const resetUser = JSON.parse(localStorage.getItem('reset-user') ?? '{}');
    const onSubmit = (data: any) => {
        setLoading(true);
        apis.resetPasswordComplete2(
            resetUser?.user?.email,
            resetUser?.code,
            data.newPassword
        )
            .then((response) => {
                history.replace('/login', {
                    preventLastLocation: true,
                });
                Toast(present, response.data?.success ?? 'error', 1400);
            })
            .catch((error) => {
                Toast(present, t('Error_changing_password'), 1400, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <IonPage>
            <HeaderSubPage
                defaultHref="/page/settings"
                title={t('Change_Password')}
            />
            <IonContent className="content-color ion-padding">
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/*           <ItemInput {...register("password")} lines="none" type="password" lable={t("Current_Password")} />
                     */}{' '}
                    <div className="header-profile-info">
                        <IonThumbnail className="thumbanail-profile">
                            <img src={resetUser?.user?.picture?.path} />
                            <div className="filter-img"></div>
                        </IonThumbnail>
                        <div className="name-content">
                            <IonText className="name">
                                {resetUser?.user?.first_name +
                                    ' ' +
                                    resetUser?.user?.last_name}
                            </IonText>
                        </div>
                        <div>
                            <IonText className="email">
                                {resetUser?.user?.email}
                            </IonText>
                        </div>
                    </div>
                    ;
                    <ItemInput
                        register={register('newPassword', { required: true })}
                        lines="none"
                        error={errors?.newPassword}
                        type="password"
                        lable={t('New_Password')}
                    />
                    <ItemInput
                        register={register('confirmPassword', {
                            required: true,
                            validate: (value) =>
                                value === password.current ||
                                'The passwords do not match',
                        })}
                        error={errors?.confirmPassword}
                        lines="none"
                        type="password"
                        lable={t('Confirm_New_Password')}
                    />
                </form>
            </IonContent>
            <FooterPage>
                <Button
                    disabled={loading}
                    loading={loading}
                    onClick={handleSubmit(onSubmit)}
                    text={t('Save_Changes')}
                    height={35}
                    expand="full"
                    color="none"
                />
            </FooterPage>
        </IonPage>
    );
};
export default React.memo(ChangePasswordReset);
