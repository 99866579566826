import React, { useEffect, useContext } from 'react';
import { IonRouterContext } from '@ionic/react';
import apis from '../../services';
import { resetGlobalHeaders } from '../../modules/global-data';
import { setStorageOnlyData } from '../../storage/management-Storage';
import { useHistory } from 'react-router';

interface RedirectToLoginProps {
    setIsLoggedIn: Function;
    deleteAllStats: Function;
    setUserProfile: Function;
    deleteAllFromCartDispatch: Function;
    deleteAllFromWishlistDispatch: Function;
}

const RedirectToLogin: React.FC<RedirectToLoginProps> = ({
    setIsLoggedIn,
    setUserProfile,
    deleteAllStats,
    deleteAllFromCartDispatch,
    deleteAllFromWishlistDispatch,
}) => {
    const ionRouterContext = useContext(IonRouterContext);
    const history = useHistory();
    useEffect(() => {
        apis.logout()
            .then(async () => {
                try {
                    setIsLoggedIn(false);
                    setUserProfile();
                    deleteAllFromWishlistDispatch();
                } catch (e) {
                    console.log('error');
                }
                resetGlobalHeaders();
                deleteAllStats && deleteAllStats();
                deleteAllFromCartDispatch();
                await setStorageOnlyData();
                history.replace('/tab/home', { preventLastLocation: true });
            })
            .catch((e) => {
                setIsLoggedIn(false);
                setUserProfile();

                resetGlobalHeaders();

                setStorageOnlyData();
                try {
                    deleteAllStats && deleteAllStats();
                    deleteAllFromCartDispatch();
                    deleteAllFromWishlistDispatch();
                } catch (e) {
                    console.log(e);
                }
                history.replace('/tab/home', { preventLastLocation: true });
            })
            .finally(() => {
                //setLoading(false);
            });
    }, [
        setIsLoggedIn,
        setUserProfile,
        history,
        deleteAllStats,
        deleteAllFromCartDispatch,
    ]);
    return null;
};

export default RedirectToLogin;
