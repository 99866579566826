import { useCallback, useContext, useMemo, useReducer } from 'react';
import { AppContext } from '../redux/AppContext';
import { connect } from '../redux/connect';
import { initialState } from '../redux/state';
import { setIsLoggedIn, setUserProfile } from '../redux/user/user.actions';
import userReducer from '../redux/user/user.reducer';

export function useAuth() {
    const { state, dispatch } = useContext(AppContext);

    const isAuthenticated = useMemo(() => {
        return state?.user?.isLoggedin ?? false;
    }, [state?.user]);

    const localLogin = useMemo(() => {
        return (
            state.user.isLoggedin &&
            state.user.userProfile != null &&
            state.user.userProfile != undefined &&
            state.user.userProfile.localLogin == true
        );
    }, [state]);
    const userProfile = useMemo(() => {
        return state.user.userProfile;
    }, [state.user.userProfile]);
    const dispatchsetIsLoggedIn = useCallback(
        (value: boolean) => {
            (setIsLoggedIn(value));
        },
        [dispatch]
    );
    const dispatchsetUserProfile = useCallback(
        (data: any) => {
            (setUserProfile(data));
        },
        [dispatch]
    );
    return {
        isAuthenticated,
        localLogin,
        userProfile,
        setIsLoggedIn,
        dispatchsetUserProfile,
        dispatchsetIsLoggedIn,
        dispatch
    };
}
