import {
    IonCard,
    IonCol,
    IonGrid,
    IonItem,
    IonLabel,
    IonRow,
} from '@ionic/react';
import React from 'react';
import { CURRENCY_LI } from '../../../../../helpers/currency';
import CardItem from '../model/card-item';
import { IPropsSliderItemHotOffres } from './props.item-slider-hot-offers';
import './style.scss';

const SliderItemHotOffers: React.FC<IPropsSliderItemHotOffres> = ({
    item,
    lang,
    onClick,
}) => {
    return (
        <IonGrid id="Slider">
            <IonRow>
                <div className="scrolling-wrapper ">
                    {item &&
                        item.map((data, index: number) => (
                            <div key={index} className="card">
                                <CardItem
                                    onClick={onClick}
                                    lang={lang}
                                    type="sm"
                                    slug={data.slug}
                                    name={data.name}
                                    category={
                                        data.categories
                                            ? data?.categories[0]?.name ?? '-'
                                            : '-'
                                    }
                                    img={data.base_image?.path ?? ''}
                                    is_dis={data.special_price == null ? 0 : 1}
                                    is_wishlist={false}
                                    price={data.price.inCurrentCurrency?.amount}
                                    price_discount={
                                        data.selling_price?.inCurrentCurrency
                                            ?.amount
                                    }
                                />
                            </div>
                        ))}
                </div>
            </IonRow>
        </IonGrid>
    );
};
export default React.memo(SliderItemHotOffers);
