import { axiosApi } from '.';

const getPosts = (page?: string) => {
    return axiosApi.get('/story_posts' + (page ?? ''));
};
const getStoryToday = () => {
    return axiosApi.get('/story_posts/today');
};
const storyApis = { getPosts, getStoryToday };
export default storyApis;
