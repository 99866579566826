import { IonChip, IonIcon } from '@ionic/react';
import { checkboxOutline, checkmark, checkmarkSharp } from 'ionicons/icons';
import React from 'react';
import { IPropsColorListPiker } from './props.color-list-piker';
import './style.scss';
export const ColorListPiker: React.FC<IPropsColorListPiker> = React.memo(
    ({ onClick, color, check, lang ,enabled}) => {
        return (
            <IonChip
                onClick={onClick && onClick}
                className={'color-piker'}
                style={{ background: color }}
                color="none"
            >
                {check ? (
                    <IonIcon
                        className={lang === 'ar' ? 'ion-icon-ar' : 'ion-icon'}
                        icon={checkmarkSharp}
                    ></IonIcon>
                ) : (
                    ''
                )}
            </IonChip>
        );
    }
);
