export function combineReducers<R extends any>(reducers: R) {
    type keys = keyof typeof reducers;
    type returnType = { [K in keys]: ReturnType<typeof reducers[K] | any> };
    const combinedReducer = (state: any, action: any) => {
        const newState: returnType = {} as any;
        const keys = Object.keys(reducers as any) as any;
        keys.forEach((key: any) => {
            const result: any = (reducers[key as keys] as any)(
                state[key],
                action
            ) as any;
            newState[key as keys] = result || state[key];
        });
        return newState;
    };
    return combinedReducer;
}
