import {
  IonItem,
  IonNote,
  IonText,
  IonThumbnail,
  IonLabel,
} from "@ionic/react";
import React from "react";
import { IPropsHeaderCard } from "./props.header-card";
import "./style.scss";
export const HeaderCard: React.FC<IPropsHeaderCard> = React.memo(
  ({ subTitle, name, img, time }) => {
    return (
      <IonItem color="none" className="item-header" lines="none">
        <IonThumbnail className="thumbnail" slot="start">
          <img className="img-thumbnail" src={img} />
        </IonThumbnail>
        <IonLabel className="name">
          {name}
          {subTitle && <p>{subTitle}</p>}{" "}
        </IonLabel>
        <IonText className="time" slot="end">
          {time}
        </IonText>
      </IonItem>
    );
  }
);
