import {
    IonCol,
    IonContent,
    IonFooter,
    IonPage,
    IonRow,
    IonToolbar,
    useIonToast,
    isPlatform,
    IonModal,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { ButtonLableIcon } from '../../components/button-lable-icon';
import { HeaderContent } from '../../components/content-header';
import ItemInput from '../../components/item-input';
import { HeaderStarted } from '../../layout/Header/header-started.tsx';
import Button from '../../components/button';
import { connect } from '../../redux/connect';
import { useForm } from 'react-hook-form';
import apis from '../../services';
import { useDispatch } from 'react-redux';
import { setIsLoggedIn, setUserProfile } from '../../redux/user/user.actions';
import { setStorageOnlyData } from '../../storage/management-Storage';
import { Toast } from '../../components/toast';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { VerificationComponent } from '../verification/verification-component';

interface DispatchProps {
    setUserProfile: typeof setUserProfile;
}
const CompleteSignUp: React.FC<any> = ({ userProfile,setUserProfile }) => {
    const { t } = useTranslation('common');
    const socialUser = JSON.parse(localStorage.getItem('social_user') ?? '{}');
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({});
    const [present, dismiss] = useIonToast();
    const [loading, setLoading] = useState(false);

    const onDismiss = (goback: boolean) => {
        setShowModal(false);
        history.goBack();
    };

    const onSubmit = (data: { phone: string }) => {
        setLoading(true);
        apis.requestVerifyUserAccount({
            phone: data.phone,
        })
            .then((respone) => {
                localStorage.setItem('verify_username', data.phone);
                setLoading(false);
                setShowModal(true);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error.response.data);
                if (error.response?.data?.message == 'validation.unique') {
                    Toast(present, t('The_phone_taken'), 1800, 'error');
                    return;
                }
                Toast(
                    present,
                    (error.response?.data?.errors?.password?.[0] ?? '') +
                        ' ' +
                        (error.response?.data?.errors?.phone?.[0] ?? '') ??
                        t('Error_Occurred'),
                    1800,
                    'error'
                );
            });
    };
    return (
        <IonPage>
            <IonContent className="content-color ion-padding" fullscreen>
                <HeaderStarted link="/login" />
                <HeaderContent
                    content={t('Fill_the_following_data_start_shopping')}
                    title={t('Welcome__', { name: socialUser.fname })}
                />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ItemInput
                        error={errors && errors.phone ? true : false}
                        lines="none"
                        register={register('phone', { required: true })}
                        lable={t('Phone_Number')}
                    />
                    <Button
                        disabled={loading}
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                        height={'48px'}
                        color="none"
                        text={t('Complete_Sign_up')}
                        expand="full"
                    />
                </form>
            </IonContent>
            <IonModal
                isOpen={showModal}
                backdropDismiss={false}
                keyboardClose={false}
                cssClass="my-custom-class"
            >
                <VerificationComponent
                    userProfile={userProfile}
                    onDismiss={onDismiss}
                    setUserProfile={setUserProfile}
                />
            </IonModal>
        </IonPage>
    );
};

export default connect<{}, any, DispatchProps>({
    mapStateToProps: (state) => ({
        darkMode: state.user.darkMode,

        userProfile:
            state.user.isLoggedin &&
            state.user.userProfile != null &&
            state.user.userProfile != undefined &&
            state.user?.userProfile,
    }),
    mapDispatchToProps: {
        setUserProfile,
    },
    component: React.memo(CompleteSignUp),
});


