import { IonRoute } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { useAuth } from '../hooks/use-auth';

export const NonAuthRoute: React.FC<any> = ({
    component: Component,
    ...rest
}) => {
    const { isAuthenticated, localLogin } = useAuth();

    // useSelector(
    //     (state: any) => state?.user.isLoggedin ?? false
    // );

    // useSelector(
    //     (state: any) =>
    //         state.user.isLoggedin &&
    //         state.user.userProfile != null &&
    //         state.user.userProfile != undefined &&
    //         state.user.userProfile.localLogin == true
    // );
    return (
        <IonRoute
            {...rest}
            exact
            render={(props) =>
                isAuthenticated === false ? (
                    <Component style={{}} {...props} />
                ) : localLogin && isAuthenticated == true ? (
                    <Redirect from={props.path} to="/tab/home" exact />
                ) : (
                    <Redirect
                        from={props.path}
                        to="/verification-account"
                        exact
                    />
                )
            }
        />
    );
};
