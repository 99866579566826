import { IonSlide, IonSlides } from '@ionic/react';
import React,{ forwardRef, useImperativeHandle, useRef} from 'react';
import './style.scss';
//{ onShowImage, files,ref }
export const SliderImage: React.FC<any> = forwardRef((props, ref) => {
    const slideRef = useRef<any>();
    const { onShowImage, files } = props;
  useImperativeHandle(ref, () => {
    return {
      slideTo: slideTo,
    };
  });
  const slideTo = (index:any) => {
    slideRef.current.slideTo(index);
  };
    return (
        <IonSlides className="slider-image-post" ref={slideRef} pager={true}>
            {files && files.length > 0 ? (
                files.map(
                    (
                        data: {
                            path: string;
                        },
                        index: number
                    ) => {
                        return (
                            <IonSlide
                                key={index}
                                onClick={() => onShowImage(index, files)}
                            >
                                <img src={data.path} />
                            </IonSlide>
                        );
                    }
                )
            ) : (
                <IonSlide onClick={onShowImage}>
                    <img src="https://i.pinimg.com/236x/6d/d1/69/6dd169b9f706e2d16bc0d4f8814f6a85.jpg" />
                </IonSlide>
            )}
        </IonSlides>
    );
});
//export default React.memo(SliderImage);
