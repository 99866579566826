import { setNotificationsSections } from '../../storage/management-Storage';
import {
    ADD_TO_STATS,
    ADD_TO_STATS_WISHLIST,
    DECREASE_TO_STATS_CART,
    DECREASE_WISHLIST_STATS,
    DECREASE_WISHLIST_STATS_PAGE,
    DELETE_ALL_STATS,
    DELETE_TO_STATS_CART,
    INCREASE_TO_STATS_CART,
    LOAD_STATE_NOTIFICATION,
} from './stats.actions';

const initState: any = { wishlist_count: 0, cart_count: 0 };
const statsReducer = (state = initState, action: any) => {

    switch (action.type) {
        case LOAD_STATE_NOTIFICATION:
            state=action.payload

            return state;
        case ADD_TO_STATS:
  
            const status = action.payload;
            state = status;
        //   setNotificationsSections(state);
            return state;
        case ADD_TO_STATS_WISHLIST:
         
            let oldData:any=state.wishlist_count;
            oldData =oldData+ action.payload??0.5;
            state.wishlist_count=oldData
            setNotificationsSections(state);
            return state;
        case DECREASE_WISHLIST_STATS:
            if (state.wishlist_count > 0) {
                state.wishlist_count -= 1;
                setNotificationsSections(state);
            }

            return state
          

            case DECREASE_WISHLIST_STATS_PAGE:
            if(state.wishlist_count>0)
                state.wishlist_count =state.wishlist_count- 1;
                setNotificationsSections(state);
                return state
        case DELETE_ALL_STATS: {
            state.wishlist_count = 0;
            state.cart_count = 0;
            setNotificationsSections(state);
            return state;
        }
        case INCREASE_TO_STATS_CART:
            state.cart_count += 1;
        
            setNotificationsSections(state);
            return state;
        case DECREASE_TO_STATS_CART:
            if (state.cart_count > 0) {
                state.cart_count -= 1;
            }
            setNotificationsSections(state);
            return state;
        case DELETE_TO_STATS_CART:
            state.cart_count = 0;
            setNotificationsSections(state);
            return state;

        default:
            return state;
    }
};
export default statsReducer;
