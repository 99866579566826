import axios from 'axios';
import { axiosApi } from '.';
interface AddProductsReviewParameter {
    rating: string | number;
    reviewer_name?: string;
    comment?: string;
}
const getProducts = () => {
    return axiosApi.get('/products');
};
const getProductsSuggestions = () => {
    return axiosApi.get('/products/suggestions');
};
const getProduct = (slug: string) => {
    return axiosApi.get(`/products/${slug}`);
};

const getProductSearchName = (page: string, name: string) => {
    return axiosApi.get(`/products?${page}&query=${name}&sort=latest`);
};
const getProductFormattedPrices = (id: number) => {
    return axiosApi.post(`/products/${id}/price`);
};
const getProductReviews = (productId: string) => {
    return axiosApi.get(`/products/${productId}/reviews`);
};
const addProductReview = (
    productId: string,
    body: AddProductsReviewParameter
) => {
    return axiosApi.post(`/products/${productId}/reviews`, body);
};

const productsApis = {
    getProducts,
    getProductsSuggestions,
    getProduct,
    addProductReview,
    getProductReviews,
    getProductFormattedPrices,
    getProductSearchName,
};
export default productsApis;
