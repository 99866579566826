import { IonContent, IonText, IonTextarea } from "@ionic/react";
import { FooterPage } from "../../../../layout/footer-page";
import { HeaderModal } from "../../../../layout/Header/header-modal";
import Button from '../../../../components/button/index'


import './style.scss'
import { RatingStarts } from "../../../../components/rating-starts/rating-starts";
import { useTranslation } from "react-i18next";
export const BodyRateOrder: React.FC<{
    count: number;
    onDismiss: () => void;
    onIncrement: () => void;
  }> = ({ count, onDismiss, onIncrement }) => {
    const { t } = useTranslation("common");
    return (
      <> <HeaderModal onClose={onDismiss} title={t("Rate_Your_Order")} /> 
      
      <IonContent className="content-color ion-padding body-rate-order">
        
       <IonText className="text-title">{t("Please_rate_the_order")}</IonText>
       <div className="area-review">
         <IonTextarea  className="input-area" rows={4} placeholder={t("Your_Review")} />
       
       </div>
     <div className="rating-star">
       <div>
       <RatingStarts />
       </div>
     </div>
         </IonContent> 
         
         <FooterPage>
           <Button text={t("Publish_My_Review")} expand="full" color="none" height={36}></Button>
     {/*   <InputComment/> */}
         </FooterPage>
         </>
       );
  };