import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonPage,
    IonRadioGroup,
    useIonToast,
} from '@ionic/react';
import { createOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ItemRadio } from '../../components/item-radio';
import { Modal } from '../../components/modal';
import { Toast } from '../../components/toast';
import { PHONE_NUMBER_SUPPORT } from '../../helpers/cons';
import { HeaderSubPage } from '../../layout/Header/header-sub-page';
import { LoadingCenter } from '../../layout/loading-center';
import apis from '../../services';
import { BodyChangeAddress } from '../checkout-page/components/body-change-address';
import { BodyCancelOrder } from './component/body-cancel-order';
import { ItemInfoOrder } from './component/item-info-order';
import { StepProgressBar } from './component/progress-bar';
const stateOrderr = (state: string) => {
    switch (state) {
        case 'created':
            return 0;
        case 'confirm':
        case 'ready':
            return 1;
        case 'closed':
            return -1;
        case 'delivered':
        case 'returned':
            return 3;
        case 'shipped':
            return 2;
        default:
            return 0;
    }
};
const OrderInfo: React.FC = () => {
    const { t } = useTranslation('common');
    const { id }: any = useParams();
    const refModal = useRef<any>(null);
    const [present, dismiss] = useIonToast();

    const refChangeAddress = useRef<any>();
    const handelShowModelCancelOrder = () => {
        if (refModal.current) {
            refModal.current.showModal();
        }
    };
    const [orderInfo, setOrderInfo] = useState<any>({
        data: {},
        loading: true,
        errorMessage: null,
    });
    useEffect(() => {
        refrashData();
    }, []);
    const refrashData = () => {
        apis.getOrder(id)
            .then((response) => {
                setOrderInfo({
                    data: response.data.order,
                    loading: false,
                    errorMessage: null,
                });
            })
            .catch((error) => {
                setOrderInfo({
                    loading: false,
                    errorMessage: 'Error Request',
                });
            });
    };
    const onSelectNewAddress = (newAddressId: any, newAddress: any) => {
        // setLoadingOnSubmit(true);
        apis.updateCheckout({
            order_id: orderInfo.data.id,
            shipping: {
                first_name: newAddress.first_name,
                last_name: newAddress.last_name,
                address_1: newAddress.address_1,
                city: newAddress.city,
                zip: newAddress.zip,
                state: newAddress.state,
                country: newAddress.country,
            },
            shipping_city_code: newAddress.city_id,
            shipping_state_code: newAddress.state_id,
            payment_method: 'cod',
            terms_and_conditions: true,
        })
            .then((response) => {
                //setLoadingOnSubmit(false);
                refrashData();
            })
            .catch((error) => {
                Toast(present, t('Error_Occurred'), 1500, 'error');
            })
            .finally(() => {
                // setLoadingOnSubmit(false);
            });
    };
    const changeAddress = () => {
        if (refChangeAddress.current) {
            refChangeAddress.current.showModal();
        }
    };
    const refLink = useRef<any>();

    return (
        <IonPage>
            <HeaderSubPage
                title={t('NO_') + id}
                defaultHref="/page/my-orders"
            ></HeaderSubPage>
            <IonContent className="content-color">
                {orderInfo.loading == false ? (
                    orderInfo.errorMessage == null && orderInfo.data ? (
                        <>
                            <ItemInfoOrder
                                images={orderInfo.data.products?.map(
                                    (data: any) => {
                                        return data?.product?.base_image?.path;
                                    }
                                )}
                                onClickCancelOrder={handelShowModelCancelOrder}
                                productsName={
                                    orderInfo.data?.products?.map(
                                        (data: any) => {
                                            return data.product.name + ',';
                                        }
                                    ) ?? t('Demo')
                                }
                                state={stateOrderr(orderInfo.data.status) ?? 0}
                                price={Number(orderInfo.data.total.amount)}
                            />
                            <Modal
                                id={orderInfo.data.id}
                                status={orderInfo.data.status}
                                Body={BodyCancelOrder}
                                idOrder={orderInfo.data.id}
                                ref={refModal}
                            />
                            <StepProgressBar
                                state={stateOrderr(orderInfo.data.status)}
                            />
                            <IonRadioGroup className="checkout" value={'1'}>
                                <ItemRadio
                                    refrashData={refrashData}
                                    isChange={true}
                                    onClick={changeAddress}
                                    descrption={
                                        orderInfo.data?.shipping_city ??
                                        t('Please_Add_Address_From_Setting')
                                    }
                                    name={
                                        orderInfo.data?.shipping_address_1 ??
                                        t('Please_Add_Address')
                                    }
                                    value={'1'}
                                />
                            </IonRadioGroup>
                            <IonItem
                                onClick={() => refLink.current.click()}
                                lines="none"
                                color="none"
                                style={{
                                    background: '#f9f9f9',
                                    borderBottom: '1.2px solid #e0e0e0',
                                }}
                            >
                                <IonLabel>
                                    <h2 className="item-content-h">
                                        {t('contentWithSupport')}{' '}
                                    </h2>
                                    <p className="item-content-p">whatsup</p>
                                </IonLabel>
                                <IonIcon
                                    icon={
                                        process.env.PUBLIC_URL +
                                        '/assets/svg/whatsapp.svg'
                                    }
                                ></IonIcon>
                            </IonItem>

                            <a
                                hidden
                                ref={refLink}
                                href={`https://api.whatsapp.com/send?phone=${PHONE_NUMBER_SUPPORT}&text=${id}اريد الاستفسار عن اطلبية  التاليه  `}
                            ></a>
                        </>
                    ) : (
                        t('Error_Occurred')
                    )
                ) : (
                    <LoadingCenter />
                )}
            </IonContent>
            <Modal
                Body={BodyChangeAddress}
                ref={refChangeAddress}
                refrashData={refrashData}
                onSelectNewAddress={onSelectNewAddress}
            />
        </IonPage>
    );
};
export default React.memo(OrderInfo);
