import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/button";
import { FooterPage } from "../../layout/footer-page";
import { HeaderSubPage } from "../../layout/Header/header-sub-page";
import { FormAddPaymentMethods } from "./form";
import "./style.scss";
const AddPaymentMethods: React.FC = () => {
  const { t } = useTranslation("common");
  return (
    <IonPage>
      <HeaderSubPage
        defaultHref="/page/payment-methods/"
        title={t("Add_Payment_Methods")}
      />
      <IonContent fullscreen className="content-color ion-padding">
        <div className="add-payment-methods">
          <FormAddPaymentMethods />
        </div>
      </IonContent>

      <FooterPage>
        <Button color="none" expand="full" text={t("ADD_MY_CARD")} />
      </FooterPage>
    </IonPage>
  );
};
export default React.memo(AddPaymentMethods);
