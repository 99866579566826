import React, { useRef } from 'react';

import {
    IonChip,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonRow,
} from '@ionic/react';
import { checkmarkCircleSharp } from 'ionicons/icons';
import './style.scss';
import { IPropsProgressBar } from './props.progress-bar';
import { ItemProgress } from './model/Item-progress';
import { StepProgess } from './model/step-progress';
import { Modal } from '../../../../components/modal';
import { BodyRateOrder } from '../body-rate-order';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
export const StepProgressBar: React.FC<IPropsProgressBar> = ({ state }) => {
    const { t } = useTranslation("common");
    const refComment = useRef<any>();
    const onShowComment = () => {
        if (refComment.current) {
            refComment.current.showModal();
        }
    };
    return (
        <div className="progress-orders-vartecl">
            <IonRow className="ion-padding">
                <IonCol size="2" sizeLg="1" sizeXl="1" sizeMd="1">
                    <div className="progess">
                        <StepProgess state={state} />
                        {/*       <div
            className="step -contenu"
              style={{
                background: "#6680C6 ",
      
              }}
            >
              <IonIcon style={{fontSize:"35px",position:"relative",top:"15px",left:"2px"}} color="light" icon={checkmarkCircleSharp}></IonIcon>
            </div> */}
                    </div>
                </IonCol>
                <IonCol size="10" sizeLg="11" sizeXl="11" sizeMd="11">
                    {' '}
                    <IonRow>
                        <IonCol>
                            {' '}
                            <ItemProgress
                                title={t("Order_Received")}
                                description={t("Your_request_has_been_received_we_ar_reviewing")}
                                state={true}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow style={{ marginTop: '20px' }}>
                        {' '}
                        <IonCol>
                            <ItemProgress
                                title={t("Preparing")}
                                description={t("Your_request_has_been_approved")}
                                state={true}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow style={{ marginTop: '35px' }}>
                        {' '}
                        <IonCol>
                            <ItemProgress
                                title={t("On_The_Way")}
                                description={t("The_order_has_been_prepared")}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow style={{ marginTop: '35px' }}>
                        {' '}
                        <IonCol>
                            <ItemProgress
                                title={t("Delivered")}
                                description={t("The_order_has_been_prepared")}
                            />

                            {state == 3 ? (
                                <>
                                    {' '}
                                    <IonChip
                                        onClick={onShowComment}
                                        mode="md"
                                        className="rate-order"
                                    >
                                        {t("Rate_Order")}
                                    </IonChip>
                                    <Modal
                                        Body={BodyRateOrder}
                                        ref={refComment}
                                    ></Modal>
                                </>
                            ) : (
                                <></>
                            )}
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
        </div>
    );
};
