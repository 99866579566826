import { IonButton, IonContent, useIonToast } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { ItemComment } from '../../../../../components/item-comment';
import { FooterPage } from '../../../../../layout/footer-page';
import { HeaderModal } from '../../../../../layout/Header/header-modal';
import apis from '../../../../../services';
import { InputComment } from './model/input-comment';
import moment from 'moment';
import { LoadingCenter } from '../../../../../layout/loading-center';
import { Toast } from '../../../../../components/toast';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AVATAR_PROFILE } from '../../../../../helpers/cons';

export const BodyCommentList: React.FC<{
    props: any;
    onDismiss: () => void;
    onIncrement: () => void;
}> = (props) => {
    const { t } = useTranslation('common');
    const [present, dismiss] = useIonToast();
    const { idProduct, isLogin } = props.props;
    const [comments, setComments] = useState<any>({
        data: [],
        loading: true,
        errorMessage: null,
    });
    const getDataComment = () => {
        apis.getProductComments(idProduct)
            .then((respone) => {
                setComments({
                    data: respone.data,
                    loading: false,
                    errorMessage: null,
                });
            })
            .catch((error) => {
                Toast(present, t('Error_Occurred'));
                setComments({ loading: false, errorMessage: 'Error Request' });
            });
    };

    useEffect(() => {
        getDataComment();
    }, []);
    return (
        <>
            {' '}
            <HeaderModal onClose={props.onDismiss} title={t('Comments')} />{' '}
            <IonContent className="content-color">
                {comments.loading == false ? (
                    comments.data?.comments.data?.length > 0 &&
                    comments.data.comments.data.map(
                        (data: any, index: number) => {
                            return (
                                <ItemComment
                                    key={index}
                                    imgPath={
                                        data.reviewer_picture
                                            ? data.reviewer_picture?.path
                                            : AVATAR_PROFILE
                                    }
                                    name={data.reviewer_name}
                                    time={moment(data.created_at).format(
                                        'hh:mm A'
                                    )}
                                    comment={data.comment}
                                />
                            );
                        }
                    )
                ) : (
                    <LoadingCenter />
                )}
            </IonContent>
            {isLogin && comments.loading == false ? (
                <FooterPage>
                    <InputComment
                        refresh={getDataComment}
                        idProduct={idProduct}
                    />
                </FooterPage>
            ) : (
                ''
            )}
        </>
    );
};
