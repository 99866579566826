import {
    IonButton,
    IonButtons,
    IonIcon,
    IonItem,
    IonLabel,
    IonThumbnail,
} from '@ionic/react';
import { heartSharp } from 'ionicons/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFromWishlistDispatch } from '../../../../../redux/favorite/favorite.actions';
import { decreaseToStatsWishlist } from '../../../../../redux/stats/stats.actions';
import apis from '../../../../../services';
import { IPropsListItemWishlist } from './props.list--item-wishlist';
import './style.scss';
const ListItemWishlist: React.FC<IPropsListItemWishlist> = ({
    imgPath,
    price,
    category,
    name,
    productId,
    refresh,
    slug,
    onClick,
    data,
    decreaseToStatsWishlist,
    deleteFromWishlistDispatch,
    isLogin,
}) => {
    const onRemoveProductWhishList = (productId: number) => {
        if (isLogin) {
            apis.deleteWishlistProduct(productId)
                .then(() => {
                    refresh && refresh();
                })
                .then(() => {
                    decreaseToStatsWishlist();
                });
        } else {
            decreaseToStatsWishlist();
            deleteFromWishlistDispatch(data);
        }
    };

    return (
        <IonItem
            onClick={onClick && onClick}
            lines="none"
            color="NONE"
            className="list-item-wishlist"
        >
            <IonThumbnail slot="start" className="thumbnail">
                <img src={imgPath} />
            </IonThumbnail>
            <IonLabel>
                <h2 className="title">{name}</h2>
                <p className="category">{category}</p>
                <p className="price">{price}</p>
            </IonLabel>
            <IonButtons
                onClick={(e) => {
                    e.isPropagationStopped();
                    e.stopPropagation();
                    onRemoveProductWhishList(productId);
                }}
                slot="end"
            >
                <IonButton color="pink">
                    <IonIcon slot="icon-only" icon={heartSharp}></IonIcon>
                </IonButton>
            </IonButtons>
        </IonItem>
    );
};
export default React.memo(ListItemWishlist);
