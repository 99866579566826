import { IonContent, IonPage } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from '../../../components/modal';
import CardPost from './component/card-post';
import { BodyCommentList } from './component/Body-comments-list';
import './style.scss';
import { HeaderMain } from '../../../layout/Header/header-main';
import { BodyShowImages } from '../../../layout/body-show-images';
import apis from '../../../services';
import { LoadingCenter } from '../../../layout/loading-center';
import { Refresher } from '../../../components/refresher';
import { InfiniteScroll } from '../../../components/Infinite-scroll';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../hooks/use-auth';

const Community: React.FC = () => {
    const [currentVideo, setCurrentVideo] = useState<any>(null);
    const { t } = useTranslation('common');
    const refComment = useRef<any>();
    const refShowIamges = useRef<any>();
    const { isAuthenticated } = useAuth();
    const [posts, setPosts] = useState<any>({
        data: [],
        loading: true,
        errorMessage: null,
    });
    const [commentID, setCommentId] = useState(0);
    const getData = () => {
        apis.getPosts()
            .then((response) => {
                if (response.data)
                    setPosts({
                        data: response.data,
                        loading: false,
                        errorMessage: null,
                    });
            })
            .catch(() => {
                setPosts({ loading: false, errorMessage: 'error Request' });
            });
    };
    useEffect(() => {
        getData();
    }, []);
    const onShowComment = (id: any) => {
        if (refComment.current) {
            setCommentId(id);
            refComment.current.showModal();
        }
    };
    const [images, setImages] = useState([]);
    const [numberImage, setNumberImage] = useState(0);
    const onShowImage = (id: number, images: any) => {
        if (refShowIamges.current) {
            setNumberImage(id);
            setImages(images);
            refShowIamges.current.showModal();
        }
    };
    const searchNext = (event: any) => {
        event.target.disabled = false;
        if (posts.data?.from < posts.data?.last_page) {
            apis.getPosts(`?page=${posts.data?.current_page + 1}`).then(
                (response) => {
                    for (let i = 0; i < posts.data.products.data.length; i++) {
                        response.data.products.data.push(
                            posts.data.products.data[i]
                        );
                    }
                    setPosts({
                        data: response.data,
                        loading: false,
                        errorMessage: null,
                    });
                }
            );
            event.target.complete();
        } else {
            event.target.disabled = true;
        }
    };

    return (
        <IonPage>
            <HeaderMain
                title={t('WoW_Community')}
                subTitle={t('You_are_in_community_Guest')}
            />

            <IonContent className="content-color">
                <Refresher Refresh={getData} />
                {posts.loading == false ? (
                    posts.errorMessage == null &&
                    posts.data.data?.length > 0 &&
                    Object.values(posts.data?.data).map(
                        (data: any, index: number) => {
                            return (
                                <CardPost
                                    comments_count={data.comments_count}
                                    created_at={data.created_at}
                                    files={data.files}
                                    post_media={data.post_media}
                                    title={data.title}
                                    key={index}
                                    onShowImage={onShowImage}
                                    onShowComment={() => onShowComment(data.id)}
                                    setCurrentVideo={setCurrentVideo}
                                    currentVideo={currentVideo}
                                ></CardPost>
                            );
                        }
                    )
                ) : (
                    <LoadingCenter />
                )}

                <Modal
                    Body={BodyCommentList}
                    idProduct={commentID}
                    isLogin={isAuthenticated}
                    ref={refComment}
                ></Modal>
                <Modal
                    Body={BodyShowImages}
                    numberImage={numberImage}
                    image={images}
                    ref={refShowIamges}
                ></Modal>
                <InfiniteScroll
                    disableInfiniteScroll={false}
                    searchNext={searchNext}
                ></InfiniteScroll>
            </IonContent>
        </IonPage>
    );
};
export default React.memo(Community);
