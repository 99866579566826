import {
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { arrowBack, arrowForward } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IPropsHeaderProduct } from './props.header-product';
import './style.scss';
export const HeaderPorduct: React.FC<IPropsHeaderProduct> = ({
    defaultHref,
    title,
}) => {
    const { t, i18n } = useTranslation('common');

    return (
        <IonHeader color="none" className="header-product ion-no-border">
            <IonToolbar color="none">
                <IonButtons slot="start">
                    <IonBackButton
                        mode="md"
                        icon={i18n.language == 'ar' ? arrowForward : arrowBack}
                        className="icon-title"
                        text={t('Back')}
                        defaultHref={defaultHref}
                    ></IonBackButton>
                </IonButtons>

                <IonTitle className="title">{title}</IonTitle>
            </IonToolbar>
        </IonHeader>
    );
};
