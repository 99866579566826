import React from "react";
import { ReactSVG } from "react-svg";

import { IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
export const EmptyReviews: React.FC = () => {
  const { t } = useTranslation("common");
  return (
    <div className="order-placed">
      <ReactSVG
        wrapper="span"
        className="wrapper-class-name"
        evalScripts="always"
        beforeInjection={(svg) => {
          svg.classList.add("svg-class-name");
          svg.setAttribute("style", "width: 200px");
          svg.setAttribute("style", "height: 200px");
        }}
        src={process.env.PUBLIC_URL + "/assets/svg/rating.svg"}
      />
      {/*             <ReactSVG  className="img" src={process.env.PUBLIC_URL + "/assets/svg/internet-connection.svg"} />
       */}{" "}
      <div className="title">
        <IonText className="text">{t("Empty_Reviews")}</IonText>
      </div>
      <div className="subtitle">
        <IonText className="text">
          {t("Empty_Reviews_Long")}
        </IonText>
      </div>
    </div>
  );
};
