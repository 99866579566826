import { IonItemDivider, IonText } from "@ionic/react";
import React from "react";
import { ItemDetails } from "./model/item-details";
import { IPropstemDetails } from "./model/props.item-details";
import { IPropsDetailsCheckout } from "./props.details-checkout";
export const DetailsCheckout: React.FC <IPropsDetailsCheckout>= React.memo(({item}) => {
  return (
    <div>
        {item.map((data,index:number)=>{
return  <ItemDetails id={data.id} key={index} value={data.value} name={data.name}/>
        })}
   
    </div>
  );
});
