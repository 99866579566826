import cartReducer from './cart/cart.reducer';
import { combineReducers } from './combineReducers';
import { configReducer } from './config/config.reducer';
import wishlistReducer from './favorite/favorite.reducer';
import filterReducer from './filter/filter.reducer';
import statsReducer from './stats/stats.reducer';
import userReducer from './user/user.reducer';

export const initialState: any = {
    data: {
        data: {
            loading: false,
            menuEnabled: true,
        },
        user: {
            hasSeen: {},
            darkMode: false,
            isLoggedin: false,
            loading: true,
            settings: { lang_tag: 'ar' },
            userProfile: undefined,
        },
    },

    user: {
        darkMode: false,
        isLoggedin: false,
        loading: false,
    },
};

export const reducers = combineReducers({
    data: configReducer,
    user: userReducer,
    cartData: cartReducer,
    wishlistData: wishlistReducer,
    statsCount: statsReducer,
    filterReducer: filterReducer,
});

export type AppState = ReturnType<typeof reducers>;
