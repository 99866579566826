import { IonItem, IonLabel } from '@ionic/react';
import { IPropsItemProgess } from './props.item-progess';
import './style.scss';
export const ItemProgress: React.FC<IPropsItemProgess> = ({
    title,
    description,
    state,
}) => {
    return (
        <IonItem className="item-progeress" color="none" lines="none">
            <IonLabel>
                <h2 className="title">{title}</h2>
                <p className="descrptions">{description}</p>
            </IonLabel>
        </IonItem>
    );
};
