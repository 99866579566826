import { IonItemDivider, IonRouterLink, IonText } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IPropsHeaderSection } from './porps.header-section';
import './style.scss';
export const HeaderSection: React.FC<IPropsHeaderSection> = ({
    title,
    routerLink,
    showAll,
}) => {
    const { t } = useTranslation('common');
    return (
        <IonItemDivider mode="ios" className="header-section">
            <IonText className="text-title" slot="start">
                {title}
            </IonText>
            {routerLink && (
                <IonRouterLink
                    className="link-all"
                    slot="end"
                    routerLink={routerLink && routerLink}
                >
                    {showAll ?? t('Show_All')}
                </IonRouterLink>
            )}
        </IonItemDivider>
    );
};
