import { IonContent } from '@ionic/react';
import { list } from 'ionicons/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { InfiniteScroll } from '../../../../../components/Infinite-scroll';
import { ItemLoading } from '../../../../../components/item-loading';
import apis from '../../../../../services';
import { HeaderModal } from '../../../header-modal';
import { ItemDivider } from './model/item-divider';
import { ItemSearch } from './model/item-search';
import { SearchInput } from './model/search-input';

export const BodySearchProduct: React.FC<{
    count: number;
    onDismiss: () => void;
    onIncrement: () => void;
    props: {
        lang: string | 'ar' | 'en';
        history: any;
    };
}> = ({ count, onDismiss, onIncrement, props }) => {
    const { t } = useTranslation('common');
    const history = useHistory();
    const [listSearch, setListSearch] = useState<any>({
        data: [],
        loading: false,
        errorMessage: null,
    });
    const [searchText, setSearchText] = useState('');
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const onKeyPress = (e: any) => {
        e.preventDefault();
        if (e.target.value.length > 0) {
            setIsSearch(true);
        } else {
            setIsSearch(false);
        }
        setSearchText(e.target.value);
        listSearch.loading = true;
        setListSearch(listSearch);
        apis.getProductSearchName('page=1', e.target.value)
            .then((response) => {
                setListSearch({
                    data: response.data,
                    loading: false,
                    errorMessage: null,
                });
                if (
                    response.data.products.current_page ===
                    response.data.products.last_page
                ) {
                    setDisableInfiniteScroll(true);
                } else {
                    setDisableInfiniteScroll(false);
                }
            })
            .catch((error) => {
                setListSearch({
                    loading: false,
                    errorMessage: 'error Request',
                });
            });
    };
    const searchNext = (event: any) => {
        if (
            listSearch.data?.products.current_page <
                listSearch.data.products?.last_page &&
            listSearch.data?.products.from != null
        ) {
            setDisableInfiniteScroll(false);
            let dataOld: any = [...listSearch.data.products.data];
            apis.getProductSearchName(
                `page=${listSearch.data?.products?.current_page + 1}`,
                searchText
            ).then((response) => {
                for (let i = 0; i < response.data.products.data.length; i++) {
                    dataOld.push(response.data.products.data[i]);
                }

                response.data.products.data = dataOld;
                setListSearch({
                    data: response.data,
                    loading: false,
                    errorMessage: null,
                });
                event.target.complete();
                setDisableInfiniteScroll(true);
            });
        } else {
            event.target.disabled = true;
        }
    };

    return (
        <>
            {' '}
            <HeaderModal onClose={onDismiss} title={t('Search')} />
            <IonContent
                className="content-color "
                style={{ direction: props.lang === 'ar' ? 'rtl' : 'ltr' }}
            >
                <SearchInput onKeyPress={onKeyPress} />

                {isSearch ? (
                    <>
                        <ItemDivider title={t('Results')} />
                        {listSearch.loading == false
                            ? listSearch.data?.products?.data.length > 0
                                ? listSearch.data?.products?.data.map(
                                      (data: any, index: number) => {
                                          console.log(
                                              'ASDASDASD' + JSON.stringify(data)
                                          );
                                          return (
                                              <ItemSearch
                                                  routerLink={() => {
                                                      props.history.push(
                                                          `/page/product/${data.slug}`
                                                      );
                                                      onDismiss();
                                                  }}
                                                  slug={data.slug}
                                                  key={index}
                                                  img={data?.base_image.path}
                                                  name={data.name}
                                                  price={Number(
                                                      data.price.amount
                                                  )}
                                                  category={
                                                      data.categories[0]
                                                          ?.name ?? ''
                                                  }
                                                  isDiscount={
                                                      data.special_price
                                                          ? true
                                                          : false
                                                  }
                                                  discount_price={Number(
                                                      data.selling_price.amount
                                                  )}
                                              />
                                          );
                                      }
                                  )
                                : t('Empty')
                            : Array(4)
                                  .fill(6)
                                  .map((data, index) => {
                                      return (
                                          <ItemLoading
                                              index={index}
                                              key={index}
                                          />
                                      );
                                  })}
                    </>
                ) : (
                    <div style={{ textAlign: 'center' }}>
                        {' '}
                        <ReactSVG
                            beforeInjection={(svg) => {
                                svg.classList.add('svg-class-name');
                                svg.setAttribute('style', 'width: 200px');
                                svg.setAttribute('style', 'height: 200px');
                            }}
                            loading={() => <span>Loading</span>}
                            src={
                                process.env.PUBLIC_URL +
                                '/assets/svg/search.svg'
                            }
                        />{' '}
                    </div>
                )}

                {/* {!isSearch ? (
          <>
            <ItemDivider title={t("Recent_Search")} />
            <ItemSearch
              img={
                "https://thumbs.dreamstime.com/b/photo-young-beautiful-smiling-good-mood-girl-showing-recommending-product-isolated-pink-color-background-photo-young-212067212.jpg"
              }
              name="Dress Title"
              price={50}
              category="Dresses"
              isDiscount={false}
              discount_price={40}
            />
            <ItemSearch
              img={
                "https://thumbs.dreamstime.com/b/photo-young-beautiful-smiling-good-mood-girl-showing-recommending-product-isolated-pink-color-background-photo-young-212067212.jpg"
              }
              name="Dress Title"
              price={50}
              category="Dresses"
              isDiscount={true}
              discount_price={50}
            />

            <ItemDivider title={t("Offers")} />
            <ItemSearch
              img={
                "https://thumbs.dreamstime.com/b/photo-young-beautiful-smiling-good-mood-girl-showing-recommending-product-isolated-pink-color-background-photo-young-212067212.jpg"
              }
              name="Dress Title"
              price={50}
              category="Dresses"
              isDiscount={true}
              discount_price={80}
            />
            <ItemSearch
              img={
                "https://thumbs.dreamstime.com/b/photo-young-beautiful-smiling-good-mood-girl-showing-recommending-product-isolated-pink-color-background-photo-young-212067212.jpg"
              }
              name="Dress Title"
              price={50}
              category="Dresses"
              isDiscount={true}
              discount_price={70}
            />
            <ItemSearch
              img={
                "https://thumbs.dreamstime.com/b/photo-young-beautiful-smiling-good-mood-girl-showing-recommending-product-isolated-pink-color-background-photo-young-212067212.jpg"
              }
              name="Dress Title"
              price={50}
              category="Dresses"
              isDiscount={true}
              discount_price={60}
            />
          </>
        ) : (
          ""
        )} */}
                <InfiniteScroll
                    disableInfiniteScroll={disableInfiniteScroll}
                    searchNext={searchNext}
                />
            </IonContent>
        </>
    );
};
