import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locale/en.json';
import ar from './locale/ar.json';
i18n.use(initReactI18next).init({
    resources: {
        en: {
            common: en, // 'common' is our custom namespace
        },
        ar: {
            common: ar,
        },
    },
    lng: 'ar',
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
        escapeValue: true,
        formatSeparator: ',',
    },
    react: {
        wait: true,
    },
});
export default i18n;
