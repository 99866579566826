import { IonFooter, IonToolbar } from '@ionic/react'
import React from 'react'
import './style.scss'
export const FooterPage:React.FC=({children})=>{

return(
    <IonFooter className="footer ion-no-border">
        <IonToolbar>
           {children}
        </IonToolbar>
    </IonFooter>
)
}