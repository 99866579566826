import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { ISelectProps } from './props';
import './style.scss';
export const Select: React.FC<ISelectProps> = ({
    options,
    label,
    onChange,
    disabled,
    ...register
}) => {
    return (
        <IonItem lines="none" className="item-input">
            {/*  <div className="input-floating-item">
        <div className="floating-label"> */}
            {/*   <input
            {...register}
            required={required}
            type={type}
            placeholder=" "
          /> */}
            <IonLabel position="floating">{label}</IonLabel>
            <IonSelect
                disabled={disabled && disabled}
                onIonChange={(e) => onChange && onChange(e.detail.value)}
            >
                {options
                    ? options &&
                      options.map((data: any, index: number) => {
                          return (
                              <IonSelectOption key={index} value={data.id}>
                                  {data.name_ar}
                              </IonSelectOption>
                          );
                      })
                    : ''}
            </IonSelect>

            {/*        <span className="highlight" />
          <label>{label}</label>
        </div>
      </div> */}
        </IonItem>
    );
};
