import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonSpinner,
    IonText,
    IonTitle,
    IonToolbar,
    useIonToast,
} from '@ionic/react';
import { arrowBack, arrowForward } from 'ionicons/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ReactSVG } from 'react-svg';
import { Toast } from '../../components/toast';
import { HeaderSubPage } from '../../layout/Header/header-sub-page';
import { connect } from '../../redux/connect';
import { setIsLoggedIn, setUserProfile } from '../../redux/user/user.actions';
import apis from '../../services';
import './style.scss';
interface DispatchProps {}
const VerificationForgotPage: React.FC<any> = ({ userProfile }) => {
    const history = useHistory();
    const [verifiocationCode, setVerifiocationCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [present, dismiss] = useIonToast();
    const { t } = useTranslation('common');

    const onActiveCode = (code: string) => {
        setVerifiocationCode(code);

        if (code.length == 4) {
            setLoading(true);
            apis.resetPasswordComplete(
                userProfile?.main_phone ?? localStorage.getItem('username'),
                code
            )
                .then((data: any) => {
                    setLoading(false);
                    if (data?.data) {
                        localStorage.setItem(
                            'reset-user',
                            JSON.stringify(data?.data)
                        );
                        history.push('/Change-password-reset');
                        Toast(
                            present,
                            t('Now_you_can_reset_your_password'),
                            1500,
                            'sucsess'
                        );
                    } else {
                        Toast(present, t('Code_Invalid'), 1500, 'error');
                    }
                })
                .catch((error) => {
                    Toast(present, t('Code_Invalid'), 1500, 'error');
                    setLoading(false);
                });
        }
    };
    return (
        <IonPage>
            <IonHeader color="none" className="header-sub">
                <IonToolbar color="none">
                    <IonTitle className="title">{t('Verification')}</IonTitle>
                    <IonButtons className="back-buttom" slot="start">
                        <IonBackButton>
                            <IonIcon
                                slot="icon-only"
                                icon={arrowBack}
                            ></IonIcon>
                        </IonBackButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent className="content-color">
                <div className="verification">
                    <div className="order-placed">
                        <ReactSVG
                            wrapper="span"
                            className="wrapper-class-name"
                            evalScripts="always"
                            beforeInjection={(svg) => {
                                svg.classList.add('svg-class-name');
                                svg.setAttribute('style', 'width: 200px');
                            }}
                            src={
                                process.env.PUBLIC_URL +
                                '/assets/svg/internet-connection.svg'
                            }
                        />
                        {/*             <ReactSVG  className="img" src={process.env.PUBLIC_URL + "/assets/svg/internet-connection.svg"} />
                         */}{' '}
                        <div className="title">
                            <IonText className="text">
                                {t('Enter_Activation_Code')}
                            </IonText>
                        </div>
                        <div style={{ paddingTop: '20px' }}>
                            {loading && (
                                <IonSpinner className="loading-spiner-center"></IonSpinner>
                            )}
                            <div
                                style={{
                                    background: loading ? '#f5f5f5c4' : '',
                                }}
                            >
                                <ReactInputVerificationCode
                                    placeholder="__"
                                    value={verifiocationCode}
                                    onChange={onActiveCode}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default connect<{}, any, DispatchProps>({
    mapStateToProps: (state) => ({
        darkMode: state.user.darkMode,
        userProfile: state.user.userProfile,
        isAuthenticated: state.user.isLoggedin,
        wishlistLocalStorage: state.wishlistData,
        localLogin:
            state.user.isLoggedin &&
            state.user.userProfile != null &&
            state.user.userProfile != undefined &&
            state.user.userProfile.localLogin == true,
    }),
    mapDispatchToProps: {},
    component: React.memo(VerificationForgotPage),
});
