import { IonBackButton, IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/react'
import React from 'react'
import './style.scss'
import {IPropsHeaderSubPage} from './props.header-sub-page'
import { useTranslation } from 'react-i18next'
export const HeaderSubPage:React.FC<IPropsHeaderSubPage>=React.memo(({title,defaultHref})=>{
    const { t } = useTranslation("common");
    return(<IonHeader color="none" className="header-sub">
        <IonToolbar color="none">
<IonTitle className="title">
    {title}
</IonTitle>
            <IonButtons className="back-buttom" slot="start">
               <IonBackButton defaultHref={defaultHref} text={t("Back")}></IonBackButton> 
            </IonButtons>
        </IonToolbar>
    </IonHeader>)
})