import { ActionType } from '../../helpers/util/types';
import { setLangTagGlobalHeaders, setUserGlobalHeaders } from '../../modules/global-data';
import {
    getCardProductsData,
    getFilterData,
    getUserData,
    getWishlistData,
    setDarkModeData,
    setHasSeenData,
    setIsLoggedInData,
    setSettingsData,
    setStorageOnlyData,
    setUserProfileData,
} from '../../storage/management-Storage';
import { ISettingsConf } from '../../storage/models/setting';
import { IUserProfile } from '../../storage/models/user-profile';
import { addToCartDispatch, LoadDataCart } from '../cart/cart.actions';
import { addToWishlistDispatch, loadWihlistProduct } from '../favorite/favorite.actions';
import { loadFilterData } from '../filter/filter.actions';
import { loadStateNotification } from '../stats/stats.actions';
import { IUserState } from './user.interface';

export const loadUserData = () => async (dispatch: React.Dispatch<any>) => {
    dispatch(setLoading(true));
    const data = await getUserData();
  
//     dispatch(setSettings(data.settings))
const dataCart= await getCardProductsData()
 const wishlistProduct=await getWishlistData()
 const filterCategory=await getFilterData()
 dispatch(LoadDataCart(dataCart))
 dispatch(setData(data))
 dispatch(loadWihlistProduct(wishlistProduct))
 dispatch(loadStateNotification(data.notificationStatus))
  dispatch(loadFilterData(filterCategory))
 dispatch(setLoading(false));


};

export const setLoading = (isLoading: boolean)  =>

    ({
        type: 'set-user-loading',
        isLoading,
    } as const);

export const setData = (data: Partial<IUserState>) => {
   

    return {
        type: 'set-user-data',
        data,
    };
};
export const logoutUser = () => async (dispatch: React.Dispatch<any>) => {
  
    await setIsLoggedInData(false);
    dispatch(setStorageOnlyData());
    dispatch(setUserProfile());
 
};

export const setIsLoggedIn =  (loggedIn: boolean) =>async(dispatch: React.Dispatch<any>)=>{

    await setIsLoggedInData(loggedIn);
  
    return ({
        type: 'set-is-loggedin',
        loggedIn,
    })as const;
};
export const setSettings =
    (settings?: ISettingsConf) => async (dispatch: React.Dispatch<any>) => {
 
     
        await setSettingsData(settings);
         return({
            type: 'set-settings',
            settings,
        });
    };

export const setUserProfile =  (userProfile?: IUserProfile) =>async (dispatch: React.Dispatch<any>)=> {
    setUserGlobalHeaders(
        userProfile?.user_id,
        'user',
        userProfile?.fullName,
        userProfile?.avatar,
        userProfile?.main_phone,
        userProfile?.reviews_count,
        userProfile?.order_count,
        userProfile?.addresses_count,
        userProfile?.password,
        userProfile?.phone_verified,
    );
    await setUserProfileData(userProfile);

    return {
        type: 'set-user-profile',
        userProfile,
    };
};

export const setHasSeen =
    (hasSeen?: any) => async (dispatch: React.Dispatch<any>) => {
        await setHasSeenData(hasSeen);
        dispatch({
            type: 'set-has-seen',
            hasSeen,
        });
    };

export const setDarkMode =
    (darkMode: boolean) => async (dispatch: React.Dispatch<any>) => {
        await setDarkModeData(darkMode);
        dispatch({
            type: 'set-dark-mode',
            darkMode,
        });
    };

export type UserActions =
    | ActionType<typeof setLoading>
    | ActionType<typeof setData>
    | ActionType<typeof setSettings>
    | ActionType<typeof setUserProfile>
    | ActionType<typeof setIsLoggedIn>
    | ActionType<typeof setHasSeen>
    | ActionType<typeof setDarkMode>;
