import { IonButton, IonContent, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import Button from "../../../components/button";
import { FooterPage } from "../../footer-page";

export const CardsPaymentEmpty: React.FC = () => {
  const { t } = useTranslation("common");
  return (
    <>
      <IonContent className="content-color">
        <div className="order-placed">
      <img    src={process.env.PUBLIC_URL + "/assets/svg/payment-empty.svg"}/>
 
          <div className="title">
            <IonText className="text">{t("No_Cards_Here")}</IonText>
          </div>
          <div className="subtitle">
            <IonText className="text">
            {t("Look_like_there_no_cards_connected")}
            </IonText>
          </div>
        </div>
      </IonContent>
    </>
  );
};
