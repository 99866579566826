import {
    IonButton,
    IonButtons,
    IonContent,
    IonIcon,
    IonItem,
    useIonToast,
} from '@ionic/react';
import { sendOutline } from 'ionicons/icons';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import StarRatingComponent from 'react-star-rating-component';
import { ItemComment } from '../../../../components/item-comment';
import { Toast } from '../../../../components/toast';
import { AVATAR_PROFILE } from '../../../../helpers/cons';
import { FooterPage } from '../../../../layout/footer-page';
import { HeaderModal } from '../../../../layout/Header/header-modal';
import { LoadingCenter } from '../../../../layout/loading-center';
import apis from '../../../../services';
import { RankStart } from './rank';

export const BodyReviews: React.FC<{
    props: any;
    onDismiss: () => void;
    onIncrement: () => void;
}> = (props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const [rank, setRank] = useState(5);
    const { t } = useTranslation('common');
    const [present, dismiss] = useIonToast();
    const [loading, setLoading] = useState(false);
    const { idProduct, userProfile, isLoggedin } = props.props;
    const [comments, setComments] = useState<any>({
        data: [],
        loading: true,
        errorMessage: null,
    });
    const getDataComment = () => {
        apis.getProductReviews(idProduct)
            .then((respone) => {
                setComments({
                    data: respone.data,
                    loading: false,
                    errorMessage: null,
                });
            })
            .catch((error) => {
                Toast(present, t('Error_Occurred'));
                setComments({ loading: false, errorMessage: 'Error Request' });
            });
    };
    useEffect(() => {
        getDataComment();
    }, []);
    const onSubmit = (data: { reviews_comment: string }) => {
        setLoading(true);
        var reviewComment = data.reviews_comment;
        reset();
        apis.addProductReview(idProduct, {
            rating: rank === 0 ? 1 : rank,
            reviewer_name: userProfile?.fullName ?? 'Demo',
            comment: reviewComment,
        }).finally(() => {
            setLoading(false);
            getDataComment();
        });
    };
    return (
        <>
            {' '}
            <HeaderModal onClose={props.onDismiss} title={t('Reviews')} />{' '}
            <IonContent className="content-color">
                {comments.loading == false ? (
                    comments.data?.data?.length > 0 &&
                    comments.data.data.map((data: any, index: number) => {
                        return (
                            <ItemComment
                                rating={data.rating}
                                key={index}
                                imgPath={
                                    data.reviewer_picture?.path ??
                                    AVATAR_PROFILE
                                }
                                name={data.reviewer_name}
                                time={moment(data.created_at).format('hh:mm A')}
                                comment={data.comment}
                            />
                        );
                    })
                ) : (
                    <LoadingCenter />
                )}
            </IonContent>
            {isLoggedin && comments.loading == false ? (
                <FooterPage>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <RankStart
                                count={5}
                                value={rank}
                                onChange={(value: any) => setRank(value)}
                                size={40}
                            />
                        </div>
                        <IonItem
                            color="none"
                            lines="none"
                            className="footer-input"
                        >
                            <input
                                {...register('reviews_comment', {
                                    required: true,
                                })}
                                className="input"
                                placeholder={t('Write_your_Review_here')}
                            />
                            <IonButtons>
                                <IonButton
                                    onClick={handleSubmit(onSubmit)}
                                    // disabled={loading}
                                >
                                    <IonIcon
                                        className="icon-color"
                                        icon={sendOutline}
                                    ></IonIcon>
                                </IonButton>
                            </IonButtons>
                        </IonItem>
                    </form>
                </FooterPage>
            ) : (
                ''
            )}
        </>
    );
};
