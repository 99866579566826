import { IonButton, IonButtons, IonContent, IonFooter, IonIcon, IonLabel, ToastOptions, useIonToast, UseIonToastResult } from "@ionic/react";
import { close } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import Button from '../../components/button/index';
export const DownloadApp:React.FC<any>=({closeDialog})=>{
  const { t } = useTranslation('common');

  return(<>
  <IonContent>
    <div style={{textAlign: "center",padding:"10px",paddingTop:"40px"}}>
    <img style={{width:"50px"}} src={process.env.PUBLIC_URL+"/assets/svg/logo-sm.svg"}/>
    <IonButton style={{position:"absolute",top:"0px",right:"0px"}} fill="clear" onClick={closeDialog}>
      <IonIcon slot="icon-only" icon={close} />
    </IonButton>
     <IonLabel className="header-lable">
        <p>
        <h2>{t("You_still_have_an_app_not_installed")}</h2>
        </p>
        <p>
        <h2>{t("Do_you_want_to_install_it_now")}</h2>
        </p>
    </IonLabel>
    <br/>
    <div style={{margin: "auto",width:"70%"}}>
    <Button
                                width="100%"
                                text={t('INSTALL')
                                }
                                href="http://onelink.to/hg3bsb"
                                color="none"
                                height={38}
                                expand="block"
                            />
                            
    </div>
    
  </div>
</IonContent>
</>
)
  }
  