import { IonText } from '@ionic/react';
import { StoriesSliderProps } from './props';
import './style.scss';
export const StoriesSlider: React.FC<StoriesSliderProps> = ({
    stories,
    onClick,
}) => {
    return (
        <div className="scrolling-wrapperr">
            {stories &&
                stories.map((data: any, index: number) => {
                    return (
                        <div
                            onClick={() => onClick(data)}
                            style={{
                                backgroundImage: data[0]?.post_media
                                    ?.thumbnail_image
                                    ? `url(${
                                          data[0]?.post_media
                                              ?.thumbnail_image ??
                                          data[0]?.post_media?.path
                                      })`
                                    : `url(${data[0]?.post_media?.path})`,
                            }}
                            className="card"
                        >
                            <IonText className="text">
                                {data[0]?.category.name?.split(9, 1000)}
                            </IonText>
                            {/* <img
                                className="image"
                                style={{
                                    borderRadius: '12px',
                                    width: '120px',
                                    height: '168px',
                                }}
                                src={}
                            ></img> */}
                        </div>
                    );
                })}
        </div>
    );
};
