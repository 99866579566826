import {
    IonContent,
    IonPage,
    useIonToast,
    useIonViewDidEnter,
    useIonViewWillEnter,
} from '@ionic/react';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button from '../../../components/button';
import { Refresher } from '../../../components/refresher';
import { Toast } from '../../../components/toast';
import { CURRENCY_LI } from '../../../helpers/currency';
import { useStorage } from '../../../hooks/use-storage';
import { BagEmpty } from '../../../layout/errors/bag-empty';
import { FooterPage } from '../../../layout/footer-page';
import { HeaderMain } from '../../../layout/Header/header-main';
import { LoadingCenter } from '../../../layout/loading-center';
import { getGlobalHeaders } from '../../../modules/global-data';
import {
    decreaseQuantity,
    deleteAllFromCartDispatch,
    increaseQuantity,
} from '../../../redux/cart/cart.actions';
import { connect } from '../../../redux/connect';
import { decreaseToStatsCart } from '../../../redux/stats/stats.actions';
import apis, { axiosApi } from '../../../services';
import { ItemProduct } from './component/item-product';
import './style.scss';
interface DispatchProps {
    deleteAllFromCartDispatch: typeof deleteAllFromCartDispatch;
    increaseQuantity: typeof increaseQuantity;
    decreaseToStatsCart: typeof decreaseToStatsCart;
    decreaseQuantity: typeof decreaseQuantity;
}
const CartBag: React.FC<any> = ({
    cartDataLocalStorage,
    localLogin,
    deleteAllFromCartDispatch,
    increaseQuantity,
    decreaseToStatsCart,
    decreaseQuantity,
}) => {
    const { t } = useTranslation('common');
    const [cart, setCart] = useState<any>({
        data: {},
        loading: true,
        errorMessage: null,
    });
    const [present] = useIonToast();
    const history = useHistory();

    const feath = async () => {
        cart.loading = true;
        setCart(cart);
        await apis
            .getCartDetails()
            .then((respone) => {
                setCart({
                    data: respone.data,
                    loading: false,
                    errorMessage: null,
                });
            })
            .catch((error) => {
                setCart({ loading: false, errorMessage: 'error' });
            });
    };
    const isAuthenticated = localLogin;
    useIonViewWillEnter(() => {
        if (isAuthenticated) {
            let products = [];
            if (cartDataLocalStorage) {
                for (let i = 0; i < cartDataLocalStorage.length; i++) {
                    products.push({
                        product_id: cartDataLocalStorage[i].id,
                        qty: cartDataLocalStorage[i].quantity,
                        options:
                            cartDataLocalStorage[i]?.selectedProductColor ?? {},
                    });
                }
            }
            try {
                if (products.length > 0) {
                    apis.addItemsToCart({ products: products }).then(() => {
                        deleteAllFromCartDispatch();
                    });
                }

                feath();
            } catch (e) {}
        }
    });

    useIonViewDidEnter(() => {
        feath();
    });

    const refresh = async () =>
        await apis
            .getCartDetails()
            .then((respone) => {
                setCart({
                    data: respone.data,
                    loading: false,
                    errorMessage: null,
                });
            })
            .catch((error) => {
                setCart({ loading: false, errorMessage: 'error' });
            });
    const emptyToast = () => {
        Toast(present, t('Empty_Card'), 1500, 'error');
    };
    const lang: { lang_tag: string } = {
        lang_tag: getGlobalHeaders().lang_tag ?? 'ar',
    };
    const hnadelRedirect = (event: any, id: string) => {
        event.preventDefault();
        history.push(`/page/product/${id}`);
    };
    console.log(isAuthenticated);
    return (
        <IonPage className="cart-bag">
            <HeaderMain title={t('My_Bag')} subTitle={t('Your_bag_Guest')} />
            {isAuthenticated &&
            cart.loading == false &&
            cart?.data &&
            Object.values(cart?.data?.items)?.length == 0 ? (
                <BagEmpty refresh={refresh} />
            ) : (
                <>
                    <IonContent className="content-color">
                        <Refresher Refresh={feath} />

                        {isAuthenticated ? (
                            cart.loading == false ? (
                                cart.data?.items ? (
                                    Object.values(cart.data?.items)?.map(
                                        (data: any, index: number) => {
                                            return (
                                                <ItemProduct
                                                    decreaseToStatsCart={
                                                        decreaseToStatsCart
                                                    }
                                                    decreaseQuantity={
                                                        decreaseQuantity
                                                    }
                                                    increaseQuantity={
                                                        increaseQuantity
                                                    }
                                                    isLogin={isAuthenticated}
                                                    onClick={(event: any) =>
                                                        hnadelRedirect(
                                                            event,
                                                            data.product.slug
                                                        )
                                                    }
                                                    slug={data.product.slug}
                                                    lang={lang.lang_tag}
                                                    refresh={refresh}
                                                    idItemCart={data.id}
                                                    productStock={
                                                        data.product.qty
                                                    }
                                                    qty={data.qty}
                                                    base_image={
                                                        data.product.base_image
                                                            ?.path
                                                    }
                                                    name={data.product.name}
                                                    key={index}
                                                    price={
                                                        data.unitPrice
                                                            ?.formatted
                                                    }
                                                    options={data.options}
                                                />
                                            );
                                        }
                                    )
                                ) : (
                                    <BagEmpty refresh={refresh} />
                                )
                            ) : (
                                <LoadingCenter />
                            )
                        ) : cartDataLocalStorage &&
                          cartDataLocalStorage.length > 0 ? (
                            cartDataLocalStorage.map(
                                (data: any, index: number) => {
                                    return (
                                        <>
                                            <ItemProduct
                                                decreaseToStatsCart={
                                                    decreaseToStatsCart
                                                }
                                                decreaseQuantity={
                                                    decreaseQuantity
                                                }
                                                increaseQuantity={
                                                    increaseQuantity
                                                }
                                                onClick={(event: any) =>
                                                    hnadelRedirect(
                                                        event,
                                                        data?.slug
                                                    )
                                                }
                                                isLogin={isAuthenticated}
                                                optionData={
                                                    data?.selectedProductColor
                                                }
                                                slug={data?.slug}
                                                lang={lang.lang_tag}
                                                dispatch={() => console.log('')}
                                                refresh={refresh}
                                                idItemCart={data.id}
                                                dataStorage={data}
                                                productStock={data.qty}
                                                qty={data.quantity}
                                                base_image={
                                                    data.base_image?.path
                                                }
                                                name={data.name}
                                                key={index}
                                                price={
                                                    data.selling_price
                                                        ?.formatted
                                                }
                                                options={data.options}
                                            />
                                        </>
                                    );
                                }
                            )
                        ) : (
                            <BagEmpty refresh={() => emptyToast()} />
                        )}
                    </IonContent>

                    {isAuthenticated ? (
                        cart.loading == false && cart.data?.items ? (
                            <FooterPage>
                                <Button
                                    routerLink={'/page/checkout'}
                                    height={40}
                                    expand="full"
                                    text={t('Checkout')}
                                    color="none"
                                />
                                <p slot="end" className="price-footer">
                                    {cart.loading == false &&
                                        cart.data.total.formatted}
                                </p>
                            </FooterPage>
                        ) : (
                            ''
                        )
                    ) : (
                        cartDataLocalStorage &&
                        cartDataLocalStorage?.length > 0 && (
                            <Button
                                routerLink={'/login'}
                                height={40}
                                expand="full"
                                text={t('Login')}
                                color="none"
                            />
                        )
                    )}
                </>
            )}
        </IonPage>
    );
};

export default connect<{}, any, DispatchProps>({
    mapStateToProps: (state) => ({
        darkMode: state.user.darkMode,
        userProfile: state.user.userProfile,
        isAuthenticated: state.user.isLoggedin,
        localLogin:
            state.user.isLoggedin &&
            state.user.userProfile != null &&
            state.user.userProfile != undefined &&
            state.user.userProfile.localLogin == true,
        cartDataLocalStorage: state.cartData,
    }),
    mapDispatchToProps: {
        deleteAllFromCartDispatch,
        increaseQuantity,
        decreaseToStatsCart,
        decreaseQuantity,
    },
    component: React.memo(CartBag),
});
