import {
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonModal,
    IonPage,
    IonRadioGroup,
    IonRow,
    IonText,
    IonToolbar,
    useIonToast,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button from '../../components/button';
import { ItemRadio } from '../../components/item-radio';
import { Modal } from '../../components/modal';
import { Toast } from '../../components/toast';
import { FooterPage } from '../../layout/footer-page';
import { HeaderSubPage } from '../../layout/Header/header-sub-page';
import { LoadingCenter } from '../../layout/loading-center';
import { connect } from '../../redux/connect';
import { deleteToStatsCart } from '../../redux/stats/stats.actions';
import { setUserProfile } from '../../redux/user/user.actions';
import apis from '../../services';
import { VerificationComponent } from '../verification/verification-component';
import { BodyChangeAddress } from './components/body-change-address';
import { ItemCheckout } from './components/item-checkout';
import { DetailsCheckout } from './details-checkout';
interface DispatchProps {
    deleteToStatsCart: typeof deleteToStatsCart;
    setUserProfile: typeof setUserProfile;
}
const CheckoutPage: React.FC<any> = ({ userProfile, deleteToStatsCart,setUserProfile }) => {
    const { t } = useTranslation('common');
    const [checkoutData, setCheckoutData] = useState<any>({
        data: [],
        loading: true,
        errorMessage: null,
    });
    const [showModal, setShowModal] = useState(false);
    const [present, dismiss] = useIonToast();
    const refChangeAddress = useRef<any>();
    const [loadingOnSubmit, setLoadingOnSubmit] = useState(false);
    const changeDefaultAddress = () => {
        if (refChangeAddress.current) {
            refChangeAddress.current.showModal();
        }
    };
    const onDismiss = (goback: boolean) => {
        setShowModal(false);
    };
    const refrashData = () => {
        apis.getCheckoutDetails()
            .then((response) => {
                setCheckoutData({
                    data: response.data,
                    loading: false,
                    errorMessage: null,
                });
            })
            .catch((error) => {
                setCheckoutData({
                    loading: false,
                    errorMessage: 'error Request',
                });
            });
    };
    const history = useHistory();
    useEffect(() => {
        refrashData();
    }, []);
    const handelVerifyAccount = () => {
        if(userProfile.social_id || !userProfile.main_phone){
            history.push('/complete-sign-up');
        }
       else{
        setCheckoutData({
            data: checkoutData?.data,
            loading: true,
            errorMessage: null,
        });
        apis.requestVerifyUserAccount({
            phone: userProfile.main_phone,
        })
            .then((respone) => {
                setShowModal(true);
            }).finally(()=>{
                setCheckoutData({
                    data: checkoutData?.data,
                    loading: false,
                    errorMessage: null,
                });
            });
       }
    };

    const handelCreateCheckout = (
        address: {
            first_name: string;
            last_name: string;
            address_1: string;
            city: string;
            zip: string;
            state: string;
            country: string;
            city_id: string;
            state_id: string;
        },
        payment_method: any,
        shipping_method: string
    ) => {
        setLoadingOnSubmit(true);
        apis.createCheckout({
            shipping: {
                first_name: address.first_name,
                last_name: address.last_name,
                address_1: address.address_1,
                city: address.city,
                zip: address.zip,
                state: address.state,
                country: address.country,
            },
            shipping_city_code: address.city_id,
            shipping_state_code: address.state_id,
            shipping_method: shipping_method,
            payment_method: 'cod',
            terms_and_conditions: true,
        })
            .then((response) => {
                setLoadingOnSubmit(false);
                deleteToStatsCart();
                history.replace(`/page/place-order/${response.data.orderId}`);
            })
            .catch((error) => {
                setLoadingOnSubmit(false);
                Toast(present, t('Error_Occurred'), 1500, 'error');
            })
            .finally(() => {
                setLoadingOnSubmit(false);
            });
    };

    return (
        <IonPage>
            <HeaderSubPage
                defaultHref="/tab/cart"
                title={t('Checkout')}
            ></HeaderSubPage>

            <IonContent className="content-color">
                {checkoutData.loading == false &&
                checkoutData.errorMessage == null ? (
                    checkoutData.data && (
                        <>
                            <IonRadioGroup className="checkout" value="2">
                                <ItemRadio
                                    isChange={false}
                                    /*  descrption="Work address"  */ name={t(
                                        'Cash_on_delivery'
                                    )}
                                    value="2"
                                />{' '}
                            </IonRadioGroup>
                            <IonRadioGroup
                                className="checkout"
                                value={
                                    checkoutData.data?.defaultAddress
                                        ?.address_id
                                }
                            >
                                <ItemRadio
                                    refrashData={refrashData}
                                    isChange={true}
                                    onClick={changeDefaultAddress}
                                    descrption={
                                        checkoutData.data?.defaultAddress
                                            ?.address?.city ??
                                        t('Please_Add_Address_From_Setting')
                                    }
                                    name={
                                        checkoutData.data?.defaultAddress
                                            ?.address?.address_1 ??
                                        t('Please_Add_Address')
                                    }
                                    value={
                                        checkoutData.data?.defaultAddress
                                            ?.address_id
                                    }
                                />
                            </IonRadioGroup>
                            {Object.values(checkoutData.data?.cart?.items).map(
                                (data: any, index: number) => {
                                    return (
                                        <ItemCheckout
                                            options={data.options}
                                            key={index}
                                            name={data.product.name}
                                            imgPath={
                                                data.product.base_image.path
                                            }
                                            price={
                                                data.product.selling_price
                                                    .formatted
                                            }
                                            quantity={data.qty}
                                        />
                                    );
                                }
                            )}
                        </>
                    )
                ) : (
                    <LoadingCenter />
                )}
            </IonContent>

            {checkoutData.loading === false &&
                checkoutData.errorMessage === null &&
                checkoutData.data && (
                    <DetailsCheckout
                        item={[
                            {
                                name: t('Delivery'),
                                value: checkoutData.data?.cart
                                    ?.availableShippingMethods?.local_pickup
                                    ?.cost?.amount,
                                id: 2,
                            },
                            {
                                name: t('Sub_Total'),
                                value: checkoutData.data.cart.subTotal.amount,
                                id: 3,
                            },
                            {
                                name: t('Total'),
                                value:
                                    checkoutData.data.cart.subTotal.amount ===
                                    checkoutData.data.cart.total.amount
                                        ? Number(
                                              checkoutData.data.cart.total
                                                  .amount
                                          ) +
                                          Number(
                                              checkoutData.data?.cart
                                                  ?.availableShippingMethods
                                                  ?.local_pickup?.cost?.amount
                                          )
                                        : checkoutData.data.cart.total.amount,
                                id: 4,
                            },
                        ]}
                    ></DetailsCheckout>
                )}
            {checkoutData.loading == false &&
            checkoutData.errorMessage == null ? (
                <FooterPage>
                    <Button
                        hidden={!userProfile?.phone_verified}
                        disabled={
                            loadingOnSubmit
                            //||
                            // checkoutData.data.defaultAddress.address ===
                            //     undefined
                            //     ? true
                            //     : false
                        }
                        loading={loadingOnSubmit}
                        onClick={() =>
                            checkoutData.data.defaultAddress.address ===
                            undefined
                                ? changeDefaultAddress()
                                : handelCreateCheckout(
                                      checkoutData.data?.defaultAddress
                                          ?.address,
                                      checkoutData.data?.gateways?.cod,
                                      checkoutData.data?.cart
                                          ?.availableShippingMethods
                                          ?.local_pickup?.name
                                  )
                        }
                        expand="full"
                        color="none"
                        height={38}
                        text={t(
                            checkoutData.data.defaultAddress.address ===
                                undefined
                                ? 'Please_Add_Address'
                                : 'PLACE_ORDER'
                        )}
                    />
                    <IonGrid hidden={userProfile?.phone_verified}>
                        <IonRow>
                            <IonCol style={{ textAlign: 'center' }}>
                                <IonText
                                    style={{
                                        color: '#F69400',
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {t('Before_place_order_verify_account')}
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <Button
                                    onClick={() => handelVerifyAccount()}
                                    expand="full"
                                    color="none"
                                    height={38}
                                    text={t('Verify_Account')}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </FooterPage>
            ) : (
                ''
            )}
            <Modal
                Body={BodyChangeAddress}
                ref={refChangeAddress}
                refrashData={refrashData}
            />
            <IonModal
                isOpen={showModal}
                backdropDismiss={false}
                keyboardClose={false}
                cssClass="my-custom-class"
            >
                <VerificationComponent
                    userProfile={userProfile}
                    onDismiss={onDismiss}
                    setUserProfile={setUserProfile}
                />
            </IonModal>
        </IonPage>
    );
};

export default connect<{}, any, DispatchProps>({
    mapStateToProps: (state) => ({
        darkMode: state.user.darkMode,

        userProfile:
            state.user.isLoggedin &&
            state.user.userProfile != null &&
            state.user.userProfile != undefined &&
            state.user?.userProfile,
    }),
    mapDispatchToProps: {
        deleteToStatsCart,
        setUserProfile,
    },
    component: React.memo(CheckoutPage),
});
