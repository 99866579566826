import { IonItem, IonLabel, IonSkeletonText, IonThumbnail } from '@ionic/react';

export const ItemLoading: React.FC<{ index: number }> = ({ index }) => {
    return (
        <IonItem
            color="none"
            lines="none"
            style={{ borderBottom: '1.2px solid #e0e0e0' }}
        >
            <IonThumbnail style={{ borderRadius: '10px' }} slot="start">
                <IonSkeletonText style={{ borderRadius: '10px' }} animated />
            </IonThumbnail>
            <IonLabel>
                <h3>
                    <IonSkeletonText animated style={{ width: '80%' }} />
                </h3>
                {index % 2 == 0 ? (
                    <>
                        <p>
                            <IonSkeletonText
                                animated
                                style={{ width: '60%' }}
                            />
                        </p>
                    </>
                ) : (
                    <>
                        <p>
                            <IonSkeletonText
                                animated
                                style={{ width: '80%' }}
                            />
                        </p>
                        <p>
                            <IonSkeletonText
                                animated
                                style={{ width: '60%' }}
                            />
                        </p>
                    </>
                )}
            </IonLabel>
        </IonItem>
    );
};
