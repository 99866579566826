import { IonContent, IonPage, IonRadioGroup } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/button";
import { ItemRadio } from "../../components/item-radio";
import { CardsPaymentEmpty } from "../../layout/errors/cards-payment-empty";
import { FooterPage } from "../../layout/footer-page";
import { HeaderSubPage } from "../../layout/Header/header-sub-page";
import "./style.scss";
const PaymentMethods: React.FC = () => {
  const { t } = useTranslation("common");
var isEmpty=true
  return (
    <IonPage>
      <HeaderSubPage defaultHref="/tab/profile" title={t("Payment_Methods")} />
      {isEmpty?
      <CardsPaymentEmpty/>
      :<IonContent className="content-color">
        <div className="payment-methods">
          <IonRadioGroup>
            <ItemRadio
              isDelete={true}
              descrption={t("Mastercard") + "- 06/23"}
              name="•••• •••• •••• 4432"
              value="1"
            />
            <ItemRadio
              isDelete={true}
              descrption={t("Visa") + "- 08/22"}
              name="•••• •••• •••• 4561"
              value="2"
            />
          </IonRadioGroup>
        </div>
      </IonContent>}
      <FooterPage>
        <Button
          routerLink="/page/payment-methods/add-payment-methods"
          text={t("ADD_MY_CARD")}
          expand="full"
          color="none"
        />
      </FooterPage>
    </IonPage>
  );
};
export default React.memo(PaymentMethods);
