export const ADD_VALUE_FILTER_VALUE = 'ADD_VALUE_FILTER_VALUE';
export const LOAD_FILTER_VALUE= "LOAD_FILTER_VALUE"
// add to wishlist
export const addToValueFilterDispatch =
    (item: any) => (dispatch: React.Dispatch<any>) => {
    
         dispatch({ type: ADD_VALUE_FILTER_VALUE, payload: item });
    };
    export const loadFilterData =
    (item: any)  => {
   
         return({ type: LOAD_FILTER_VALUE, payload: item });
    };
