import { IonImg, IonItem, IonLabel, IonThumbnail } from '@ionic/react';
import React from 'react';
import { IPropsItemComment } from './props.item-comment';
import './style.scss';
import StarRatingComponent from 'react-star-rating-component';

export const ItemComment: React.FC<IPropsItemComment> = React.memo(
    ({ imgPath, name, comment, time, rating }) => {
        return (
            <IonItem className="item-comment" lines="none" color="none">
                <IonThumbnail slot="start" className="thumbnail">
                    <IonImg className="img" src={imgPath} />
                </IonThumbnail>
                <IonLabel>
                    <h2>
                        <span className="name">{name}</span>
                        <span className="time">{time}</span>
                    </h2>
                    {rating ? (
                        <p>
                            <StarRatingComponent name="rank" value={rating} />
                        </p>
                    ) : (
                        ''
                    )}
                    <p className="commtent-content">{comment}</p>
                </IonLabel>
            </IonItem>
        );
    }
);
