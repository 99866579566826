import { axiosApi } from '.';

const getCountries = () => {
    return axiosApi.get('location/countries');
};
const getAllGovernates = () => {
    return axiosApi.get('/location/governates');
};
const getGovernatesOfCountry = (id: any) => {
    return axiosApi.get(`/location/countries/${id}`);
};
const getCitiesOfGovernate = (id: number) => {
    return axiosApi.get(`/location/governates/${id}`);
};
const locationApis = {
    getAllGovernates,
    getCountries,
    getGovernatesOfCountry,
    getCitiesOfGovernate,
};
export default locationApis;
