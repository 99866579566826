import { IonContent, IonPage, useIonToast } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../components/button/index';
import ItemInput from '../../components/item-input';
import { Toast } from '../../components/toast';
import { FooterPage } from '../../layout/footer-page';
import { HeaderSubPage } from '../../layout/Header/header-sub-page';
import apis from '../../services';
const ChangePassword: React.FC = () => {
    const { t } = useTranslation('common');
    const {
        register,
        watch,
        formState: { errors },
        handleSubmit,
    } = useForm();
    const password = useRef({});
    const [present, dismiss] = useIonToast();
    const [loading, setLoading] = useState(false);
    password.current = watch('newPassword', '');
    const onSubmit = (data: any) => {
        setLoading(true);
        apis.updatePassword({
            password: data.newPassword,
        })
            .then((response) => {
                Toast(present, response.data?.success ?? 'error', 1400);
            })
            .catch((error) => {
                Toast(present, t('Error_changing_password'), 1400, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <IonPage>
            <HeaderSubPage
                defaultHref="/page/settings"
                title={t('Change_Password')}
            />
            <IonContent className="content-color ion-padding">
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/*           <ItemInput {...register("password")} lines="none" type="password" lable={t("Current Password")} />
                     */}{' '}
                    <ItemInput
                        register={register('newPassword', { required: true })}
                        lines="none"
                        error={errors?.newPassword}
                        type="password"
                        lable={t('New_Password')}
                    />
                    <ItemInput
                        register={register('confirmPassword', {
                            required: true,
                            validate: (value) =>
                                value === password.current ||
                                t('The_passwords_do_not_match') + '',
                        })}
                        error={errors?.confirmPassword}
                        lines="none"
                        type="password"
                        lable={t('Confirm_New_Password')}
                    />
                </form>
            </IonContent>
            <FooterPage>
                <Button
                    disabled={loading}
                    loading={loading}
                    onClick={handleSubmit(onSubmit)}
                    text={t('Save_Changes')}
                    height={35}
                    expand="full"
                    color="none"
                />
            </FooterPage>
        </IonPage>
    );
};
export default React.memo(ChangePassword);
