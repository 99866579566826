import { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../redux/AppContext";

export function useStorage(){

    const { state, dispatch } = useContext(AppContext);
const [filter,setFilter]=useState([])
const [notificationStatus,setnotificationStatus]=useState({wishlist_count: 0, cart_count: 0})
useEffect(()=>{
    setnotificationStatus(state.statsCount)

},[state?.statsCount])

useEffect(()=>{
 
    setFilter(state.filterReducer)
},[filter])

    return {
        notificationStatus,filter
    }
}