export const SETTINGS = 'app-settings';
export const STORAGE_ONLY = 'storage-only';
export const USER_PROFILE = 'user-profile';
export const HAS_LOGGED_IN = 'has-loggedIn';
export const HAS_ACTIVED_IN = 'has-activedIn';

export const DARK_MODE = 'dark-Mode';
export const HAS_SEEN = 'has-Seen';
export const RECENT_SEARCH = 'recentSearch';
export const CARD_PRODUCT = 'card-product';
export const NOTIFICATION_SECTIONS = 'notification-sections';
export const WISHLIST_PRODUCT = 'wishlist-product';
export const FILTER_CATEGORY = 'filter_category';
