import {
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonRouterLink,
    IonText,
} from '@ionic/react';
import { trashOutline } from 'ionicons/icons';
import React from 'react';
import { IPropsItemRadio } from './props.item-radio';
import './style.scss';
export const ItemRadio: React.FC<IPropsItemRadio> = ({
    name,
    value,
    descrption,
    isChange,
    isDelete,
    onClick,
    refrashData,
    onClickDelete,
}) => {
    return (
        <IonItem
            button={onClick ? true : false}
            onClick={(e) => {
                e.preventDefault();
                onClick && onClick();
            }}
            className="item-radio"
            color="none"
            lines="none"
        >
            <IonRadio mode="md" value={value} slot="start"></IonRadio>
            <IonLabel className="title">
                {name}
                {descrption ? <p className="descrption">{descrption}</p> : ''}
            </IonLabel>
            {isChange ? (
                <IonRouterLink color="pink" className="change" slot="end">
                    Change
                </IonRouterLink>
            ) : (
                ''
            )}
            {isDelete ? (
                <IonButton
                    onClick={(e) => {
                        e.isPropagationStopped();
                        onClickDelete && onClickDelete();
                    }}
                    slot="end"
                    fill="clear"
                    className="change"
                    color="pink"
                >
                    <IonIcon color="danger" icon={trashOutline}></IonIcon>{' '}
                    Delete
                </IonButton>
            ) : (
                ''
            )}
        </IonItem>
    );
};
