import { IonContent, IonPage, useIonToast } from "@ionic/react";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Button from "../../components/button/index";
import ItemInput from "../../components/item-input";
import { Toast } from "../../components/toast";
import { FooterPage } from "../../layout/footer-page";
import { HeaderSubPage } from "../../layout/Header/header-sub-page";
import apis from "../../services";
const ForgotPassword: React.FC = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const email = useRef({});
  const [present, dismiss] = useIonToast();
  const [loading, setLoading] = useState(false);
  email.current = watch("email", "");
  const onSubmit = (data: any) => {
    setLoading(true);
    apis
      .resetPassword(data.email)
      .then((response) => {
        localStorage.setItem('username', data.email);
        history.push(`/verification-forgot`);
        Toast(present, response.data?.success ?? t("Error_Occurred"), 1400);
        
      })
      .catch((error) => {
        Toast(present, t("Error_Occurred"), 1400, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <IonPage>
      <HeaderSubPage defaultHref="/page/settings" title={t("Forgot_Password")} />
      <IonContent className="content-color ion-padding">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/*           <ItemInput {...register("password")} lines="none" type="password" lable={t("Current_Password")} />
           */}{" "}
          <ItemInput
            {...register("email", { required: true })}
            lines="none"
            error={errors?.email}
            type="text"
            lable={t("Phone_")}
          />
        </form>
      </IonContent>
      <FooterPage>
        <Button
          disabled={loading}
          loading={loading}
          onClick={handleSubmit(onSubmit)}
          text={t("Send_Reset_Code")}
          height={35}
          expand="full"
          color="none"
        />
      </FooterPage>
    </IonPage>
  );
};
export default React.memo(ForgotPassword);
