import {
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonPage,
    IonRow,
} from '@ionic/react';
import React, { useEffect } from 'react';
import SliderStarted from './components/Slider/slider-started';
import './index.scss';
import Button from '../../components/button/index';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const GetStartedPage: React.FC = () => {
    const { t } = useTranslation('common');

    useEffect(() => {
        /*  if (isLogin) history.push("/tab/home"); */
    }, []);
    return (
        <IonPage id="get-start">
            <IonContent scrollY={false} fullscreen>
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        background:
                            'transparent linear-gradient(180deg, #2D2D2D26 0%, #000000 100%) 0% 0% no-repeat padding-box',
                    }}
                ></div>
                <div
                    className="backgeound--page"
                    style={{
                        backgroundImage: `url(${
                            process.env.PUBLIC_URL + '/assets/slide_img.jpg'
                        })`,
                    }}
                >
                    <div className="content">
                        <div className="img-content">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/assets/svg/logo-sm.svg'
                                }
                            />
                        </div>

                        <SliderStarted></SliderStarted>

                        <IonGrid>
                            <IonRow>
                                <IonCol size="6">
                                    <Button
                                        height={'42px'}
                                        expand="full"
                                        color="none"
                                        fill="fill"
                                        routerLink="/tab/home"
                                        text={t('DISCOVER')}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <Button
                                        height={'42px'}
                                        expand="full"
                                        color="none"
                                        fill="clear"
                                        routerLink="/login"
                                        text={t('Login')}
                                    />
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};
export default GetStartedPage;
