import { useHistory } from 'react-router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useEffect } from 'react';
const AppUrlListener: React.FC<any> = () => {
    let history = useHistory();
    useEffect(() => {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            // Example url: https://beerswift.app/tabs/tab2
            // slug = /tabs/tab2
            const slug = event.url.split('turkishwow.com').pop();
            if (slug) {
                history.push(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    }, []);

    return null;
};

export default AppUrlListener;
