import { IonRoute } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

export const AuthRoute: React.FC<any> = React.memo(
    ({ component: Component, ...rest }) => {
        // const isAuthenticated = useSelector(
        //     (state: any) => state?.user.isLoggedin
        // );
        const isAuthenticated = true;
        const isActiveLogin = true;
        // const isActiveLogin = useSelector(
        //     (state: any) =>
        //         state.user.isLoggedin &&
        //         state.user.userProfile != null &&
        //         state.user.userProfile != undefined &&
        //         state.user.userProfile.localLogin == true
        // );
        return (
            <IonRoute
                {...rest}
                exact
                render={(props) =>
                    !isAuthenticated && isActiveLogin ? (
                        <Redirect from={props.path} to="/tab/home" exact />
                    ) : isActiveLogin && isAuthenticated ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            from={props.path}
                            to={'/verification-account'}
                            exact
                        ></Redirect>
                    )
                }
            />
        );
    }
);
