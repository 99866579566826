import { IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import { IPropsListMenu } from './props.list-menu';
import './style.scss';
export const ListMenu: React.FC<IPropsListMenu> = ({
    title,
    description,
    routerLink,
}) => {
    return (
        <IonItem
            routerLink={routerLink && routerLink}
            lines="none"
            color="none"
            className={'list-item'}
            button
        >
            <IonLabel>
                <h2 className="title">{title}</h2>
                <p className="description">{description}</p>
            </IonLabel>
        </IonItem>
    );
};
