import { IonChip } from '@ionic/react'
import React from 'react'
import { IPropsSizeChip } from './props.size-chip'
import './style.scss'
export const SizeChip:React.FC<IPropsSizeChip>=({onClick,name,state})=>{

 return(<IonChip
 onClick={onClick&&onClick}
 className={"ion-text-uppercase size-chip"+" "+state}
 color="none"
 >{name}</IonChip>)
}
