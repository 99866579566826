import {
  IonBadge,
  IonCard,
  IonCardContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonText,
  IonThumbnail,
} from "@ionic/react";
import { starOutline, star } from "ionicons/icons";
import { IPropsItemReview } from "./props.item-review";
import "./style.scss";
export const ItemReview: React.FC<IPropsItemReview> = ({
  name,
  dataTime,
  starNumber,
  content,
  image
}) => {
  return (
    <IonCard color="none" className="card-review">
      <IonItem color="none" lines="none">
        <IonThumbnail className="img-profile" slot="start">
          <img
            className="img-profile"
            src={image}
          />
        </IonThumbnail>
        <IonLabel>
          <h2 className="name"> {name}</h2>
          <p className="date-time">{dataTime}</p>
        </IonLabel>
        <IonBadge className="base" slot="end" color="success">
          <span className="content">
            {starNumber} <IonIcon className="icon-star" src={star} />
          </span>
        </IonBadge>
      </IonItem>
      <IonCardContent className="content-card">
        <IonText className="text">
         {content}
        </IonText>
      </IonCardContent>
    </IonCard>
  );
};
