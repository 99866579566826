import { axiosApi } from '.';

const getTag = (tag: string) => {
    return axiosApi.get(`/tags/${tag}`);
};
const getProductByTag = (tag: string, page?: string) => {
    return axiosApi.get(`/tags/${tag}/products${page ?? ''}`);
};
const getTags = () => {
    return axiosApi.get(`/tags`);
};

const tagsApis = { getTags, getTag, getProductByTag };
export default tagsApis;
