import { IonText, IonThumbnail } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { IPropsHeaderProfileInfo } from './props.header-profile-info';
import './style.scss';

export const HeaderProfileInfo: React.FC<IPropsHeaderProfileInfo> = ({
    img,
    name,
    email,
    onClick,
}) => {
    return (
        <div className="header-profile-info">
            <IonThumbnail
                onClick={onClick && onClick}
                className="thumbanail-profile"
            >
                <img src={img} />
                <div className="filter-img"></div>
            </IonThumbnail>
            <div className="name-content">
                <IonText className="name">{name}</IonText>
            </div>
            <div>
                <IonText className="email">{email}</IonText>
            </div>
        </div>
    );
};
