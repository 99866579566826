import {
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonRadioGroup,
    IonTitle,
    IonToolbar,
    useIonToast,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Buttons from '../../../../components/button';
import { ItemRadio } from '../../../../components/item-radio';
import { Modal } from '../../../../components/modal';
import { Toast } from '../../../../components/toast';
import { LoadingCenter } from '../../../../layout/loading-center';
import apis from '../../../../services';
import { BodyAddAddress } from '../body-add-address';

export const BodyChangeAddress: React.FC<{
    props: any;
    onDismiss: () => void;
    onIncrement: () => void;
}> = (props) => {
    const { t } = useTranslation('common');
    const refAddAddress = useRef<any>();

    const AddNewAddress = () => {
        if (refAddAddress.current) refAddAddress.current.showModal();
    };
    const [addresses, setAddresses] = useState<any>({
        data: [],
        loading: true,
        errorMessage: null,
    });
    const [present] = useIonToast();
    const [addressId, setAddressId] = useState<number>(-1);
    const getAddress = () => {
        apis.getAddress().then((respone) => {
            setAddresses({
                data: respone.data,
                loading: false,
                errorMessage: null,
            });
            if(respone.data.addresses && respone.data.addresses.length == 0){
                AddNewAddress();
            }
            if(!props?.props?.onSelectNewAddress){
                setAddressId(respone.data.defaultAddress?.address_id);
            }
        });
    };
    useEffect(() => {
        getAddress();
    }, []);

    const onChangeDefualtAddress = async (id: string) => {
        if(!props?.props?.onSelectNewAddress){
        await apis
            .changeDefaultAddress({
                address_id: id,
            })
            .then((response) => {
                Toast(present, response.data?.success ?? t('Success'), 1500);
                props.onDismiss()
            })
            .finally(() => {
                try {
                    props.props.refrashData();
                    // setAddressId(id as any);
                } catch (e) {
                    console.log('error refreash');
                }
            });
        // setAddressId(id as any);
        }
        else{
            try {
                props.props.onSelectNewAddress(id,Object.values(addresses.data.addresses)?.find((item:any)=> item.id == addressId))
                // setAddressId(id as any);
            } catch (e) {
                console.log('error refreash');
            }
            props.onDismiss()
        }
    };
    useEffect(() => {
        if(addressId == -1){
            return;
        }
        
        if(!props?.props?.onSelectNewAddress){
            if (addresses.data.defaultAddress?.address_id != addressId)
            onChangeDefualtAddress(addressId.toString());
        }
        else{
            onChangeDefualtAddress(addressId.toString());
        }
        
    }, [addressId]);
    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButtons onClick={() => props.onDismiss()} slot="start">
                        <IonButton>
                            <IonIcon slot="icon-only" icon={arrowBack} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle> {t('Change_Address')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="content-color">
                {addresses.loading == false && addresses.data ? (
                    <IonRadioGroup
                        onIonChange={(e) => {
                            e.preventDefault();
                            setAddressId(e.detail.value);
                        }}
                        value={addressId}
                        /*         value={addressId} */
                    >
                        {Object.values(addresses.data.addresses).map(
                            (data: any, index: number) => {
                                return (
                                    <ItemRadio
                                        key={index}
                                        isChange={true}
                                        descrption={data.city}
                                        name={data.address_1}
                                        value={data.id}
                                    />
                                );
                            }
                        )}
                        {/*     <ItemRadio isChange={true} descrption="Work address" name="Piata Unirii 2, Apartment 23…" value="2" /> */}
                    </IonRadioGroup>
                ) : (
                    <LoadingCenter />
                )}
            </IonContent>
            <IonFooter>
                <IonToolbar style={{ paddingRight: '5px', paddingLeft: '5px' }}>
                    <Buttons
                        onClick={AddNewAddress}
                        expand="full"
                        color="none"
                        text={t('Add_New_Address')}
                    />
                </IonToolbar>
            </IonFooter>
            <Modal
                Body={BodyAddAddress}
                getAddress={getAddress}
                ref={refAddAddress}
            />
        </>
    );
};
