import { IonButton, IonButtons, IonIcon } from '@ionic/react'
import React from 'react'
import { IPropsButtonTextIcon } from './props.button-text-icon'
import './style.scss'
export const ButtonWithIcon:React.FC<IPropsButtonTextIcon>=React.memo(({icon ,text,state,onClick})=>{


    return(
        <IonButton onClick={onClick&&onClick} color="none" expand="full" className={"button-icon-text"+(state?" active":" defualt")} fill="clear">
<IonIcon icon={icon}></IonIcon>
{" "+text}

        </IonButton>
    )
})