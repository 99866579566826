import {
    IonCard,
    IonSlide,
    IonSlides,
    IonText,
    useIonViewWillEnter,
} from '@ionic/react';

import React, { createRef, useLayoutEffect } from 'react';
import { IPropsListCategory } from './props.list-category';
import './style.scss';
export const ListCategory: React.FC<IPropsListCategory> = ({
    category,
    onClick,
}) => {
    const refSlides = createRef<HTMLIonSlidesElement>();
    useLayoutEffect(() => {
        if (refSlides.current) {
            setTimeout(() => {
                refSlides?.current?.update().then(() => {});
            }, 200);
        }
    }, []);
    useIonViewWillEnter(() => {
        if (refSlides.current) {
            setTimeout(() => {
                refSlides?.current?.update().then(() => {});
            }, 200);
        }
    });

    return category && category?.length > 0 ? (
        <div id="slide">
            <IonSlides
                ref={refSlides}
                options={{
                    slidesPerView: 5,
                    spaceBetween: 0,
                    freeMode: true,
                }}
                className="swiper-slide "
            >
                {category &&
                    category.map((data: any, index) => {
                        return (
                            <IonSlide key={index}>
                                <div className="sli">
                                    <div className="circal">
                                        <IonCard
                                            routerLink={
                                                '/page/filter-products/' +
                                                data.slug
                                            }
                                            onClick={() =>
                                                onClick && onClick(data)
                                            }
                                            button
                                            className="circal-card"
                                        >
                                            <img
                                                className="icon-slide"
                                                src={data?.logo?.path}
                                            ></img>
                                        </IonCard>
                                    </div>{' '}
                                    <p className="text">{data.name}</p>{' '}
                                </div>
                            </IonSlide>
                        );
                    })}
            </IonSlides>
        </div>
    ) : (
        <></>
    );
};
