import { IonLabel } from '@ionic/react';
import React from 'react';
import { CURRENCY_LI } from '../../../../helpers/currency';
import { IPropsHeaderProduct } from './props.header-product';
import './style.scss';
export const HeaderProduct: React.FC<IPropsHeaderProduct> = React.memo(
    ({ title, is_discount, price, discount_price, category, lang }) => {
        return (
            <IonLabel className="labal-header-product ">
                <h2 className="header">{title}</h2>
                <p className="margin-top">
                    {lang === 'ar' ? (
                        <>
                            {is_discount == 1 ? (
                                <span className="discount">{price}</span>
                            ) : (
                                ''
                            )}
                            <span className="price">
                                {is_discount == 0 ? price : discount_price}
                            </span>
                        </>
                    ) : (
                        <>
                            {' '}
                            <span className="price">
                                {' '}
                                {is_discount == 0 ? price : discount_price}
                            </span>
                            {is_discount == 1 ? (
                                <span className="discount">{price}</span>
                            ) : (
                                ''
                            )}
                        </>
                    )}
                    <span
                        className={lang === 'ar' ? 'category-ar' : 'category'}
                    >
                        {category}
                    </span>
                </p>
            </IonLabel>
        );
    }
);
