import { IonCard, IonImg, IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CURRENCY_LI } from '../../../../../../helpers/currency';
import { getLangTag, GET_IMAGE_BY_SIZE } from '../../../../../../modules/global-data';
import { IPropsCardItem } from './props.card-item';
import './style.scss';
const CardItem: React.FC<IPropsCardItem> = ({
    name,
    img,
    price,
    is_dis,
    price_discount,
    category,
    is_wishlist,
    type,
    slug,
    lang,
    onClick,
}) => {
    const { t } = useTranslation('common');

    return (
        <IonCard
            onClick={() => onClick && onClick(slug)}
            routerLink={onClick ? undefined : `/page/product/${slug}`}
            button
            className="card-item"
        >
            <IonImg className="img-grid img-card-item" src={GET_IMAGE_BY_SIZE(img,"small")}></IonImg>
            {is_dis == 1 ? (
                <div
                    className={
                        lang === 'en' ? 'discount-badge' : 'discount-badge-ar'
                    }
                >
                    {' '}
                    {Math.abs(Math.round((price_discount / price - 1) * 100))}%
                    {t('OFF')}
                </div>
            ) : (
                ''
            )}

            <IonItem className="item" lines="none" color="none">
                <IonLabel>
                    <h2 className="title" style={{ paddingBottom: '3px' }}>
                        {name}
                    </h2>
                    {type == 'sm' && <p className="category">{category}</p>}
                    {type == 'sm' ? (
                        <p className="price-base">
                            <span className="base">
                                {CURRENCY_LI}
                                {price_discount}
                            </span>{' '}
                            {is_dis ? (
                                <span
                                    className="discount"
                                    style={{ paddingRight: '4px' }}
                                >
                                    {CURRENCY_LI}
                                    <del>{price}.00</del>
                                </span>
                            ) : (
                                ''
                            )}
                        </p>
                    ) : (
                        <p className="price-base">
                            <span className="base">
                                {CURRENCY_LI}
                                {price_discount}.00
                            </span>{' '}
                            {is_dis ? (
                                <span className="discount">
                                    <del>
                                        {' '}
                                        {CURRENCY_LI}
                                        {price}.00
                                    </del>
                                </span>
                            ) : (
                                ''
                            )}
                            <span
                                className="category-lg"
                                style={{
                                    float: lang === 'ar' ? 'left' : 'right',
                                }}
                            >
                                {category}
                            </span>
                        </p>
                    )}
                </IonLabel>
            </IonItem>
        </IonCard>
    );
};
export default React.memo(CardItem);
