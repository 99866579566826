export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST";
export const DELETE_ALL_FROM_WISHLIST = "DELETE_ALL_FROM_WISHLIST";
export const LOAD_WISHLIST_PRDOCUT='LOAD_WISHLIST_PRDOCUT'
// add to wishlist
// export const loadWishlistProduct
export const loadWihlistProduct = (item:any) =>{
  
  return ({ type: LOAD_WISHLIST_PRDOCUT, payload: item });

};

export const addToWishlistDispatch = (item:any) => (dispatch: React.Dispatch<any>)=>{
  
  return dispatch({ type: ADD_TO_WISHLIST, payload: item });

};

// delete from wishlist
export const deleteFromWishlistDispatch = (item:any) =>(dispatch: React.Dispatch<any>)=> {
  return  dispatch({ type: DELETE_FROM_WISHLIST, payload: item });

};

//delete all from wishlist
export const deleteAllFromWishlistDispatch = () =>(dispatch: React.Dispatch<any>)=> {
  return   dispatch({ type: DELETE_ALL_FROM_WISHLIST });
 
};
