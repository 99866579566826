import { IonItemDivider, IonText } from "@ionic/react"
import { useRef } from "react"
import { CURRENCY_LI } from "../../../../helpers/currency"
import { IPropstemDetails } from "./props.item-details"
import './style.scss'
export const ItemDetails:React.FC<IPropstemDetails>=({name,value,id})=>{


    return(  <IonItemDivider mode="ios" className="divider">
        <IonText color={id==4?"lightness":""} className="title" slot="start">{name}</IonText>
        <IonText color={id==4?"lightness":""} className="value" slot="end">{CURRENCY_LI} {value}</IonText>
      </IonItemDivider>)
}