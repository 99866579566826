import React from "react";

export const ADD_TO_CART = "ADD_TO_CART";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY"
export const INCREASE_QUANTITY='INCREASE_QUANTITY'
export const LOAD_DATA_CART="LOAD_DATA_CART"
export const LoadDataCart=(item:any)=>{

  return  ({ type: LOAD_DATA_CART, payload: item });

}

export const addToCartDispatch = (
    item:any,
    quantityCount:any,
    selectedProductColor:any
  ) => (dispatch: React.Dispatch<any>)=>{

   
    dispatch  ({
        type: ADD_TO_CART,
        payload: {
          ...item,
          quantity: quantityCount,
          selectedProductColor: selectedProductColor
            ? selectedProductColor
            : item.selectedProductColor
            ? item.selectedProductColor
            : null
        }
      });
    
  };
export const increaseQuantity = (item:any) =>(dispatch: React.Dispatch<any>)=>{

  dispatch  ({ type: INCREASE_QUANTITY, payload: item });
    
  }
export const decreaseQuantity = (item:any) => (dispatch: React.Dispatch<any>)=> {
 
  dispatch  ({ type: DECREASE_QUANTITY, payload: item });
  }
  export const deleteFromCartDispatch = (item:any) => (dispatch: React.Dispatch<any>)=>{
    deleteFromCartDispatch  ({ type: DELETE_FROM_CART, payload: item });
  };
  export const deleteAllFromCartDispatch = () =>(dispatch: React.Dispatch<any>)=>{

    dispatch  ({ type: DELETE_ALL_FROM_CART });
  
  };