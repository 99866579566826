import { IonImg, IonItem, IonLabel, IonText, IonThumbnail } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CURRENCY_LI } from '../../../../../../../helpers/currency';
import { IPropsItemSerch } from './props.item-search';
import './style.scss';
export const ItemSearch: React.FC<IPropsItemSerch> = ({
    img,
    name,
    price,
    isDiscount,
    discount_price,
    category,
    slug,
    routerLink,
}) => {
    const { t } = useTranslation("common");

    return (
        <IonItem
            onClick={routerLink}
            button
            className="item-search"
            lines="none"
            color="none"
        >
            <IonThumbnail className="thumbnail" slot="start">
                <IonImg src={img} className=" img " />
            </IonThumbnail>
            <IonLabel>
                <h1>{name}</h1>
                <p className="category">{category}</p>
                <p className="price">
                    {CURRENCY_LI}
                    {isDiscount ? discount_price : price}
                    {isDiscount ? (
                        <span>
                            &nbsp; &nbsp;
                            <del>
                                {' '}
                                {CURRENCY_LI}
                                {price}
                            </del>
                        </span>
                    ) : (
                        ''
                    )}
                </p>
            </IonLabel>
            {isDiscount ? (
                <div slot="end" className="discount-area">
                    <IonText className="text">
                        {' '}
                        {Math.abs(
                            Math.round((discount_price / price - 1) * 100)
                        )}
                        % { t("OFF__")}
                    </IonText>
                </div>
            ) : (
                ''
            )}
        </IonItem>
    );
};
