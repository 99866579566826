import { IonIcon } from '@ionic/react';
import { checkmarkCircleSharp } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import { getGlobalHeaders } from '../../../../../../modules/global-data';
import { IPropsStepProgess } from './props.step-progerss';

export const StepProgess: React.FC<IPropsStepProgess> = ({ state }) => {
    const onStatebackground = (state: number) => {
        switch (state) {
            case -1:
                return 'red';
            case 0:
                return '#6680C6';
            case 1:
                return '#F69400';
            case 2:
                return '#FED700';
            case 3:
                return '#4CD100';
            default:
                return '#6680C6';
        }
    };
    const onStateheight = (state: number) => {
        switch (state) {
            case -1:
                return '95px';
            case 0:
                return '95px';
            case 1:
                return '198px';
            case 2:
                return '297px';
            case 3:
                return '385px';
            default:
                return '99px';
        }
    };
    const lang = getGlobalHeaders().lang_tag??"ar"

    return (
        <div
            className="step -start"
            style={{
                height: onStateheight(state),
                background: onStatebackground(state),
            }}
        >
            <IonIcon
                style={{
                    fontSize: '35px',
                    position: 'relative',
                    top: '15px',
                    left: lang === 'ar' ? '-2px' : '2px',
                }}
                color="light"
                icon={checkmarkCircleSharp}
            ></IonIcon>

            <IonIcon
                style={{
                    fontSize: '35px',
                    position: 'relative',
                    top: '64px',
                    left: lang === 'ar' ? '-2px' : '2px',
                    color: state <= 3 && state > 0 ? '#F9F9F9 ' : '#D0D0D0',
                }}
                icon={checkmarkCircleSharp}
            ></IonIcon>
            <IonIcon
                style={{
                    fontSize: '35px',
                    position: 'relative',
                    top: '128px',
                    left: lang === 'ar' ? '-2px' : '2px',
                    color: state <= 3 && state > 1 ? '#F9F9F9 ' : '#D0D0D0',
                }}
                icon={checkmarkCircleSharp}
            ></IonIcon>
            <IonIcon
                style={{
                    fontSize: '35px',
                    position: 'relative',
                    top: '190px',
                    left: lang === 'ar' ? '-2px' : '2px',
                    color: state <= 3 && state > 2 ? '#F9F9F9 ' : '#D0D0D0',
                }}
                /*  color="light" */ icon={checkmarkCircleSharp}
            ></IonIcon>
        </div>
    );
};
