import './style.scss';
import React, { useRef, useState } from 'react';
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonIcon,
    IonItem,
} from '@ionic/react';
import { HeaderCard } from './model/header-card';
import {
    chatbubbleEllipsesOutline,
    heartOutline,
    shareSocialOutline,
} from 'ionicons/icons';
import { ButtonWithIcon } from '../../../../../components/button-text-icon';
import { IPropsCardPost } from './props.card-post';
import moment from 'moment';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import handleViewport from 'react-in-viewport';
import { ReactPlayerBlock } from './ReactPlayerBlock';
import { Video } from '../../../../../components/video-player/video';
import { SliderImage } from '../../../../../components/slider-img';

const CardPost: React.FC<IPropsCardPost> = ({
    comments_count,
    created_at,
    files,
    post_media,
    title,
    onShowComment,
    onShowImage,
    setCurrentVideo,
    currentVideo,
}) => {
    const videoRef = useRef<any>();
    const { t } = useTranslation('common');
    const onVideoStateChange = (state: boolean) => {
        if (state) {
            if (currentVideo?.current && currentVideo != videoRef) {
                currentVideo.current.pause();
            }
            setCurrentVideo(videoRef);
        } else {
        }
    };

    return (
        <IonCard color="none" className="card-post">
            <HeaderCard
                name={t('Turkish_WoW')}
                subTitle={title}
                time={moment(created_at).format('hh:mm A')}
                img={process.env.PUBLIC_URL + '/assets/svg/logo-sm.svg'}
            ></HeaderCard>
            <IonCardContent className="card-content">
                {post_media?.mime?.includes('video') ? (
                    <div>
                        <video
                            hidden={true}
                            muted={true}
                            playsInline
                            preload="auto"
                            width="100%"
                        >
                            <source src={post_media.path} type="video/mp4" />
                        </video>
                        <ReactPlayerBlock
                            onEnterViewport={() => {
                                //videoRef.current.play()
                            }}
                            onLeaveViewport={() => {
                                videoRef.current.pause();
                            }}
                        >
                            <Video
                                onStateChange={onVideoStateChange}
                                src={post_media.path}
                                ref={videoRef}
                            >
                                {(video, state, actions) => (
                                    <div>
                                        {video}
                                        <progress
                                            value={state.currentTime}
                                            max={state.duration}
                                            style={{
                                                width: '100%',
                                                position: 'relative',
                                                top: '-2px',
                                            }}
                                        />
                                    </div>
                                )}
                            </Video>
                        </ReactPlayerBlock>
                    </div>
                ) : (
                    <SliderImage files={files} onShowImage={onShowImage} />
                )}
            </IonCardContent>
            <IonItem color="none" className="item-footer" lines="none">
                {/*       <ButtonWithIcon icon={heartOutline} text="1" state={true} /> */}
                <ButtonWithIcon
                    onClick={onShowComment}
                    icon={chatbubbleEllipsesOutline}
                    text={comments_count.toString()}
                    state={false}
                />
                {/*         <ButtonWithIcon icon={shareSocialOutline} text="0" state={false} /> */}
            </IonItem>
        </IonCard>
    );
};
export default React.memo(CardPost);
