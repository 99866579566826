import React, { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';

export const ReactPlayerBlock = (props) => {
  const myRef = useRef();
  const options = {

  }
  let config = null;
  const {
    inViewport,
    enterCount,
    leaveCount,
  } = useInViewport(
    myRef,
    options,
    config = { disconnectOnLeave: false },
    props
  );

  return (
    <section ref={myRef}>
      {props.children}
    </section>
  );
};
