import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import { InfiniteScrollProps } from './props';

export const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
    disableInfiniteScroll,
    searchNext,
}) => {
    return (
        <IonInfiniteScroll
            threshold="100px"
            disabled={disableInfiniteScroll}
            onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}
        >
            <IonInfiniteScrollContent loadingText="Loading more Products..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
    );
};
