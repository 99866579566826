import {
    IonCol,
    IonItem,
    IonLabel,
    IonRouterLink,
    IonRow,
    IonThumbnail,
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CURRENCY_LI } from '../../../../helpers/currency';
import { StateOrder } from '../../../../hooks/state-order';
import { GridIamge } from './model/grid-image';
import { IPropsOrderItem } from './props.order-item';
import './style.scss';
const stateOrderr = (state: string, t: any) => {
    console.log(state);
    switch (state) {
        case 'created':
            return {
                name: t('Order_Received'),
                color: '#6680C6',
            } as any;
        case "confirm":
        case "ready":
            return {
                name: t('Preparing'),
                color: '#F69400',
            };
        case 'shipped':
            return {
                name: t('On_The_Way'),
                color: '#FED700',
            };
        case 'closed':
            return {
                name: t('Closed'),
                color: '#D10000',
            };
        case 'delivered':
            return {
                name: t('Completed'),
                color: '#4CD100',
            };
        case "returned":
            return {
                name: t('Returned'),
                color: "#4CD100",
            };
        default:
            return {
                name: t('pending'),
                color: '#6680C6',
            };
    }
};
export const OrderItem: React.FC<IPropsOrderItem> = ({
    name,
    price,
    status,
    id,
    lang,
    images,
}) => {
    const { t } = useTranslation('common');
    const img = [
        'https://d15sjtfh6z5ui1.cloudfront.net/Items/GS212552/RUST/5f7303a63a05e.jpg',
        'https://d15sjtfh6z5ui1.cloudfront.net/Items/GS212552/RUST/5f7303a63a05e.jpg',
        'https://d15sjtfh6z5ui1.cloudfront.net/Items/GS212552/RUST/5f7303a63a05e.jpg',
    ];

    /*   const data: any = StateOrder(status); */

    return (
        <IonItem lines="none" className="item-order" color="none">
            <IonThumbnail slot="start" className="thumbnail">
                <GridIamge images={images}></GridIamge>
            </IonThumbnail>
            <IonLabel>
                <h2 className="title">{name}</h2>
                <p className="price">
                    {CURRENCY_LI}
                    {price}
                </p>
                <p className="state-opetion">
                    <span
                        style={{ color: stateOrderr(status, t).color }}
                        className="state-order"
                    >
                        {stateOrderr(status, t).name}
                    </span>
                    <IonRouterLink
                        routerLink={`/page/my-orders/${id}`}
                        className="link-view"
                        style={{
                            float: lang && lang === 'ar' ? 'left' : 'right',
                        }}
                    >
                        {t('View_Details')}
                    </IonRouterLink>
                </p>
            </IonLabel>
        </IonItem>
    );
};
