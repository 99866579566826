import { IonSlides } from "@ionic/react";
import React, { createRef, useEffect, useLayoutEffect } from "react";
import './style.slider-started.scss'
import { Slide } from "./slide/slide";
interface IContentSlider {
  title: string;
  content: string;
}
const SliderStarted: React.FC = () => {
  var dataSlider = [
    {
      title: "Fashion.. Elegance",
      content:
        "Online store to shop all that is new in the world of elegance and fashion is the Turkish WoW policy",
    },
    {
      title: "Exceed your style goals here",
      content:
        "Shop Now 🛒Save up to 50% Off",
    },
    {
      title: "Turkish WoW",
      content:
        "Don't stop go ahead",
    },
  ];
const refSlides=createRef<HTMLIonSlidesElement>()

useLayoutEffect(()=>{
if(refSlides.current){

setTimeout(()=>{refSlides?.current?.update().then(()=>{

})},400)
}
},[])
  return (
    <IonSlides ref={refSlides} className="ion-slides-start" mode="ios" pager={true}>
        {dataSlider.map((data:IContentSlider,index:number)=>{
            return    <Slide key={index} title={data.title} content={data.content} />
        })}
   
    </IonSlides>
  );
};
export default React.memo(SliderStarted);
