import axios from 'axios';
import { axiosApi } from '.';

interface UpdateProfileInfo {
    email?: string;
    phone?: string;
    first_name: string;
    last_name: string;
}
interface BodyAddAddress {
    customer_id: any;
    city_id: any;
    state_id: any;
    country_id: any;
    first_name: string;
    last_name: string;
    address_1: string;
    address_2?: string;
    city: string;
    state: string;
    zip: string;
    country: string;

    state_name: string;
    country_name: string;
}
const getProfileInfo = () => {
    return axiosApi.get('/account/profile');
};
const updatePassword = (body: { password: string }) => {
    return axiosApi.put('/account/password', body);
};
const getOrder = (id: string) => {
    return axiosApi.get(`/account/orders/${id}`);
};
const getOrders = () => {
    return axiosApi.get('/account/orders');
};

const getReviews = () => {
    return axiosApi.get('/account/reviews');
};
const updateProfileInfo = (body: UpdateProfileInfo) => {
    return axiosApi.put('/account/profile', body);
};
const getAddress = () => {
    return axiosApi.get('/account/addresses');
};
const addAddress = (body: BodyAddAddress) => {
    return axiosApi.post('/account/addresses', body);
};
const changeDefaultAddress = (body: { address_id: string }) => {
    return axiosApi.post('/account/addresses/change-default-address', body);
};
const deleteAddress = (id: string) => {
    return axiosApi.delete(`/account/addresses/${id}`);
};
const updateAddress = (id: string) => {
    return axiosApi.put(`/account/addresses/${id}`);
};
const getStats = () => {
    return axiosApi.get('/account/stats');
};
const uploadProfilePicutre = (body: any) => {
    return axiosApi.post('/account/profile_picture', body);
};
const setPushToken = (tokenValue: any) => {
    return axiosApi.post('/device/token/store', { token: tokenValue });
};
const accountApi = {
    getProfileInfo,
    getOrder,
    getOrders,
    getReviews,
    updateProfileInfo,
    getAddress,
    changeDefaultAddress,
    addAddress,
    deleteAddress,
    updateAddress,
    updatePassword,
    getStats,
    setPushToken,
    uploadProfilePicutre,
};
export default accountApi;
