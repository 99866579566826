import { IonCol, IonRow } from "@ionic/react"
import { useTranslation } from "react-i18next";
import ItemInput from "../../../components/item-input"

export const FormAddPaymentMethods:React.FC=()=>{
    const { t } = useTranslation("common");
    return(
        <form>
        <ItemInput lines="none" lable={t("Cardholder_name")} />
        <ItemInput isIcon={true} icon={process.env.PUBLIC_URL+"/assets/svg/visa.svg"} type="number" lines="none" lable={t("Card_number")} />
<IonRow>
    <IonCol className="ion-no-padding ion-padding-end" size="6">
    <ItemInput lines="none" lable={t("Expiry_date")} />

    </IonCol>
    <IonCol className="ion-no-padding" size="6">    <ItemInput lines="none" lable={t("CVV")} /></IonCol>
</IonRow>
        </form>
    )
}