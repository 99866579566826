import { Dialog } from '@capacitor/dialog';
import {
    IonContent,
    IonPage,
    IonRadioGroup,
    useIonLoading,
    useIonToast,
    useIonViewDidEnter,
    useIonViewDidLeave,
    useIonViewWillEnter,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/button';
import { ItemRadio } from '../../components/item-radio';
import { Refresher } from '../../components/refresher';
import { Toast } from '../../components/toast';
import { FooterPage } from '../../layout/footer-page';
import { HeaderSubPage } from '../../layout/Header/header-sub-page';
import { LoadingCenter } from '../../layout/loading-center';
import apis from '../../services';
const ShippingAddresses: React.FC = () => {
    const { t } = useTranslation('common');
    const [addresses, setAddresses] = useState<any>({
        data: [],
        loading: true,
        errorMessage: null,
    });
    const [addressId, setAddressId] = useState<number>(1);
    const [present, dismiss] = useIonLoading();
    const [presentToast] = useIonToast();

    useIonViewDidEnter(() => {
        Refresh();
      });
    

    const onChangeDefualtAddress = (id: number) => {
        apis.changeDefaultAddress({
            address_id: id?.toString(),
        });
        setAddressId(id);
    };
    const Refresh = async () => {
        await apis.getAddress().then((respone) => {
            setAddresses({
                data: respone.data,
                loading: false,
                errorMessage: null,
            });
            setAddressId(respone.data.defaultAddress?.address_id);
            if(!respone.data.defaultAddress?.address_id && respone.data?.addresses){
                const arr = Object.values(respone.data.addresses);
                if(arr.length > 0){
                    const firstAdd = arr[0] as any;
                    onChangeDefualtAddress(firstAdd?.id)
                }
               
            }
        });
    };

    const onHandelDelete = async (id: string) => {
        const { value } = await Dialog.confirm({
            title: t('Confirm'),
            message: t('ALERT_MESSAGE_DELETE_CONFIRM'),
        });

        if (value) {
            present({
                message: 'Loading...',
                duration: 4000,
            });
            apis.deleteAddress(id)
                .then(() => {
                    Toast(presentToast, t('DELETE_SUCSSESSFULLY'), 1500);
                    Refresh();
                })
                .catch(() => {
                    Toast(presentToast, t('Failed_To_Save'), 1500, 'error');
                })
                .finally(() => {
                    dismiss();
                });
        }
    };
    return (
        <IonPage>
            <HeaderSubPage
                title={t('Shipping_Addresses')}
                defaultHref="/page/settings"
            />
            <IonContent className="content-color">
                <Refresher Refresh={Refresh}></Refresher>
                {addresses.loading == false && addresses.data ? (
                    <IonRadioGroup
                        onIonChange={(e) =>
                            onChangeDefualtAddress(e.detail.value)
                        }
                        value={addressId}
                    >
                        {Object.values(addresses.data.addresses).map(
                            (data: any, index: number) => {
                                return (
                                    <ItemRadio
                                        onClickDelete={() =>
                                            onHandelDelete(data.id)
                                        }
                                        key={index}
                                        isDelete={true}
                                        descrption={data.city}
                                        name={data.address_1}
                                        value={data.id}
                                    />
                                );
                            }
                        )}
                        {/*     <ItemRadio isChange={true} descrption="Work address" name="Piata Unirii 2, Apartment 23…" value="2" /> */}
                    </IonRadioGroup>
                ) : (
                    <LoadingCenter />
                )}
            </IonContent>
            {addresses.loading == false ? (
                <FooterPage>
                    <Button
                        text={t('Add_New_Address')}
                        routerLink="/page/settings/shipping-addresses/add-new-address"
                        height={35}
                        expand="full"
                        color="none"
                    ></Button>
                </FooterPage>
            ) : (
                ''
            )}
        </IonPage>
    );
};
export default React.memo(ShippingAddresses);
