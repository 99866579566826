import React from 'react';
import { IPropsTickerBar } from './props.ticker-bar';
import './style.scss';
const TickerBar: React.FC<IPropsTickerBar> = ({ listNews }) => {
    return (
        <div className="tirker-bar">
            <div className="ticker-wrap">
                <div className="ticker">
                    {listNews.map((data, index) => {
                        return <div className="ticker__item">{data.title}</div>;
                    })}
                </div>
            </div>
        </div>
    );
};

export default React.memo(TickerBar);
