export const ADD_TO_STATS = 'ADD_TO_STATS';
export const ADD_TO_STATS_WISHLIST = 'ADD_TO_STATS_WISHLIST';
export const DECREASE_WISHLIST_STATS = 'DECREASE_WISHLIST_STATS';
export const DELETE_ALL_STATS = 'DELETE_ALL_STATS';
export const INCREASE_TO_STATS_CART = 'INCREASE_TO_STATS_CART';
export const DECREASE_TO_STATS_CART = 'DECREASE_TO_STATS_CART';
export const DECREASE_WISHLIST_STATS_PAGE = 'DECREASE_WISHLIST_STATS_PAGE';
export const DELETE_TO_STATS_CART = 'DELETE_TO_STATS_CART';
export const LOAD_STATE_NOTIFICATION='LOAD_STATE_NOTIFICATION'
interface Item {
    wishlist_count: number;
    cart_count: number;
}
export const loadStateNotification=(item:any)=>{
   
    return ({ type: LOAD_STATE_NOTIFICATION, payload: item })

}
export const addToStatsDispatch =
    (item: Item) => (dispatch: React.Dispatch<any>) => {

        dispatch ({ type: ADD_TO_STATS, payload: item })
    };
export const increaseToStatsWishlist =
    (number:number) => (dispatch: React.Dispatch<any>) => {
 
    
         dispatch({ type: ADD_TO_STATS_WISHLIST ,payload:number});
    };
export const decreaseToStatsWishlist =
    () => {

         return({ type: DECREASE_WISHLIST_STATS });
    };
export const increaseToStatsCart = () => (dispatch: React.Dispatch<any>) => {

    dispatch ({ type: INCREASE_TO_STATS_CART });
};
export const decreaseToStatsWhishlistPage = () =>(dispatch: React.Dispatch<any>)=> {

    dispatch ({ type: DECREASE_WISHLIST_STATS_PAGE });
};
export const decreaseToStatsCart = () => (dispatch: React.Dispatch<any>) => {
     dispatch({ type: DECREASE_TO_STATS_CART });
};
export const deleteAllStats = () => (dispatch: React.Dispatch<any>) => {
     dispatch({ type: DELETE_ALL_STATS });
};
export const deleteToStatsCart = () => (dispatch: React.Dispatch<any>) => {
     dispatch({ type: DELETE_TO_STATS_CART });
};
