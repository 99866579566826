import axios from 'axios';
import { axiosApi } from '.';
interface BodyLogin {
    login_id: string;
    password: string;
    remember_me: boolean | true;
}
interface BodyRegister {
    first_name: string;
    last_name: string;
    email?: string;
    phone: string;
    password: string;
    password_confirmation: string;
    privacy_policy: boolean | true;
}
interface BodyVerifyUserAccount {
    code: string;
    username: string;
}
const loginUser = (body: BodyLogin) => {
    return axiosApi.post('/auth/login', body);
};

const loginWith = (provider:string,accessToken: any,_idToken?:string,_serverAuthCode?:string,_phone = "") => {
    return axiosApi.post(`/auth/login/${provider}/callback`,{
        access_token :accessToken,
        idToken:_idToken,
        serverAuthCode:_serverAuthCode,
        phone:_phone
    });
};

const loginWithSocial = (
    provider:string,
    accessToken: any,
    firstName: string,
    lastName: string,
    userId: string,
    _idToken?:string,
    _serverAuthCode?:string,
    _phone = "") => {
    return axiosApi.post(`/auth/login/social/${provider}/callback`,{
        access_token :accessToken,
        idToken:_idToken,
        serverAuthCode:_serverAuthCode,
        fname:firstName,
        lname:lastName,
        id:userId,
        phone:_phone
    });
};

const logout = () => {
    return axiosApi.get('/auth/logout');
};

const CreateUser = (body: BodyRegister) => {
    return axiosApi.post('/auth/register', body);
};
const verifyUserAccount = (body: BodyVerifyUserAccount) => {
    return axiosApi.post(`/auth/verify`, body);
};
const requestVerifyUserAccount = (body: any) => {
    return axiosApi.post(`/auth/request-verify`, body);
};
const resetPassword = (email: string) => {
    return axiosApi.post(`/auth/password/reset`, { email: email });
};
const resetPasswordComplete = (email: string,code: string) => {
  return axiosApi.get(`/auth/password/reset/${email}/${code}`);
};
const resetPasswordComplete2 = (email: string,code: string,new_password: string) => {
  return axiosApi.post(`/auth/password/reset/${email}/${code}`,{new_password : new_password,new_password_confirmation : new_password});
};
const getUserInfo = () => {
    return axiosApi.get('auth/user');
};
const authApis = {
    loginUser,
    loginWith,
    loginWithSocial,
    logout,
    CreateUser,
    verifyUserAccount,
    requestVerifyUserAccount,
    resetPassword,
    resetPasswordComplete,
    resetPasswordComplete2,
    getUserInfo,
};
export default authApis;
