import { IonTextarea } from '@ionic/react'
import React from 'react'
import { IPropsInputTextArea } from './props.input-text-area'
import './style.scss'
export const InputTextArea:React.FC<IPropsInputTextArea>=React.memo(({placeholder,onChange})=>{



    return(  
        <div style={{paddingRight:"2px"}}>
    <textarea onChange={onChange} className="area-review input-area" rows={4} placeholder={placeholder&&placeholder} />
    </div>
  
  )
})