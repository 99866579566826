import { IonCol, IonContent, IonGrid, IonRow, IonText } from "@ionic/react";
import React, { useState } from "react";
import { HeaderModal } from "../../../../layout/Header/header-modal";
import { ReactSVG } from "react-svg";

import { InputTextArea } from "../../../../components/input-text-area";
import "./style.scss";
import { FooterPage } from "../../../../layout/footer-page";
import Button from "../../../../components/button";
import apis from "../../../../services";
import { useTranslation } from "react-i18next";
export const BodyCancelOrder: React.FC<{
  props: {
    id: number;
    status: string;
  };
  onDismiss: () => void;
  onIncrement: () => void;
}> = (props) => {
  const [cancelReason, setCancelReason] = useState("");
  const { t } = useTranslation("common");
  const handelCancelOrder = (id: number, status: string) => {
    apis.cancelCheckout(id, cancelReason).finally(() => {
      alert(id + "," + cancelReason);
      props.onDismiss();
    });
  };
  console.log(props);
  return (
    <>
      <HeaderModal onClose={props.onDismiss} title={t("Cancel_Order")} />

      <IonContent
        fullscreen
        className="content-color ion-padding content-cancel-modal"
      >
        <IonGrid>
          <IonRow className="ion-justify-content-center center-ju-screen">
            <IonCol /* size="8" */ className="ion-align-self-center">
              <ReactSVG
                wrapper="span"
                className="wrapper-class-name"
                evalScripts="always"
                beforeInjection={(svg) => {
                  svg.classList.add("svg-class-name");
                  svg.setAttribute("style", "width: 200px");
                }}
                src={process.env.PUBLIC_URL + "/assets/svg/what-wrong.svg"}
              />
              <div className="title">
                <IonText className="text">
                  {t("Are_you_sure_you_want_to_cancel_the_order")}
                </IonText>
              </div>
              <div className="subtitle">
                <IonText className="text">
                  {t("Please_note_that_you_cannot_undo_this_action")}
                </IonText>
              </div>
              <InputTextArea placeholder={t("Reason_for_cancellation")}
              onChange={(value: any) =>
                setCancelReason(value.target.value)
            }
               />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <FooterPage>
        <IonRow>
          <IonCol>
            <Button
              onClick={() =>
                handelCancelOrder(props.props.id, props.props.status)
              }
              expand="full"
              text={t("Confirm")}
              color="none"
            />
          </IonCol>
          <IonCol>
            <Button
              onClick={() => props.onDismiss()}
              fontColor="#EB4381"
              border="0.8462259769439697px solid #EE417F"
              background="linear-gradient(90deg, rgba(238,65,127,0.19) 0%, rgba(238,65,127,0.19) 25%, rgba(238,65,127,0.19091386554621848) 100%)"
              fill="clear"
              expand="full"
              text={t("Cancel")}
              color="none"
            />
          </IonCol>
        </IonRow>
      </FooterPage>
    </>
  );
};
