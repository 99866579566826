import { setWishlistData } from '../../storage/management-Storage';
import {
    ADD_TO_WISHLIST,
    DELETE_ALL_FROM_WISHLIST,
    DELETE_FROM_WISHLIST,
    LOAD_WISHLIST_PRDOCUT,
} from './favorite.actions';

const initState: any = [];

const wishlistReducer = (state = initState, action: any) => {
    const wishlistItems = state,
        product = action.payload;
        if(action.type===LOAD_WISHLIST_PRDOCUT){
state=action.payload
return state
        }
    if (action.type === ADD_TO_WISHLIST) {
    
        const wishlistItem = wishlistItems.filter(
            (item: any) => item.id === product.id
        )[0];
        if (wishlistItem === undefined) {
            setWishlistData([...wishlistItems, product])
            return [...wishlistItems, product];
        } else {
            setWishlistData(wishlistItems)
            return wishlistItems;
        }
     
    }

    if (action.type === DELETE_FROM_WISHLIST) {
        const remainingItems = (wishlistItems: any, product: any) =>
            wishlistItems.filter(
                (wishlistItem: any) => wishlistItem.id !== product.id
            );
            setWishlistData(remainingItems(wishlistItems, product))
        return remainingItems(wishlistItems, product);
    }

    if (action.type === DELETE_ALL_FROM_WISHLIST) {
        setWishlistData(wishlistItems.filter((item: any) => {
            return false;
        }))
        return wishlistItems.filter((item: any) => {
            return false;
        });
       
    }

    return wishlistItems;
};

export default wishlistReducer;
