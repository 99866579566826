import ReactStars from "react-rating-stars-component";

export const RatingStarts=()=>{


    const ratingChanged = (newRating) => {
        console.log(newRating);
      };
    return(
        <ReactStars
        count={5}
      
       /*  isHalf={true} */
        onChange={ratingChanged}
        size={50}
        activeColor="#4CD100"
      />
    )
};