import { IonButton, IonSpinner } from "@ionic/react";
import React from "react";
import { IButton } from "./props.button";
import "./style.scss";
const Button: React.FC<IButton> = ({
  color,
  text,
  fill,
  expand,
  routerLink,
  height,
  background,
  border,
  fontColor,
  loading,
  disabled,
  width,
  hidden,
  href,
  onClick
}) => {
  return (
    <IonButton
    hidden={hidden}
    href={href && href}
    onClick={onClick&&onClick}
      routerLink={routerLink && routerLink}
      style={{
        height: height && height,
        background: background && background,
        border: border && border,
        color: fontColor && fontColor,
        width:width
      }}
      disabled={disabled}
      expand={expand && expand}
      className={`button-kit ${fill ? fill : "fill"}`}
      color={color && color}
    >
      {loading ? <IonSpinner></IonSpinner> : text}
    </IonButton>
  );
};
export default React.memo(Button);
