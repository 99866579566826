import { IonApp, IonContent, IonFooter, IonModal, IonRouterOutlet, IonSpinner, IonText, useIonModal } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
import { Redirect, Route } from 'react-router';
import React, { useEffect, useRef, useState } from 'react';
import {
    loadUserData,
    setIsLoggedIn,
    setUserProfile,
} from './redux/user/user.actions';
import { getGlobalHeaders, resetGlobalHeaders } from './modules/global-data';
import GetStartedPage from './pages/getstarted-page';
import Login from './pages/login-page/login';
import SingnUp from './pages/sign-up-page/singn-up';
import TabDemo from './routes/tabs/tab-demo';
import Product from './pages/product/product';
import MyOrders from './pages/my-orders/my-orders';
import OrderInfo from './pages/order-info/order-info';
import Settings from './pages/settings/settings';
import ChangeLanguage from './pages/change-language/change-language';
import ChangePassword from './pages/change-password/change-password';
import ShippingAddresses from './pages/shipping-addresses/shipping-addresses';
import AddNewAddress from './pages/add-new-address/add-new-address';
import CheckoutPage from './pages/checkout-page/checkout-page';
import MyReviews from './pages/my-reviews';
import OrderPlaced from './pages/order-placed';
import PaymentMethods from './pages/payment-methods';
import AddPaymentMethods from './pages/add-payment-methods';
import VerificationPage from './pages/verification/verification-page';
import ForgotPassword from './pages/forgot-password/forgot-password';
import { NonAuthRoute } from './routes/route-nonauth';
import apis, { axiosApi } from './services';
import history from './helpers/history';
import {
    setNotificationsSections,
    setStorageOnlyData,
} from './storage/management-Storage';
import { AuthRoute } from './routes/route-auth';
import AllProduct from './pages/all-product';
import {
    addToStatsDispatch,
    deleteAllStats,
} from './redux/stats/stats.actions';
import EditProfile from './pages/edit-profile';
import FilterProducts from './pages/filter-products';
import verificationForgotPage from './pages/verification-forgot/verification-forgot-page';
import changePasswordReset from './pages/change-password-reset/change-password-reset';

import { useTranslation } from 'react-i18next';
import {
    ActionPerformed,
    PushNotificationSchema,
    PushNotifications,
    Token,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import completeSingnUp from './pages/sign-up-page/complete-singn-up';
import { connect } from './redux/connect';
import { AppContextProvider } from './redux/AppContext';
import { addToValueFilterDispatch } from './redux/filter/filter.actions';
import AppUrlListener from './routes/AppUrlListener';
import RedirectToPage from './helpers/util/RedirectToPage';
import RedirectToLogin from './helpers/util/Logout';
import { deleteAllFromCartDispatch } from './redux/cart/cart.actions';
import { deleteAllFromWishlistDispatch } from './redux/favorite/favorite.actions';
import { DownloadApp } from './components/download-app';

interface DispatchProps {
    loadUserData: typeof loadUserData;
    setUserProfile: typeof setUserProfile;
    setIsLoggedIn: typeof setIsLoggedIn;
    addToStatsDispatch: typeof addToStatsDispatch;
    addToValueFilterDispatch: typeof addToValueFilterDispatch;
    deleteAllStats: typeof deleteAllStats;
    deleteAllFromCartDispatch: typeof deleteAllFromCartDispatch;
    deleteAllFromWishlistDispatch: typeof deleteAllFromWishlistDispatch;
}

const App: React.FC = () => {
    return (
        <AppContextProvider>
            <IonicAppConnected />
        </AppContextProvider>
    );
};
const IonicApp: React.FC<any> = ({
    settings,
    isAuthenticated,
    localLogin,
    loadUserData,
    setUserProfile,
    addToStatsDispatch,
    addToValueFilterDispatch,
    loading,
    setIsLoggedIn,
    deleteAllStats,
    deleteAllFromCartDispatch,
    deleteAllFromWishlistDispatch,
}) => {
    const [downloadAppShow, SetDownloadAppShow] = useState(!Capacitor.isNativePlatform());
    
    const { i18n } = useTranslation('common');
    const AuthCheck = async () => {
        await apis
            .getProfileInfo()
            .then((response) => {
                setUserProfile({
                    main_phone: response.data.account.phone,
                    email: response.data.account.email,
                    fullName:
                        response.data.account.first_name +
                        ' ' +
                        response.data.account.last_name,
                    avatar: response.data.account.picture?.path,
                    localLogin: true,
                    user_id: response.data.account.id,
                    reviews_count: response.data.reviews_count,
                    addresses_count: response.data.addresses_count,
                    order_count: response.data.orders_count,
                    password: 'getGlobalHeaders().password',
                    phone_verified: response.data.account.phone_verified,
                    social_id: response.data.account.social_id,
                });
            })
            .catch((e) => {
                setUserProfile();
                resetGlobalHeaders();

                setStorageOnlyData();
                setIsLoggedIn(false);
            });
    };
    useEffect(() => {
        loadUserData();
        try {
            
            if (Capacitor.isPluginAvailable('PushNotifications')) {
                PushNotifications.requestPermissions().then((result) => {
                    if (result.receive === 'granted') {
                        // Register with Apple / Google to receive push via APNS/FCM
                        PushNotifications.register();
                    } else {
                        //alert('Error Notifications');
                        // Show some error
                    }
                });

                PushNotifications.addListener(
                    'registration',
                    (token: Token) => {
                        if (token?.value) {
                            apis.setPushToken(token.value)
                                .then((respones) => {})
                                .catch((error) => {
                                    console.log(JSON.stringify(error));
                                });

                            console.log(
                                'Push registration success, token: ' +
                                    token.value
                            );
                        }
                    }
                );

                PushNotifications.addListener(
                    'registrationError',
                    (error: any) => {
                        console.log(
                            'Error on registration: ' + JSON.stringify(error)
                        );
                    }
                );

                PushNotifications.addListener(
                    'pushNotificationReceived',
                    (notification: PushNotificationSchema) => {
                        console.log(
                            'Push received: ' + JSON.stringify(notification)
                        );
                    }
                );

                PushNotifications.addListener(
                    'pushNotificationActionPerformed',
                    (notification: ActionPerformed) => {
                        if (notification.notification?.data) {
                            if (notification.notification.data?.link) {
                                history.push(
                                    notification.notification.data?.link
                                );
                            }
                        }
                    }
                );
            }
            apis.getCategories().then((response) => {
                try {
                    addToValueFilterDispatch(response.data?.categories);
                } catch (e) {
                    console.log(e);
                }
            });
        } catch (e) {
            console.log(e);
        }
    }, []);
    useEffect(() => {
        if (!loading && settings != undefined) {
            i18n.changeLanguage(settings.lang_tag || 'en');
        }
    }, [loading]);
    useEffect(() => {
        if (settings != undefined) {
            i18n.changeLanguage(settings.lang_tag || 'en');
        }
    }, [settings]);

    useEffect(() => {
        if (localLogin) {
            AuthCheck && AuthCheck();
            apis.getStats().then((respones) => {
                addToStatsDispatch(respones.data);
            });
        }
    }, [localLogin]);

    useEffect(() => {
        console.log(getGlobalHeaders());
        axiosApi.interceptors.request.use(function (config) {
            config.headers.Authorization =
                'Bearer ' + localStorage.getItem('token');

            config.withCredentials = false;
            config.headers.Accept = 'application/json';
            return config;
        });
    }, [getGlobalHeaders]);
    const routerRef = useRef<HTMLIonRouterOutletElement | null>(null);
    if (loading && settings != undefined && getGlobalHeaders().lang_tag) {
        return (
            <div id="startetComponent" style={{ textAlign: 'center' }}>
                <p>
                    {' '}
                    loading{' '}
                    <IonSpinner style={{ position: 'absolute' }} name="dots" />
                </p>
            </div>
        );
    }
    return (
        <IonApp
            style={{
                background: '#F9F9F9 0% 0% no-repeat padding-box',
            }}
        >
            <IonModal isOpen={downloadAppShow} cssClass="an-dialog-modal">
            <DownloadApp closeDialog={()=> {SetDownloadAppShow(false);}} />
            </IonModal>
            <IonReactRouter forceRefresh={true} history={history}>
                {/* <Route
                    render={(props) => {
                        if (isAuthenticated) {
                            if (
                                localLogin &&
                                ['/login', '/sign-up', '/'].includes(
                                    props.location.pathname
                                )
                            ) {
                                return <Redirect to="/tab/home" exact />;
                            }
                            if (isAuthenticated && localLogin === false) {
                                return (
                                    <Redirect
                                        to="/verification-account"
                                        exact
                                    />
                                );
                            }
                        }
                        //write your code here
                    }}
                ></Route> */}

                <AppUrlListener></AppUrlListener>
                {/* <RedirectToPage
                    Auth={isAuthenticated}
                    locallogin={localLogin}
                /> */}

                <IonRouterOutlet
                    style={{
                        direction: settings?.lang_tag === 'ar' ? 'rtl' : 'ltr',
                    }}
                    ref={routerRef}
                >
                    <NonAuthRoute path="/" exact component={GetStartedPage} />

                    <NonAuthRoute path="/login" exact component={Login} />
                    <NonAuthRoute
                        path="/verification-forgot"
                        exact
                        component={verificationForgotPage}
                    />
                    <NonAuthRoute
                        path="/Change-password-reset"
                        exact
                        component={changePasswordReset}
                    />
                    <Route
                        path="/verification-account2"
                        exact
                        component={VerificationPage}
                    />
                    <Route path="/tab" component={TabDemo}></Route>
                    <Route
                        path="/page/product/:slug"
                        exact
                        component={Product}
                    />
                    <Route
                        exact
                        component={FilterProducts}
                        path="/page/filter-products/:value"
                    />
                    <Route
                        path="/page/all-product/:id"
                        exact
                        component={AllProduct}
                    />
                    <NonAuthRoute path="/sign-up" exact component={SingnUp} />
                    <Route
                        path="/complete-sign-up"
                        exact
                        component={completeSingnUp}
                    />
                    <NonAuthRoute
                        path="/forgot-password"
                        exact
                        component={ForgotPassword}
                    />
                    <AuthRoute
                        path="/page/my-orders"
                        exact
                        component={MyOrders}
                    />
                    <AuthRoute
                        path="/page/settings"
                        exact
                        component={Settings}
                    />
                    <AuthRoute
                        path="/page/settings/change-language"
                        exact
                        component={ChangeLanguage}
                    />
                    <AuthRoute
                        path="/page/settings/edit-profile"
                        exact
                        component={EditProfile}
                    />
                    <AuthRoute
                        path="/page/settings/change-password"
                        exact
                        component={ChangePassword}
                    />
                    <AuthRoute
                        path="/page/settings/shipping-addresses"
                        exact
                        component={ShippingAddresses}
                    />
                    <AuthRoute
                        path="/page/settings/shipping-addresses/add-new-address"
                        exact
                        component={AddNewAddress}
                    />
                    <AuthRoute
                        path="/page/my-orders/:id"
                        exact
                        component={OrderInfo}
                    />
                    <AuthRoute
                        path="/page/checkout"
                        exact
                        component={CheckoutPage}
                    />
                    <AuthRoute
                        path="/page/my-reviews"
                        exact
                        component={MyReviews}
                    />
                    <AuthRoute
                        path="/page/place-order/:id"
                        exact
                        component={OrderPlaced}
                    />
                    <AuthRoute
                        path="/page/payment-methods"
                        exact
                        component={PaymentMethods}
                    />
                    <AuthRoute
                        path="/page/payment-methods/add-payment-methods"
                        exact
                        component={AddPaymentMethods}
                    />
                    <Route
                        path="/logout"
                        render={() => {
                            return (
                                <>
                                    Logout ... <IonSpinner />
                                    <RedirectToLogin
                                        deleteAllFromWishlistDispatch={
                                            deleteAllFromWishlistDispatch
                                        }
                                        deleteAllFromCartDispatch={
                                            deleteAllFromCartDispatch
                                        }
                                        setIsLoggedIn={setIsLoggedIn}
                                        setUserProfile={setUserProfile}
                                        deleteAllStats={deleteAllStats}
                                    />
                                </>
                            );
                        }}
                    />
                    <Route>
                        {' '}
                        <Redirect to="/tab/home"></Redirect>
                    </Route>
                </IonRouterOutlet>
            </IonReactRouter>
        </IonApp>
    );
};

export default React.memo(App);

const IonicAppConnected = connect<{}, any, DispatchProps>({
    mapStateToProps: (state) => ({
        darkMode: state.user.darkMode,
        isAuthenticated: state.user.isLoggedin,
        localLogin:
            state.user.isLoggedin &&
            state.user.userProfile != null &&
            state.user.userProfile != undefined &&
            state.user.userProfile.localLogin == true,
        settings: state.user.settings,
        loading: state.user.loading,
    }),
    mapDispatchToProps: {
        loadUserData,
        deleteAllFromWishlistDispatch,
        setUserProfile,
        deleteAllFromCartDispatch,
        setIsLoggedIn,
        addToStatsDispatch,
        addToValueFilterDispatch,
        deleteAllStats,
    },
    component: IonicApp,
});
