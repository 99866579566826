import { IonButton, IonButtons, IonIcon } from '@ionic/react'
import React from 'react'
import { IPropsButtonsIcon } from './props.buttons-icon'
import './style.scss'
export const ButtonsIcon:React.FC<IPropsButtonsIcon>=React.memo(({color,icon,slot,onClick})=>{

 return(<IonButtons  onClick={onClick} slot={slot&&slot}>
     <IonButton /* routerLink="/page/search"  */color={color&&color}>
         <IonIcon slot="icon-only" icon={icon}/>
     </IonButton>
 </IonButtons>)   
})
