import { IonContent, IonList, IonPage, IonRadioGroup } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button/index';
import { ItemRadio } from '../../components/item-radio';
import { FooterPage } from '../../layout/footer-page';
import { HeaderSubPage } from '../../layout/Header/header-sub-page';
import {
    ChangeLang,
    getGlobalHeaders,
    setLangTagGlobalHeaders,
} from '../../modules/global-data';
import { connect } from '../../redux/connect';
import { setSettings } from '../../redux/user/user.actions';
import { setSettingsData } from '../../storage/management-Storage';
import './style.scss';
interface DispatchProps {
    setSettings: typeof setSettings;
}
const ChangeLanguage: React.FC<any> = ({ setSettings, settings }) => {
    const { i18n, t } = useTranslation('common');

    const listLang = [
        {
            name: 'English',
            value: 'en',
        },
        {
            name: 'العربية',
            value: 'ar',
        },
    ];

    const [langApp, setLangApp] = useState('ar');

    useEffect(() => {
        setLangApp(settings?.lang_tag ?? 'ar');
    }, [settings]);
    const onChangeLang = () => {
        setLangApp(langApp);
        setSettingsData({ lang_tag: langApp });
        setSettings({ lang_tag: langApp });
        setLangTagGlobalHeaders(langApp);

        i18n.changeLanguage(langApp);
        ChangeLang(langApp);
    };

    return (
        <IonPage className="change-lang">
            <HeaderSubPage
                title={t('Change_Language')}
                defaultHref="/page/settings"
            ></HeaderSubPage>

            <IonContent className="content-color">
                <IonList className="list" color="none">
                    <IonRadioGroup
                        onIonChange={(e) => setLangApp(e.detail.value)}
                        value={langApp}
                    >
                        {listLang.map((data, index) => {
                            return (
                                <ItemRadio
                                    key={index}
                                    name={data.name}
                                    value={data.value}
                                />
                            );
                        })}
                    </IonRadioGroup>
                </IonList>
            </IonContent>
            <FooterPage>
                <Button
                    onClick={onChangeLang}
                    color="none"
                    expand="full"
                    height={35}
                    text={t('Save_Changes')}
                ></Button>
            </FooterPage>
        </IonPage>
    );
};

export default connect<{}, any, DispatchProps>({
    mapStateToProps: (state) => ({
        darkMode: state.user.darkMode,
        userProfile: state.user.userProfile,
        isAuthenticated: state.user.isLoggedin,
        localLogin:
            state.user.isLoggedin &&
            state.user.userProfile != null &&
            state.user.userProfile != undefined &&
            state.user.userProfile.localLogin == true,
        settings: state.user.settings,
    }),
    mapDispatchToProps: {
        setSettings,
    },
    component: React.memo(ChangeLanguage),
});
