import {
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonTitle,
    IonToolbar,
    useIonLoading,
    useIonToast,
} from '@ionic/react';
import { arrowBack, arrowForward } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../components/button';
import ItemInput from '../../../../components/item-input';
import { Select } from '../../../../components/select-item';
import { Toast } from '../../../../components/toast';
import { FooterPage } from '../../../../layout/footer-page';
import { LoadingCenter } from '../../../../layout/loading-center';
import { getGlobalHeaders } from '../../../../modules/global-data';
import apis from '../../../../services';

export const BodyAddAddress: React.FC<{
    props: any;
    onDismiss: () => void;
    onIncrement: () => void;
    getAddress: () => void;
}> = (props) => {
    const { t } = useTranslation('common');
    const [present, dismiss] = useIonToast();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [loading, setLoading] = useState(false);
    const [governates, setGovernates] = useState<any>({
        data: [],
        loading: true,
        erorMessage: null,
    });
    const [countries, setCountries] = useState<any>({
        data: [],
        loading: true,
        erorMessage: null,
    });
    const [presentLoading, dismissLoading] = useIonLoading();

    const [countriesId, setCountriesId] = useState(0);
    const [governatesId, setGovernatesId] = useState(0);
    const [cityId, setCityId] = useState(0);
    const [city, setCity] = useState<any>({
        data: [],
        loading: true,
        erorMessage: null,
    });
    useEffect(() => {
        getGovernatesCountries();
    }, []);
    const getGovernatesCountries = () => {
        // setCountriesId(id);
        presentLoading({
            message: 'Loading...',
            duration: 4000,
        });
        apis.getAllGovernates()
            .then((response) => {
                setGovernates({
                    data: response.data,
                    loading: false,
                });
            })
            .finally(() => {
                dismissLoading();
            });
    };
    const getCity = (id: number) => {
        setCountriesId(
            governates?.data.find((x: any) => x.id === id)?.country_id ?? 0
        );
        setGovernatesId(id);

        presentLoading({
            message: 'Loading...',
            duration: 4000,
        });
        apis.getCitiesOfGovernate(id)
            .then((response) => {
                setCity({
                    data: response.data.cities,
                    loading: false,
                });
            })
            .finally(() => {
                dismissLoading();
            });
    };
    const handelAddAddress = async (data: { address_1: string }) => {
      
        if (governatesId != -1 && countriesId != -1 && cityId != -1
            && governatesId && countriesId && cityId) {
            setLoading(true);
        await apis
            .addAddress({
                city_id: cityId,
                state_id: governatesId,
                country_id: countriesId,
                first_name: getGlobalHeaders().name_user ?? ' demo',
                last_name: t('Empty'),

                customer_id: getGlobalHeaders().user_id,
                address_1: data.address_1 ?? t('Empty'),
                city:
                    governates.data.find((x: any) => x.id == governatesId)
                        ?.name_ar ?? 'test',
                state: city.data.find((x: any) => x.id == cityId).name_ar,
                zip: '000',
                country: 'country',
                country_name:
                    countries.data.find((x: any) => x.id == countriesId)
                        ?.name_ar.name_ar ?? 'demo',

                state_name: city.data.find((x: any) => x.id == cityId).name_ar,
            })
            .then((respone) => {
                if (respone.data) {
                    setLoading(false);
                    Toast(present, t('Saved_Successfully'), 1500);
                    props?.props?.getAddress && props?.props?.getAddress();
                    setTimeout(() => {
                        props.onDismiss();
                    }, 1510);
                }
            })
            .catch((error) => {
                setLoading(false);
                Toast(present, t('Failed_To_Save'), 1500, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
        }
        else{
            Toast(present, t("Please_fill_fields"), 1500, 'error');
        }
    };
    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButtons onClick={props.onDismiss} slot="start">
                        <IonButton>
                            <IonIcon
                                slot="icon-only"
                                icon={arrowBack}
                            ></IonIcon>
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Add Address</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="content-color">
                {governates.loading === false ? (
                    <>
                        {/* <Select
                            onChange={getGovernatesCountries}
                            options={countries.data}
                            label={t('District')}
                        /> */}
                        <Select
                            // disabled={city.data.length > 0 ? true : false}
                            onChange={getCity}
                            options={governates.data}
                            label={t('Governorate')}
                        />
                        <Select
                            disabled={
                                governates?.data?.length > 0 ? false : true
                            }
                            onChange={setCityId}
                            options={city.data}
                            label={t('City')}
                        />
                        <form onSubmit={handleSubmit(handelAddAddress)}>
                            <IonItem>
                                <IonLabel position="floating">
                                    {t('Address_Details')}
                                </IonLabel>
                                <IonInput
                                    style={{
                                        border:
                                            errors && errors.address_1
                                                ? '1px solid red'
                                                : '',
                                    }}
                                    {...register('address_1', {
                                        required: true,
                                    })}
                                    placeholder={t('Address_Details')}
                                ></IonInput>
                            </IonItem>
                        </form>
                    </>
                ) : (
                    <LoadingCenter></LoadingCenter>
                )}
            </IonContent>
            <FooterPage>
                {' '}
                <Button
                    loading={loading}
                    disabled={loading}
                    text="save"
                    color="none"
                    expand="full"
                    onClick={handleSubmit(handelAddAddress)}
                ></Button>
            </FooterPage>
        </>
    );
};
