import { axiosApi } from ".";

interface IBodyParmeter {
  order_id?:string,
  shipping: {
    first_name: string;
    last_name: string;
    address_1: string;
    city: string;
    zip: string;
    state: string;
    country: string;
  };
  shipping_city_code?: string;
  shipping_state_code?: string;
  shipping_method?: string;
  payment_method: string;
  terms_and_conditions: boolean | true;
}
const createCheckout = (body: IBodyParmeter) => {
  return axiosApi.post("/checkout", body);
};
const updateCheckout = (body: IBodyParmeter) => {
  return axiosApi.post("/checkout-update", body);
};
const getCheckoutDetails = () => {
  return axiosApi.get("/checkout");
};
const cancelCheckout = (orderId: number, cancel_reason: string) => {
  return axiosApi.post(`/checkout/${orderId}/cancel`,{
    order_id : orderId,
    cancel_reason : cancel_reason
  });
};

const checkoutApis = { getCheckoutDetails, createCheckout, cancelCheckout,updateCheckout };

export default checkoutApis;
