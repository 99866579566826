import { UserActions } from './user.actions';
import { IUserState } from './user.interface';
const initializing = {
    hasSeen: {},
    darkMode: false,
    isLoggedin: false,
    loading: true,
    settings: { lang_tag: 'en' },
    userProfile: undefined,
};
function userReducer(
    state: IUserState = initializing,
    action: any
): IUserState {
  
    switch (action.type) {
        
        case 'set-user-loading':
          
            return { ...state, loading: action.isLoading };
        case 'set-user-data':
          
            return { ...state, ...action.data };
        case 'set-settings':
            return { ...state, settings: action.settings };
        case 'set-user-profile':
            return { ...state, userProfile: action.userProfile };
        case 'set-has-seen':
            return { ...state, hasSeen: action.hasSeen };
        case 'set-dark-mode':
            return { ...state, darkMode: action.darkMode };
        case 'set-is-loggedin':
        
            return { ...state, isLoggedin: action.loggedIn };
        default:

            return state;
    }

}
export default userReducer;
