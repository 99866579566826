import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonPage,
    IonProgressBar,
    IonToast,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Refresher } from '../../../components/refresher';
import { PHONE_NUMBER_SUPPORT } from '../../../helpers/cons';

import { HeaderMain } from '../../../layout/Header/header-main';

import { connect } from '../../../redux/connect';
import { deleteAllStats } from '../../../redux/stats/stats.actions';
import {
    loadUserData,
    setUserProfile,
    setIsLoggedIn,
} from '../../../redux/user/user.actions';
import apis from '../../../services';
import { VerificationComponent } from '../../verification/verification-component';
import { HeaderProfileInfo } from './component/header-profile-info';
import { ListMenu } from './component/list-menu';
import './style.scss';
const Profile: React.FC<{
    isAuthenticated: boolean;
    localLogin: boolean;
    userProfile: any;
    setIsLoggedIn: any;
    setUserProfile: any;
    deleteAllStats: any;
}> = ({
    deleteAllStats,
    isAuthenticated,
    localLogin,
    userProfile,
    setIsLoggedIn,
    setUserProfile,
}) => {
    const { t } = useTranslation('common');
    var menuGuest = [
        {
            title: t('Login'),
            description: t('you_have_account'),
            link: '/login',
        },
        {
            title: t('Sign_up'),
            description: t('dont_have_account'),
            link: '/sign-up',
        },
    ];

    const refModal = useRef<any>(null);
    const [loadingImage, setLoadingImage] = useState(false);
    const isLoggedin = localLogin;
    const ActiveAccount = isAuthenticated;
    var menu = [
        {
            title: t('My_Orders'),
            description: t('Already_have__orders', {
                counts: `${userProfile?.order_count ?? 0}`,
            }),
            link: '/page/my-orders',
        },
        {
            title: t('Shipping_Addresses'),
            description: t('Address__count', {
                counts: `${userProfile?.addresses_count ?? 0}`,
            }),
            link: '/page/settings/shipping-addresses',
        },
        /*   {
            title: t("Payment_Methods"),
            description: t("Visa") + ' **89',
            link: '/page/payment-methods',
        }, */
        {
            title: t('My_Reviews'),
            description: t('Reviews_for__items', {
                counts: `${userProfile?.reviews_count ?? 0}`,
            }),
            link: '/page/my-reviews',
        },
        {
            title: t('Settings'),
            description: t('Notifications_password'),
            link: '/page/settings',
        },
    ];
    //const dispatch = useDispatch();
    const getProfileInfo = () => {
        apis.getProfileInfo()
            .then((response) => {
                setUserProfile({
                    main_phone: response.data.account.phone,
                    email: response.data.account.email,
                    fullName:
                        response.data.account.first_name +
                        ' ' +
                        response.data.account.last_name,
                    avatar: response.data.account.picture?.path,
                    localLogin: true,
                    user_id: response.data.account.id,
                    reviews_count: response.data.reviews_count,
                    addresses_count: response.data.addresses_count,
                    order_count: response.data.orders_count,
                    password: 'getGlobalHeaders().password',
                    phone_verified: response.data.account.phone_verified,
                    social_id: response.data.account.social_id,
                });

                // dispatch(loadUserData());
            })
            .catch((error) => {});
    };
    const refLink = useRef<any>();
    useEffect(() => {
        if (isLoggedin && ActiveAccount) getProfileInfo();
    }, []);
    const [fileUpload, setFileUpload] = useState<any>();
    const [image, setImage] = useState(null);
    const [showToast2, setShowToast2] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const onDismiss = (goback: boolean) => {
        setShowModal(false);
        if (userProfile?.phone_verified === 0) setShowToast2(true);
    };
    const uploadProfilePicutre = (fileInput: any) => {
        // setLoadingImageUpload(false);
        setLoadingImage(true);
        if (!fileInput || !fileInput.files) {
            return;
        }
        if (fileInput.files.length === 0) {
            setLoadingImage(false);
            /*   setLoadingImg(false); */
            return;
        }
        const formData = new FormData();
        formData.append('picture_file', fileInput.files[0]);

        apis.uploadProfilePicutre(formData)
            .then((res) => {
                // getDataProfile();
                userProfile.avatar = res.data.path;
                setImage(res.data.path);
                setUserProfile(userProfile);
                setLoadingImage(false);
            })
            .finally(() => {
                setLoadingImage(false);
            });
    };
    return (
        <IonPage className="profile">
            <HeaderMain
                deleteAllStats={deleteAllStats}
                setUserProfile={setUserProfile}
                setIsLoggedIn={setIsLoggedIn}
                logout={true}
                title={t('My_Profile')}
                subTitle={t('Here_the_profile_guest')}
            />

            <IonContent className="content-color">
                <Refresher Refresh={getProfileInfo} />
                <>
                    <input
                        ref={(file: any) => setFileUpload(file)}
                        hidden={true}
                        onChange={(value: any) =>
                            uploadProfilePicutre(value.target)
                        }
                        type="file"
                    />
                    <HeaderProfileInfo
                        onClick={() =>
                            isLoggedin
                                ? fileUpload.click()
                                : console.log('Login')
                        }
                        img={
                            !isLoggedin
                                ? process.env.PUBLIC_URL + '/assets/avatar.png'
                                : image != null
                                ? image
                                : userProfile?.avatar ??
                                  process.env.PUBLIC_URL + '/assets/avatar.png'
                        }
                        name={userProfile?.fullName ?? t('Guest')}
                        email={userProfile?.email}
                    />
                    {loadingImage ? (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    ) : (
                        ''
                    )}
                    {isLoggedin && ActiveAccount ? (
                        <div className="menu">
                            {menu.map((data, index) => {
                                return (
                                    <ListMenu
                                        routerLink={data.link}
                                        key={index}
                                        title={data.title}
                                        description={data.description}
                                    ></ListMenu>
                                );
                            })}
                            <IonItem
                                onClick={() => refLink.current.click()}
                                lines="none"
                                color="none"
                                style={{
                                    background: '#f9f9f9',
                                    borderBottom: '1.2px solid #e0e0e0',
                                }}
                            >
                                <IonLabel>
                                    <h2 className="item-content-h">
                                        {t('contentWithSupport')}{' '}
                                    </h2>
                                    <p className="item-content-p">whatsup</p>
                                </IonLabel>
                                <IonIcon
                                    icon={
                                        process.env.PUBLIC_URL +
                                        '/assets/svg/whatsapp.svg'
                                    }
                                ></IonIcon>
                            </IonItem>
                        </div>
                    ) : (
                        <div className="menu">
                            {menuGuest.map((data, index) => {
                                return (
                                    <ListMenu
                                        routerLink={data.link}
                                        key={index}
                                        title={data.title}
                                        description={data.description}
                                    ></ListMenu>
                                );
                            })}
                            <IonItem
                                onClick={() => refLink.current.click()}
                                lines="none"
                                color="none"
                                style={{
                                    background: '#f9f9f9',
                                    borderBottom: '1.2px solid #e0e0e0',
                                }}
                            >
                                <IonLabel>
                                    <h2 className="item-content-h">
                                        {t('contentWithSupport')}{' '}
                                    </h2>
                                    <p className="item-content-p">whatsup</p>
                                </IonLabel>
                                <IonIcon
                                    icon={
                                        process.env.PUBLIC_URL +
                                        '/assets/svg/whatsapp.svg'
                                    }
                                ></IonIcon>
                            </IonItem>
                        </div>
                    )}
                </>
                <a
                    hidden
                    ref={refLink}
                    href={`https://api.whatsapp.com/send?phone=${PHONE_NUMBER_SUPPORT}&text=مرحبا اريد المساعدة`}
                ></a>
                <IonModal
                    isOpen={showModal}
                    backdropDismiss={false}
                    keyboardClose={false}
                    cssClass="my-custom-class"
                >
                    <VerificationComponent
                        userProfile={userProfile}
                        onDismiss={onDismiss}
                        setUserProfile={setUserProfile}
                        setIsLoggedIn={setIsLoggedIn}
                    />
                </IonModal>
            </IonContent>
        </IonPage>
    );
};
export default connect<{}, any>({
    mapStateToProps: (state) => ({
        darkMode: state.user.darkMode,
        isAuthenticated: state.user.isLoggedin,
        localLogin:
            state.user.isLoggedin &&
            state.user.userProfile != null &&
            state.user.userProfile != undefined &&
            state.user.userProfile.localLogin == true,
        userProfile:
            state.user.isLoggedin &&
            state.user.userProfile != null &&
            state.user.userProfile != undefined &&
            state.user?.userProfile,
    }),
    mapDispatchToProps: {
        setIsLoggedIn,
        setUserProfile,
        deleteAllStats,
    },
    component: React.memo(Profile),
});
