import {
    IonChip,
    IonItem,
    IonLabel,
    IonText,
    IonThumbnail,
} from '@ionic/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../components/modal';
import { CURRENCY_LI } from '../../../../helpers/currency';
import { GridIamge } from '../../../my-orders/component/order-item/model/grid-image';
import { IPropsItemInfoOrder } from './props.item-info-order';
import './style.scss';
export const ItemInfoOrder: React.FC<IPropsItemInfoOrder> = ({
    productsName,
    price,
    state,
    onClickCancelOrder,
    images,
}) => {
    const { t } = useTranslation('common');
    const img = [
        'https://d15sjtfh6z5ui1.cloudfront.net/Items/GS212552/RUST/5f7303a63a05e.jpg',
        'https://d15sjtfh6z5ui1.cloudfront.net/Items/GS212552/RUST/5f7303a63a05e.jpg',
        'https://d15sjtfh6z5ui1.cloudfront.net/Items/GS212552/RUST/5f7303a63a05e.jpg',
    ];
    const stateOrder = (state: number) => {
        switch (state) {
            case 0:
                break;
            case 1:
                return 'Preparing';
            case 2:
                return 'On The Way';
            case 3:
                return 'Delivered!';
            default:
                break;
        }
    };
    const eventHandler = useCallback(() => {
        onClickCancelOrder && onClickCancelOrder();
    }, [onClickCancelOrder]);

    const onStatebackground = (state: number) => {
        switch (state) {
            case 0:
                return '#6680C6';
            case 1:
                return '#F69400';
            case 2:
                return '#FED700';
            case 3:
                return '#4CD100';
            default:
                return '#6680C6';
        }
    };

    return (
        <IonItem color="none" lines="none" className="item-order-info">
            <IonThumbnail slot="start" className="thumbnail">
                <GridIamge images={images} />
            </IonThumbnail>
            <IonLabel>
                <h2 className="products-name">{productsName}</h2>
                <p className="price">
                    {CURRENCY_LI}
                    {price}
                </p>
                {state == 0 ? (
                    <IonChip
                        onClick={eventHandler}
                        mode="md"
                        className="chip-cancel-order"
                    >
                        {t('Cancel_Order')}
                    </IonChip>
                ) : (
                    <IonText
                        className="text-state-order"
                        style={{ color: onStatebackground(state ?? 0) }}
                    >
                        {stateOrder(state ?? 0)}
                    </IonText>
                )}
            </IonLabel>
        </IonItem>
    );
};
