import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonSpinner,
    IonText,
    IonTitle,
    IonToolbar,
    useIonToast,
} from '@ionic/react';
import { arrowBack, arrowForward } from 'ionicons/icons';
import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useHistory } from 'react-router';
import { ReactSVG } from 'react-svg';
import { Toast } from '../../components/toast';
import { HeaderModal } from '../../layout/Header/header-modal';
import { connect } from '../../redux/connect';
import { deleteAllStats } from '../../redux/stats/stats.actions';
import apis from '../../services';
import './style.scss';
export const VerificationComponent: React.FC<any> = forwardRef(
    ({ userProfile, onDismiss, setUserProfile }, ref) => {
        const { t } = useTranslation('common');
        const history = useHistory();
        const [verifiocationCode, setVerifiocationCode] = useState('');
        const [loading, setLoading] = useState(false);
        const [present, dismiss] = useIonToast();

        const onActiveCode = (code: string) => {
            setVerifiocationCode(code);

            if (code.length == 4) {
                setLoading(true);

                apis.verifyUserAccount({
                    code: code,
                    username:
                        localStorage.getItem('verify_username') ??
                        userProfile.main_phone,
                })
                    .then(() => {
                        setLoading(false);
                        Toast(
                            present,
                            t('Your_account_has_been_activated'),
                            1500,
                            'sucsess'
                        );
                        userProfile.phone_verified = 1;
                        userProfile.localLogin = true;
                        setUserProfile(userProfile);
                        onDismiss && onDismiss();
                    })
                    .catch((error) => {
                        Toast(present, t('Code_Invalid'), 1500, 'error');
                        setLoading(false);
                    });
            }
        };

        return (
            <>
                <HeaderModal
                    onClose={() => {
                        onDismiss(true);
                    }}
                    title={t('Verification')}
                />
                <IonContent className="content-color">
                    <div className="verification">
                        <div className="order-placed">
                            <ReactSVG
                                wrapper="span"
                                className="wrapper-class-name"
                                evalScripts="always"
                                beforeInjection={(svg) => {
                                    svg.classList.add('svg-class-name');
                                    svg.setAttribute('style', 'width: 200px');
                                }}
                                src={
                                    process.env.PUBLIC_URL +
                                    '/assets/svg/internet-connection.svg'
                                }
                            />
                            {/*             <ReactSVG  className="img" src={process.env.PUBLIC_URL + "/assets/svg/internet-connection.svg"} />
                             */}{' '}
                            <div className="title">
                                <IonText className="text">
                                    {t('Enter_Activation_Code')}
                                </IonText>
                            </div>
                            <div style={{ paddingTop: '20px' }}>
                                {loading && (
                                    <IonSpinner className="loading-spiner-center"></IonSpinner>
                                )}
                                <div
                                    style={{
                                        direction: 'ltr',
                                        background: loading ? '#f5f5f5c4' : '',
                                    }}
                                >
                                    <ReactInputVerificationCode
                                        placeholder="__"
                                        value={verifiocationCode}
                                        onChange={onActiveCode}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
            </>
        );
    }
);
