import {
    IonCard,
    IonIcon,
    IonText,
    useIonToast,
    isPlatform,
    IonSpinner,
} from '@ionic/react';
import { Browser } from '@capacitor/browser';
import { logoApple, logoFacebook } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { CardIcon } from './component/card-icon';
import { IPropsFooterStarted } from './props.footer-started';
import './style.scss';
import {
    FacebookError,
    FacebookLogin,
    FacebookLoginResponse,
} from '@capacitor-community/facebook-login';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { useTranslation } from 'react-i18next';
import apis from '../../services';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { setStorageOnlyData } from '../../storage/management-Storage';
import { Toast } from '../../components/toast';

import {
    SignInWithApple,
    SignInWithAppleResponse,
    SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import { initFacebookSdk } from '../../services/init-facebook-sdk';

export const FooterStarted: React.FC<IPropsFooterStarted> = ({
    title,
    setUserProfile,
    setIsLoggedIn,
    setShowModal,
}) => {
    const { t } = useTranslation('common');
    const history = useHistory();
    const [present, dismiss] = useIonToast();
    useEffect(() => {
        async function initGoogle() {
            try {
                await GoogleAuth.init();
            } catch (e: any) {
                console.log(e.message);
            }
        }

        initGoogle();

        initFacebookSdk().then(() => {});
    }, []);

    const googleIcon =
        process.env.PUBLIC_URL + '/assets/icon/Google__G__Logo.svg';
    const [socialLoading, SetSocialLoading] = useState(false);
    const loginWithGoogle = async () => {
        SetSocialLoading(true);
        GoogleAuth.signIn()
            .then((googleUser) => {
                const socialUser = {
                    fname: googleUser.givenName,
                    lname: googleUser.familyName,
                    profileImage: googleUser.imageUrl,
                    id: googleUser.id,
                    provider: 'google',
                    token: googleUser.authentication.accessToken,
                    idToken: googleUser.authentication.idToken,
                    serverAuthCode: googleUser.serverAuthCode,
                };
                localStorage.setItem('social_user', JSON.stringify(socialUser));

                SetSocialLoading(true);
                //provider:string,
                //accessToken: any,
                //firstName: string,
                //lastName: string,
                //userId: string,
                //_idToken?:string,
                //_serverAuthCode?:string,
                //_phone = ""

                apis.loginWithSocial(
                    'google',
                    googleUser.authentication.accessToken,
                    socialUser.fname,
                    socialUser.lname,
                    socialUser.id,
                    googleUser.authentication.idToken,
                    googleUser.serverAuthCode
                )
                    .then((response: any) => {
                        successResponse(response);
                    })
                    .catch((error: any) => {
                        errorResponse(error);
                    })
                    .finally(() => {
                        SetSocialLoading(false);
                    });
                /*loginWith({
        variables: {
          provider: "GOOGLE",
          SocialId: googleUser.id,
        },
      });*/
            })
            .catch((reason) => {
                Toast(
                    present,
                    'Google Error:' + JSON.stringify(reason),
                    1400,
                    'error'
                );
            })
            .finally(() => {
                SetSocialLoading(false);
            });
    };
    const loginWithFacebook = async () => {
        SetSocialLoading(true);
        const FACEBOOK_PERMISSIONS = ['public_profile'];
        FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })
            .then((resultAccessToken) => {
                if (resultAccessToken.accessToken) {
                    // Login successful.
                    console.log(
                        `Facebook access token is ${resultAccessToken.accessToken.token}`
                    );

                    SetSocialLoading(true);
                    FacebookLogin.getProfile<{
                        id: string;
                        picture: any;
                        name: string;
                    }>({ fields: ['name', 'picture'] })
                        .then((result) => {
                            var names = result.name.split(' ');
                            var fname = result.name;
                            var lname = result.name;
                            if (names.length > 1) {
                                fname = names[0];
                                lname = names[1];
                            }
                            const socialUser = {
                                fname: fname,
                                lname: lname,
                                profileImage: result.picture.data.url,
                                id: result.id,
                                provider: 'facebook',
                                token: resultAccessToken?.accessToken?.token,
                            };
                            console.log(JSON.stringify(socialUser));

                            localStorage.setItem(
                                'social_user',
                                JSON.stringify(socialUser)
                            );

                            apis.loginWithSocial(
                                'facebook',
                                resultAccessToken?.accessToken?.token,
                                socialUser.fname,
                                socialUser.lname,
                                socialUser.id,
                                undefined,
                                undefined
                            )
                                .then((response: any) => {
                                    successResponse(response);
                                })
                                .catch((error: any) => {
                                    errorResponse(error);
                                })
                                .finally(() => {
                                    SetSocialLoading(false);
                                });
                        })
                        .catch((reason) => {
                            Toast(present, t('Errorwhilelogin'), 1400, 'error');
                        })
                        .finally(() => {
                            SetSocialLoading(false);
                        });
                } else {
                    SetSocialLoading(false);
                }
            })
            .catch((reason: FacebookError) => {
                console.error(reason.message);
                Toast(
                    present,
                    'Facebook Error:' + JSON.stringify(reason.message),
                    1400,
                    'error'
                );
            })
            .finally(() => {
                SetSocialLoading(false);
            });
    };

    let options: SignInWithAppleOptions = {
        clientId: 'com.your.webservice',
        redirectURI: 'https://www.yourfrontend.com/login',
        scopes: 'email name',
        state: '12345',
        nonce: 'nonce',
    };
    const loginWithApple = async () => {
        SetSocialLoading(true);

        SignInWithApple.authorize(options)
            .then((result: SignInWithAppleResponse) => {
                // Handle user information
                // Validate token with server and create new session
                if (result.response && result.response.identityToken) {
                    var user = result.response;
                    console.log(JSON.stringify(user));
                    const socialUser = {
                        fname: user.givenName,
                        lname: user.familyName,
                        profileImage: '',
                        id: user.user ?? user.email,
                        provider: 'apple',
                        token: user.identityToken,
                    };
                    localStorage.setItem(
                        'social_user',
                        JSON.stringify(socialUser)
                    );

                    SetSocialLoading(true);
                    apis.loginWithSocial(
                        'apple',
                        user.identityToken,
                        socialUser.fname ?? '',
                        socialUser.lname ?? '',
                        socialUser.id ?? '',
                        user.identityToken
                    )
                        .then((response: any) => {
                            successResponse(response);
                        })
                        .catch((error: any) => {
                            errorResponse(error);
                        })
                        .finally(() => {
                            SetSocialLoading(false);
                        });
                    /*loginWith({
                variables: {
                provider: "GOOGLE",
                SocialId: googleUser.id,
                },
            });*/
                } else {
                    Toast(present, t('Login_Failed'), 1400, 'error');
                }
            })
            .catch((error) => {
                Toast(present, t('Login_Failed'), 1400, 'error');
            })
            .finally(() => {
                SetSocialLoading(false);
            });
    };
    const successResponse = (response: any) => {
        localStorage.setItem('token', response.data.auth.token);
        localStorage.setItem('user_id', response.data.user.id);
        localStorage.setItem(
            'fullname',
            response.data.user.first_name + ' ' + response.data.user.last_name
        );

        setUserProfile({
            phone_verified: response.data.user.phone_verified,
            main_phone: response.data.user.phone,
            email: response.data.user.email,
            fullName:
                response.data.user.first_name +
                ' ' +
                response.data.user.last_name,
            avatar: response.data.user.picutre?.path,
            localLogin: true,
            user_id: response.data.user.id,
            password: '',
            social_id: response.data.user.social_id,
        });

        setIsLoggedIn(true);
        setStorageOnlyData({
            access_token: response.data.auth.token,
            refresh_token: 'data.refresh_token',
            user_id: response.data.user.id,
            type_user: 'user',
        });
        setTimeout(() => {
            // window.location.reload();
        }, 100);
    };
    const errorResponse = (error: any) => {
        if (
            error.message === 'Request failed with status code 401' &&
            error.response?.data?.errors
        ) {
            /*   userProfile.localLogin = false; */
            setUserProfile({
                localLogin: false,
            });
            /*                     dispatch(setUserProfile(userProfile));
             */ setIsLoggedIn(true);
            localStorage.setItem('verify_username', error.response?.data?.errors[0]);
            setShowModal(true);
        }
        console.log(JSON.stringify(error));

        Toast(
            present,
            error.response?.data?.message ?? t('Error_In_Connection'),
            1400,
            'error'
        );
    };
    return (
        <div className="content-footer">
            <IonText className="title">{title}</IonText>
            <div>{socialLoading && <IonSpinner></IonSpinner>}</div>
            <div className="card-align">
                <IonCard
                    onClick={loginWithFacebook}
                    className="card-icon facebook-icon"
                    button
                >
                    <IonIcon
                        size="large"
                        style={{ color: '#395A9A' }}
                        icon={logoFacebook}
                    />
                </IonCard>
                <IonCard onClick={loginWithGoogle} className="card-icon" button>
                    <IonIcon size="large" icon={googleIcon} />
                </IonCard>
                {isPlatform('ios') && (
                    <IonCard
                        onClick={loginWithApple}
                        className="card-icon"
                        button
                    >
                        <IonIcon size="large" icon={logoApple} />
                    </IonCard>
                )}
            </div>
        </div>
    );
};
export default React.memo(FooterStarted);
