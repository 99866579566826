import { IonHeader, IonTitle, IonToolbar } from '@ionic/react'
import { close } from 'ionicons/icons'
import React from 'react'
import { ButtonsIcon } from '../../../components/buttons-icon'
import { IPropsHeaderModal } from './props.header-modal'
import './style.scss'
export const HeaderModal:React.FC<IPropsHeaderModal>=({title,onClose})=>{

return(
    <IonHeader color="none" className="header-modal">
<IonToolbar color="none">
    <IonTitle className="title">{title}</IonTitle>
    <ButtonsIcon  onClick={onClose} slot="end" icon={close}/>
</IonToolbar>
    </IonHeader>
 
)
}
