import { IonHeader, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import {
    logInOutline,
    optionsOutline,
    powerSharp,
    searchCircle,
    searchOutline,
} from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { setTimeout } from 'timers';
import { ButtonsIcon } from '../../../components/buttons-icon';
import { Modal } from '../../../components/modal';
import { showConfirm } from '../../../helpers/util/native/confirm';
import { useAuth } from '../../../hooks/use-auth';
import { useStorage } from '../../../hooks/use-storage';
import {
    getGlobalHeaders,
    resetGlobalHeaders,
} from '../../../modules/global-data';
import { deleteAllStats } from '../../../redux/stats/stats.actions';

import apis from '../../../services';
import { setStorageOnlyData } from '../../../storage/management-Storage';
import { BodyFilterProducts } from './component/body-filter-products';
import { BodySearchProduct } from './component/body-search-product';
import { IPropsHeaderMain } from './props.header-main';
import './style.scss';
export const HeaderMain: React.FC<IPropsHeaderMain> = React.memo(
    ({
        title,
        subTitle,
        logout,
        setIsLoggedIn,
        setUserProfile,
        deleteAllStats,
    }) => {
        const { isAuthenticated, localLogin } = useAuth();
        const { filter } = useStorage();
        const { t } = useTranslation('common');
        const refModal = useRef<any | null>(null);
        const refModalFilter = useRef<any | null>(null);

        const isLoggedin = isAuthenticated;
        // useSelector((state: any) => state.filterReducer);
        const lang = getGlobalHeaders().lang_tag;

        const isAuth = localLogin;

        const [loading, setLoading] = useState(false);
        const showModal = () => {
            if (refModal.current) {
                refModal.current.showModal();
            }
        };
        const ShowModalFilter = () => {
            if (refModalFilter.current) {
                refModalFilter.current.showModal();
            }
        };
        //const dispatch = useDispatch();
        const history = useHistory();
        const handelLogout = () => {
            showConfirm({
                title: t('Confirm'),
                message: t('Are_you_sure'),
            }).then((value) => {
                if (value) {
                    history.replace('/logout');
                    // setLoading(true);
                    // apis.logout()
                    //     .then(async () => {
                    //         setIsLoggedIn(false);
                    //         setUserProfile(undefined);
                    //         resetGlobalHeaders();
                    //         await setStorageOnlyData();
                    //         deleteAllStats && deleteAllStats();
                    //     })
                    //     .catch((e) => {
                    //         setIsLoggedIn(false);
                    //         setUserProfile(undefined);
                    //         resetGlobalHeaders();
                    //         setStorageOnlyData();
                    //         try {
                    //             deleteAllStats && deleteAllStats();
                    //         } catch (e) {
                    //             console.log(e);
                    //         }
                    //     })
                    //     .finally(() => {
                    //         setLoading(false);
                    //     });
                }
            });
        };
        return (
            <IonHeader color="none" className="header-main" mode="md">
                <IonToolbar color="none">
                    <IonTitle>
                        <h2 className="title-page">{title}</h2>
                        <p className="sub-title-page">
                            {isLoggedin && isAuth
                                ? t('Welcome') +
                                  ' ' +
                                  getGlobalHeaders().name_user +
                                  ' !'
                                : subTitle}
                        </p>
                    </IonTitle>
                    {logout ? (
                        isLoggedin ? (
                            !loading ? (
                                <ButtonsIcon
                                    onClick={handelLogout}
                                    slot="end"
                                    color="pink"
                                    icon={powerSharp}
                                />
                            ) : (
                                <IonSpinner color="pink" slot="end" />
                            )
                        ) : (
                            <ButtonsIcon
                                onClick={() => history.push('/login')}
                                slot="end"
                                color="pink"
                                icon={logInOutline}
                            />
                        )
                    ) : (
                        <>
                            <ButtonsIcon
                                onClick={showModal}
                                slot="end"
                                icon={searchOutline}
                            />
                            <ButtonsIcon
                                onClick={ShowModalFilter}
                                slot="end"
                                icon={optionsOutline}
                            />
                        </>
                    )}
                </IonToolbar>
                <Modal
                    lang={lang}
                    history={history}
                    Body={BodySearchProduct}
                    ref={refModal}
                />
                <Modal
                    lang={lang}
                    history={history}
                    filter={filter}
                    // dispatch={dispatch}
                    Body={BodyFilterProducts}
                    ref={refModalFilter}
                />
            </IonHeader>
        );
    }
);
