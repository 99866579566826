import { IonButton, IonContent, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import Button from "../../../components/button";
import { FooterPage } from "../../footer-page";
import { IPropsConnection } from "./props";

export const ConnectionLost: React.FC<IPropsConnection> = ({
  loading,
  onReloadRequset,
}) => {
  const { t } = useTranslation("common");
  return (
    <>
      <IonContent className="content-color">
        <div className="order-placed">
          <ReactSVG
            wrapper="span"
            className="wrapper-class-name"
            evalScripts="always"
            beforeInjection={(svg) => {
              svg.classList.add("svg-class-name");
              svg.setAttribute("style", "width: 200px");
            }}
            src={process.env.PUBLIC_URL + "/assets/svg/internet-connection.svg"}
          />
          {/*             <ReactSVG  className="img" src={process.env.PUBLIC_URL + "/assets/svg/internet-connection.svg"} />
           */}{" "}
          <div className="title">
            <IonText className="text">{t("Connection_Lost")}</IonText>
          </div>
          <div className="subtitle">
            <IonText className="text">
              {t("Connection_Lost_Long")}
            </IonText>
          </div>
        </div>
      </IonContent>
      <FooterPage>
        <Button
          onClick={() => onReloadRequset()}
          loading={loading}
          expand="full"
          background="#6A7EC4"
          color="none"
          text={t("TRY_AGAIN")}
        ></Button>
      </FooterPage>
    </>
  );
};
