import {
    IonContent,
    IonPage,
    IonText,
    useIonViewDidEnter,
    useIonViewWillEnter,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Refresher } from '../../../components/refresher';
import { FavoriteEmpty } from '../../../layout/errors/favorite-empty';
import { LoginRequired } from '../../../layout/errors/login-requerd';
import { HeaderMain } from '../../../layout/Header/header-main';
import { LoadingCenter } from '../../../layout/loading-center';
import { connect } from '../../../redux/connect';
import {
    deleteAllFromWishlistDispatch,
    deleteFromWishlistDispatch,
} from '../../../redux/favorite/favorite.actions';
import { decreaseToStatsWhishlistPage } from '../../../redux/stats/stats.actions';
import { loadUserData } from '../../../redux/user/user.actions';
import apis from '../../../services';
import ListItemWishlist from './component/list-item-wishlist';
import './style.scss';
interface DispatchProps {
    decreaseToStatsWhishlistPage: typeof decreaseToStatsWhishlistPage;
    deleteFromWishlistDispatch: typeof deleteFromWishlistDispatch;
    deleteAllFromWishlistDispatch: typeof deleteAllFromWishlistDispatch;
}
const Wishlist: React.FC<any> = ({
    wishlistLocalStorage,
    localLogin,
    decreaseToStatsWhishlistPage,
    deleteFromWishlistDispatch,
    deleteAllFromWishlistDispatch,
}) => {
    const { t } = useTranslation('common');

    const [wishlist, setWishlist] = useState<any>({
        data: [],
        loading: true,
        errorMessage: null,
    });
    // useSelector(
    //     (state: any) => state?.wishlistData
    // );
    const history = useHistory();

    const isLogin = localLogin;
    const [state, setState] = useState(0);
    const getData = () => {
        apis.getWishlistProducts()
            .then((respone) => {
                setWishlist({
                    data: respone.data.data,
                    loading: false,
                    errorMessage: null,
                });
            })
            .catch((eror) => {
                setWishlist({ loading: false, errorMessage: 'Error Request' });
            });
    };
    useIonViewDidEnter(() => {
        setState(1);

        let idStorage = [];
        if (wishlistLocalStorage) {
            for (let i = 0; i < wishlistLocalStorage.length; i++) {
                idStorage.push(wishlistLocalStorage[i].id);
            }
        }
        if (isLogin) {
            try {
                if (wishlistLocalStorage && idStorage.length > 0) {
                    apis.addWishlistProducts({ product_ids: idStorage }).then(
                        () => {
                            deleteAllFromWishlistDispatch();
                        }
                    );
                }
                getData();
            } catch (e) {}
        } else {
        }
    });
    useEffect(() => {
        getData();
    }, []);

    var isEmpty = true;
    const onClickToredirict = (event: any, slug: string) => {
        event.preventDefault();
        history.push(`/page/product/${slug}`);
    };
    return (
        <IonPage className="wishlist">
            <HeaderMain
                title={t('Favorite_List')}
                subTitle={t('Your_list_Guest')}
            />
            {isLogin &&
            wishlist.loading == false &&
            wishlist.data &&
            wishlist.data.length == 0 ? (
                <FavoriteEmpty />
            ) : (
                <IonContent className="content-color">
                    <Refresher Refresh={getData} />
                    {wishlist.loading == false && wishlist.data ? (
                        <div className="title-padding">
                            <IonText className="ion-padding title ">
                                {t('You_have__product_in_your_favorite_list', {
                                    counts: `${wishlist.data?.length ?? 0}`,
                                })}
                            </IonText>
                        </div>
                    ) : (
                        ''
                    )}
                    {isLogin == true ? (
                        wishlist.loading == false ? (
                            wishlist.errorMessage == null ? (
                                wishlist.data?.map(
                                    (data: any, index: number) => {
                                        return (
                                            <ListItemWishlist
                                                onClick={(e: any) =>
                                                    onClickToredirict(
                                                        e,
                                                        data.slug
                                                    )
                                                }
                                                decreaseToStatsWishlist={
                                                    decreaseToStatsWhishlistPage
                                                }
                                                deleteFromWishlistDispatch={
                                                    deleteFromWishlistDispatch
                                                }
                                                isLogin={isLogin}
                                                key={index}
                                                slug={data.slug}
                                                refresh={getData}
                                                productId={data.id}
                                                imgPath={data.base_image.path}
                                                category={
                                                    data.categories?.length > 0
                                                        ? data.categories[0]
                                                              .name
                                                        : 'cloth'
                                                }
                                                price={
                                                    data.selling_price.formatted
                                                }
                                                name={data.name}
                                            />
                                        );
                                    }
                                )
                            ) : (
                                <></>
                            )
                        ) : (
                            <LoadingCenter />
                        )
                    ) : wishlistLocalStorage &&
                      wishlistLocalStorage.length > 0 ? (
                        wishlistLocalStorage.map((data: any, index: number) => {
                            return (
                                <ListItemWishlist
                                    key={index}
                                    slug={data.slug}
                                    data={data}
                                    decreaseToStatsWishlist={
                                        decreaseToStatsWhishlistPage
                                    }
                                    deleteFromWishlistDispatch={
                                        deleteFromWishlistDispatch
                                    }
                                    isLogin={isLogin}
                                    refresh={getData}
                                    productId={data.id}
                                    imgPath={data.base_image.path}
                                    category={
                                        data.categories?.length > 0
                                            ? data.categories[0].name
                                            : 'cloth'
                                    }
                                    price={data.selling_price.formatted}
                                    name={data.name}
                                />
                            );
                        })
                    ) : (
                        <FavoriteEmpty />
                    )}
                </IonContent>
            )}
        </IonPage>
    );
};

export default connect<{}, any, DispatchProps>({
    mapStateToProps: (state) => ({
        darkMode: state.user.darkMode,
        userProfile: state.user.userProfile,
        isAuthenticated: state.user.isLoggedin,
        wishlistLocalStorage: state.wishlistData,
        localLogin:
            state.user.isLoggedin &&
            state.user.userProfile != null &&
            state.user.userProfile != undefined &&
            state.user.userProfile.localLogin == true,
    }),
    mapDispatchToProps: {
        decreaseToStatsWhishlistPage,
        deleteFromWishlistDispatch,
        deleteAllFromWishlistDispatch,
    },
    component: React.memo(Wishlist),
});
