import { v4 as uuid } from 'uuid';
import {  setCardProductsData } from '../../storage/management-Storage';
import {
    ADD_TO_CART,
    DECREASE_QUANTITY,
    DELETE_ALL_FROM_CART,
    DELETE_FROM_CART,
    INCREASE_QUANTITY,
    LOAD_DATA_CART,
} from './cart.actions';

const initState: any =[]

const cartReducer = (state = initState, action: any) => {
    
    const cartItems = state,
        product = action.payload;
if(action.type===LOAD_DATA_CART){

    state=product
return state
}
    if (action.type === ADD_TO_CART) {
    
        if (true) {
            const cartItem = cartItems.filter(
                (item: any) => item.id === product.id
            )[0];
            if (cartItem === undefined) {
                setCardProductsData([
                    ...cartItems,
                    {
                        ...product,
                        quantity: product.quantity ? product.quantity : 1,
                        cartItemId: uuid(),
                    },
                ]);
              
                return [
                    ...cartItems,
                    {
                        ...product,
                        quantity: product.quantity ? product.quantity : 1,
                        cartItemId: uuid(),
                    },
                ];
            } else {
      
                setCardProductsData(cartItems.map((item: any) =>
                item.id === cartItem.id
                    ? {
                          ...item,
                          quantity: product.quantity
                              ? item.quantity + product.quantity
                              : item.quantity + 1,
                      }
                    : item
            ))
                return cartItems.map((item: any) =>
                    item.variant_id === cartItem.variant_id
                        ? {
                              ...item,
                              quantity: product.quantity
                                  ? item.quantity + product.quantity
                                  : item.quantity + 1,
                          }
                        : item
                );
            }
            // for variant products
        } else {
            const cartItem = cartItems.filter(
                (item: any) =>
                    item.variant_id === product.variant_id &&
                    product.selectedProductColor &&
                    product.selectedProductColor ===
                        item.selectedProductColor &&
                    (product.cartItemId
                        ? product.cartItemId === item.cartItemId
                        : true)
            )[0];

            if (cartItem === undefined) {
                return [
                    ...cartItems,
                    {
                        ...product,
                        quantity: product.quantity ? product.quantity : 1,
                        cartItemId: uuid(),
                    },
                ];
            } else if (
                cartItem !== undefined &&
                cartItem.selectedProductColor !== product.selectedProductColor
            ) {
                return [
                    ...cartItems,
                    {
                        ...product,
                        quantity: product.quantity ? product.quantity : 1,
                        cartItemId: uuid(),
                    },
                ];
            } else {
                return cartItems.map((item: any) =>
                    item.cartItemId === cartItem.cartItemId
                        ? {
                              ...item,
                              quantity: product.quantity
                                  ? item.quantity + product.quantity
                                  : item.quantity + 1,
                              selectedProductColor:
                                  product.selectedProductColor,
                          }
                        : item
                );
            }
        }
    }

    if (action.type === DECREASE_QUANTITY) {
        if (product.quantity === 1) {
            const remainingItems = (cartItems: any, product: any) =>
                cartItems.filter(
                    (cartItem: any) =>
                        cartItem.cartItemId !== product.cartItemId
                );
            setCardProductsData(remainingItems(cartItems, product));
            return remainingItems(cartItems, product);
        } else {
            setCardProductsData(
                cartItems.map((item: any) =>
                    item.cartItemId === product.cartItemId
                        ? { ...item, quantity: item.quantity - 1 }
                        : item
                )
            );
            return cartItems.map((item: any) =>
                item.cartItemId === product.cartItemId
                    ? { ...item, quantity: item.quantity - 1 }
                    : item
            );
        }
    }
    if (action.type === INCREASE_QUANTITY) {
        setCardProductsData(
            cartItems.map((item: any) =>
                item.cartItemId === product.cartItemId
                    ? { ...item, quantity: item.quantity + 1 }
                    : item
            )
        );
        return cartItems.map((item: any) =>
            item.cartItemId === product.cartItemId
                ? { ...item, quantity: item.quantity + 1 }
                : item
        );
    }
    if (action.type === DELETE_FROM_CART) {
        const remainingItems = (cartItems: any, product: any) =>
            cartItems.filter(
                (cartItem: any) => cartItem.cartItemId !== product.cartItemId
            );
        setCardProductsData(remainingItems(cartItems, product));
        return remainingItems(cartItems, product);
    }

    if (action.type === DELETE_ALL_FROM_CART) {

        setCardProductsData(cartItems.filter((item: any) => {
            return false;
        }));
        return cartItems.filter((item: any) => {
            return false;
        });
    }
  // setCardProductsData(state);

    return state;
};

export default cartReducer;
