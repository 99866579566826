import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonSpinner,
    IonText,
    IonTitle,
    IonToolbar,
    useIonToast,
} from '@ionic/react';
import { arrowBack, arrowForward } from 'ionicons/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ReactSVG } from 'react-svg';
import { Toast } from '../../components/toast';
import { HeaderSubPage } from '../../layout/Header/header-sub-page';
import { resetGlobalHeaders } from '../../modules/global-data';
import { connect } from '../../redux/connect';
import { deleteAllStats } from '../../redux/stats/stats.actions';
import { setIsLoggedIn, setUserProfile } from '../../redux/user/user.actions';
import apis from '../../services';
import { setStorageOnlyData } from '../../storage/management-Storage';
import './style.scss';
import { VerificationComponent } from './verification-component';
interface DispatchProps {}
const VerificationPage: React.FC<any> = ({
    userProfile,
    setUserProfile,
    setIsLoggedIn,
    deleteAllStats,
}) => {
    const { t } = useTranslation('common');
    const history = useHistory();
    const [verifiocationCode, setVerifiocationCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [present, dismiss] = useIonToast();

  
    const backHome = async () => {
        setUserProfile();
        resetGlobalHeaders();

        await setStorageOnlyData();
        deleteAllStats();
        setIsLoggedIn(false);
        history.replace('/login', { preventLastLocation: true });
    };
    return (
        <IonPage>
            <IonHeader color="none" className="header-sub">
                <IonToolbar color="none">
                    <IonTitle className="title">{t('Verification')}</IonTitle>
                    <IonButtons
                        onClick={() => backHome()}
                        className="back-buttom"
                        slot="start"
                    >
                        <IonButton>
                            <IonIcon
                                slot="icon-only"
                                icon={arrowBack}
                            ></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

           <VerificationComponent userProfile={userProfile} />
        </IonPage>
    );
};

export default connect<{}, any, DispatchProps>({
    mapStateToProps: (state) => ({
        darkMode: state.user.darkMode,

        isAuthenticated: state.user.isLoggedin,
        localLogin:
            state.user.isLoggedin &&
            state.user.userProfile != null &&
            state.user.userProfile != undefined &&
            state.user.userProfile.localLogin == true,
        cartDataLocalStorage: state.cartData,
        userProfile:
            state.user.isLoggedin &&
            state.user.userProfile != null &&
            state.user.userProfile != undefined &&
            state.user?.userProfile,
    }),
    mapDispatchToProps: {
        setUserProfile,
        setIsLoggedIn,
        deleteAllStats,
    },
    component: React.memo(VerificationPage),
});
