import { Network } from '@capacitor/network';
import { useCallback, useMemo, useState } from 'react';

export function useInternetState<T>() {
    let state=false
    const [internet, setInterNet] = useState<boolean>(true)
    Network.addListener('networkStatusChange', status => {
        state=status.connected
      
    });
    const logCurrentNetworkStatus = useCallback(async () => {
        const status = await Network.getStatus();

        setInterNet(status.connected)
    }, [state,setInterNet]);
    useMemo(() => {
        return logCurrentNetworkStatus()
    }, [internet])

    return {
        internet
    }

}