import { ConfActions } from "./config.actions";
import { IConfig } from "./config.interface";
const initializing={
    loading: false,
    menuEnabled: true,
}
export const configReducer = (state: IConfig=initializing, action: ConfActions): IConfig => {
    switch (action.type) {
      case 'set-conf-loading': {
        return { ...state, loading: action.isLoading };
      }
      case 'set-conf-data': {
        return { ...state, ...action.data };
      }
      case 'set-menu-enabled': {
        return { ...state, menuEnabled: action.menuEnabled };
     
      }
      default:
         
        return state
    }
  }