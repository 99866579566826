import { RefresherEventDetail } from "@ionic/core";
import { IonRefresher, IonRefresherContent } from "@ionic/react";

export const Refresher: React.FC<{ Refresh: () => void }> = ({ Refresh }) => {
  async function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    console.log("Begin async operation");
    try {
      Refresh();
      setTimeout(() => {
        event.detail.complete();
      }, 2000);
    } catch (e) {
      event.detail.complete();
    }
  }
  return (
    <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
      <IonRefresherContent></IonRefresherContent>
    </IonRefresher>
  );
};
