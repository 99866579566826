import ReactStars from 'react-rating-stars-component';
export const RankStart = ({ count, onChange, size, value }) => {
    return (
        <ReactStars
            value={value}
            count={count ?? 5}
            onChange={onChange && onChange}
            size={size ?? 25}
            activeColor="#ffd700"
        />
    );
};
