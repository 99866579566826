import { IonCard, IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import CardItemBig from './model/card-item-big';
import { IPropsRecentlyArrived } from './props.recently-arrived';
import './style.scss';
const RecentlyArrived: React.FC<IPropsRecentlyArrived> = ({
    item,
    lang,
    ...props
}) => {
    /* const item=[{
        name:"Black Dress",
        img:"https://quotess.cc/wp-content/uploads/2018/06/3784-2.jpg",
        price:40.00,
        is_dis:0,
        price_discount:40,
        category:"Abaya",
        is_wishlist:false
    
    },
    {
        name:"Black Dress",
        img:"https://www.alrakia.com/wp-content/uploads/2017/07/%D9%85%D8%AD%D8%AC%D8%A8%D8%A7%D8%AA_%D8%AA%D8%B1%D9%83%D9%8A.jpg",
        price:80.00,
        is_dis:1,
        price_discount:20.00,
        category:"Abaya",
        is_wishlist:false
    
    },
    {
        name:"Black Dress",
        img:"https://www.alrakia.com/wp-content/uploads/2017/07/turkish-hijab-styles.jpg",
        price:150.00,
        is_dis:1,
        price_discount:100.00,
        category:"Abaya",
        is_wishlist:false
    
    },
    {
        name:"Black Dress",
        img:"https://www.alrakia.com/wp-content/uploads/2019/10/ATE18-New-Arrival-Elegant-Abaya-Kaftan-Gold.jpg",
        price:240.00,
        is_dis:1,
        price_discount:120.00,
        category:"Abaya",
        is_wishlist:false
    
    }] */

    return (
        <IonGrid className="item-vertical ion-padding">
            <IonRow>
                {item &&
                    item.map((data, index: number) => {
                        return (
                            <IonCol {...props} key={index}>
                                <CardItemBig
                                    lang={lang}
                                    slug={data.slug}
                                    category={data.categories[0]?.name}
                                    img={data.base_image.path}
                                    name={data.name}
                                    price={data.price.formatted}
                                    discount_price={
                                        data.selling_price?.formatted ?? '0'
                                    }
                                    is_discount={
                                        data.special_price == null ? 0 : 1
                                    }
                                />
                            </IonCol>
                        );
                    })}
            </IonRow>
        </IonGrid>
    );
};
export default React.memo(RecentlyArrived);
