import { IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonToolbar } from "@ionic/react"
import './style.scss'
import { arrowBack, arrowForward, bookOutline,arrowBackSharp } from 'ionicons/icons'
import { useRef } from "react"
import { useHistory } from "react-router"
import { IPropsHeaderStarted } from "./props.header"

export const HeaderStarted:React.FC<IPropsHeaderStarted>=({link})=>{
  const history = useHistory();
  
  const clickedEditButton = () => {
    // preventLastLocation is important here
    history.replace(link, {preventLastLocation: true});
  };

    return(<>
   
    <div className="header-started">
       <IonButtons onClick={()=>clickedEditButton()  }> <IonButton fill="clear" className="button-back"><IonIcon icon={arrowBackSharp}/></IonButton>
       </IonButtons>  <div className="img-center">
        <img src={process.env.PUBLIC_URL+"/assets/svg/logo-sm.svg"}/>
      </div>
   
        </div>
    </>)
}
