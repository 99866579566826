import { IonItem, IonLabel, IonText } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IPropsItemChange } from './props.item-change';
import './style.scss';
export const ItemChange: React.FC<IPropsItemChange> = React.memo(
    ({ title, descripton, routerLink }) => {
        const { t } = useTranslation('common');

        return (
            <IonItem
                routerLink={routerLink && routerLink}
                button
                detailIcon={'mode'}
                className="item-change"
                color="none"
                lines="none"
            >
                <IonLabel slot="start">
                    <h1 style={{ whiteSpace: 'initial' }}>{title}</h1>

                    <p>{descripton}</p>
                </IonLabel>

                <IonText color="pink" className="change-text" slot="end">
                    {t('Change')}
                </IonText>
            </IonItem>
        );
    }
);
