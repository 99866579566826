import axios from 'axios';
import { axiosApi } from '.';

const getCategories = () => {
    return axiosApi.get('/categories');
};
const getCategorieDetails = (category: string) => {
    return axiosApi.get(`/categories/${category}`);
};

const getProductsByCategory = (category: string, page?: string) => {
    return axiosApi.get(`/categories/${category}/products${page ? page : ''}`);
};
const categorysApis = {
    getCategories,
    getCategorieDetails,
    getProductsByCategory,
};
export default categorysApis;
