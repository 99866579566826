import {
    IonButton,
    IonButtons,
    IonIcon,
    IonItem,
    useIonToast,
} from '@ionic/react';
import { sendOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonsIcon } from '../../../../../../../components/buttons-icon';
import { Toast } from '../../../../../../../components/toast';
import apis from '../../../../../../../services';
import { IPropsInputComment } from './props.input-comment';
import './style.scss';
export const InputComment: React.FC<IPropsInputComment> = ({
    idProduct,
    refresh,
}) => {
    const { t } = useTranslation('common');
    const [present] = useIonToast();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const [loading, setLoading] = useState(false);

    const onSubmitComment = (data: { body: string }) => {
        setLoading(true);
        apis.addCommentToProduct(idProduct, data)
            .then(() => {
                refresh && refresh();
                Toast(present, t('Success'), 1400, 'sucsess');
            })
            .catch(() => {
                Toast(present, t('Error_Occurred'), 1400, 'error');
            })
            .finally(() => {
                setLoading(false);
                reset();
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmitComment)}>
            <IonItem color="none" lines="none" className="footer-input">
                <input
                    {...register('body', { required: true })}
                    className="input"
                    placeholder={t('Write_your_comment_here')}
                />
                <IonButtons>
                    <IonButton
                        onClick={handleSubmit(onSubmitComment)}
                        disabled={loading}
                    >
                        <IonIcon
                            className="icon-color"
                            icon={sendOutline}
                        ></IonIcon>
                    </IonButton>
                </IonButtons>
            </IonItem>
        </form>
    );
};
