import { IonLabel } from "@ionic/react"
import { IPropsHeaderContent } from "./props.content-header"
import './style.scss'
export const HeaderContent:React.FC<IPropsHeaderContent>=({title,content})=>{


return(<div className="header-conent">

   <IonLabel className="header-lable">
      <h1>{title}</h1>
      <p>{content}</p>
    </IonLabel>
</div>)
}
