import { IonItem, IonLabel, IonToggle } from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPropsItemToggle } from './props.item-toggle';
import './style.scss';
export const ItemToggle: React.FC<IPropsItemToggle> = React.memo(
    ({ description, isCheck }) => {
        const [Checked, setChecked] = useState(isCheck);
        const { t } = useTranslation("common");

        return (
            <IonItem color="none" lines="none" className="item-toggle">
                <IonLabel>
                    <h2>{Checked ? t("Enabled") : t("Disable")}</h2>
                    <p>{description}</p>
                </IonLabel>
                <IonToggle
                    mode="md"
                    checked={Checked}
                    onIonChange={(e) => setChecked(e.detail.checked)}
                />
            </IonItem>
        );
    }
);
