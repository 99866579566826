import { ToastOptions, useIonToast, UseIonToastResult } from "@ionic/react";
import { HookOverlayOptions } from "@ionic/react/dist/types/hooks/HookOverlayOptions";

export function Toast<T>(
  present: any,
  message: string,
  duration?: number | 2000,
  type?: any | "sucsess"
) {
  present({
    duration: duration ?? 2000,
    message: message,
    /*         onDidDismiss: () => console.log('dismissed'),
                  onWillDismiss: () => console.log('will dismiss'), */
    /*     translucent:true, */

    cssClass: `toast ${type ?? " sucsess"}`,
  });
}
