import { Share as ShareNative } from '@capacitor/share';
export async function Share(
    title: string,
    text: string,
    url: string,
    dialogTitle?: string
) {
    await ShareNative.share({
        title: title,
        text: text && text,
        url: url,
        dialogTitle: dialogTitle && dialogTitle,
    });
}
