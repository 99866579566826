import {
    IonContent,
    IonFooter,
    IonModal,
    IonPage,
    IonToolbar,
    isPlatform,
    useIonToast,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import ItemInput from '../../components/item-input';
import Button from '../../components/button';
import { HeaderStarted } from '../../layout/Header/header-started.tsx';
import { HeaderContent } from '../../components/content-header';
import { ButtonLableIcon } from '../../components/button-lable-icon';
import { FooterStarted } from '../../layout/footer-started';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import './style.login.scss';
import { useForm } from 'react-hook-form';
import apis from '../../services';
import { Toast } from '../../components/toast';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoggedIn, setUserProfile } from '../../redux/user/user.actions';
import { setStorageOnlyData } from '../../storage/management-Storage';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { connect } from '../../redux/connect';
import { VerificationComponent } from '../verification/verification-component';
interface DispatchProps {
    setUserProfile: typeof setUserProfile;
    setIsLoggedIn: typeof setIsLoggedIn;
}
const Login: React.FC<any> = ({
    setUserProfile,
    setIsLoggedIn,
    userProfile,
}) => {
    const { t } = useTranslation('common');
    const [showModal, setShowModal] = useState(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [present, dismiss] = useIonToast();
    const [showKeyBorad, setShowKeyBorad] = useState(true);
    useEffect(() => {
        if (Capacitor.isPluginAvailable('Keyboard')) {
            try {
                if (isPlatform('ios') || isPlatform('android')) {
                    Keyboard.addListener('keyboardWillShow', (info) => {
                        setShowKeyBorad(false);
                    });
                    Keyboard.addListener('keyboardWillHide', () => {
                        setShowKeyBorad(true);
                    });
                }
            } catch (e) {
                console.log('sdfsdf');
            }
        }
    }, [Keyboard]);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        shouldFocusError: true,
        criteriaMode: 'all',
        /*  mode:"onChange" */
    });

    const history = useHistory();
    const onSubmit = (data: { username: string; password: string }) => {
        setLoading(true);
        apis.loginUser({
            login_id: data.username,
            password: data.password,
            remember_me: true,
        })
            .then((response) => {
                try {
                    localStorage.setItem('token', response.data.auth.token);
                    localStorage.setItem('user_id', response.data.user.id);
                    localStorage.setItem(
                        'fullname',
                        response.data.user.first_name +
                            ' ' +
                            response.data.user.last_name
                    );

                    setUserProfile({
                        main_phone: response.data.user.phone,
                        email: response.data.user.email,
                        fullName:
                            response.data.user.first_name +
                            ' ' +
                            response.data.user.last_name,
                        avatar: response.data.user.picutre?.path,
                        localLogin: true,
                        user_id: response.data.user.id,
                        password: data.password,
                        phone_verified: response.data.user.phone_verified,
                        social_id:response.data.user.social_id
                    });

                    setIsLoggedIn(true);
                    setStorageOnlyData({
                        access_token: response.data.auth.token,
                        refresh_token: 'data.refresh_token',
                        user_id: response.data.user.id,
                        type_user: 'user',
                    });
                } catch (e:any) {
                    console.log(e);
                    Toast(
                        present,
                        e?.message ?? "",
                        1400,
                        'error'
                    );
                }
                // setTimeout(() => {
                //     window.location.reload();
                // }, 100);
                //    history.replace('/tab/home', { preventLastLocation: true });
            })
            .catch((error) => {
                // alert(error.response?.data?.message);
                if (error.message === 'Request failed with status code 401') {
                    setUserProfile({ localLogin: false });
                    //alert('data.username' + data.username);
                    //setIsLoggedIn(true);
                    localStorage.setItem('verify_username', data.username);
                    // history.replace(`/verification-account`, {
                    //     preventLastLocation: true,
                    // });
                    setShowModal(true);
                }

                Toast(
                    present,
                    error.response?.data?.message ?? t('Error_In_Connection'),
                    1400,
                    'error'
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const onDismiss = (goback: boolean) => {
        setShowModal(false);
        if (goback) {
            history.goBack();
        }
    };
    return (
        <IonPage id="login">
            <IonContent className="content-color ion-padding" fullscreen>
                <HeaderStarted link="/" />
                <HeaderContent
                    content={t('Fill_the_following_data_start_shopping')}
                    title={t('Welcome_Back')}
                />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ItemInput
                        required={true}
                        register={register('username', { required: true })}
                        error={errors && errors.username ? true : false}
                        lines="none"
                        lable={t('Phone_Number')}
                    />

                    <ItemInput
                        required={true}
                        register={register('password', { required: true })}
                        error={errors && errors.password ? true : false}
                        type="password"
                        lines="none"
                        lable={t('Password')}
                    />
                    <ButtonLableIcon
                        content={t('Forgot_Password_')}
                        routerLink="/forgot-password"
                    />
                    <Button
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                        height={'48px'}
                        color="none"
                        text={t('SIGN_IN')}
                        expand="full"
                    />
                    <br />
                    <Button
                        loading={loading}
                        routerLink="/sign-up"
                        height={'48px'}
                        fill="clear"
                        color="none"
                        text={t('SIGN_UP_')}
                        expand="full"
                    />
                </form>
                {showKeyBorad ? (
                    <IonFooter color="none">
                        <IonToolbar color="none">
                            <FooterStarted
                                setUserProfile={setUserProfile}
                                setIsLoggedIn={setIsLoggedIn}
                                setShowModal={setShowModal}
                                title={t('Or_sign_up_with_social_account')}
                            />
                        </IonToolbar>
                    </IonFooter>
                ) : (
                    ''
                )}
            </IonContent>
            <IonModal
                isOpen={showModal}
                backdropDismiss={false}
                keyboardClose={false}
                cssClass="my-custom-class"
            >
                <VerificationComponent
                    userProfile={userProfile}
                    onDismiss={onDismiss}
                    setUserProfile={setUserProfile}
                    setIsLoggedIn={setIsLoggedIn}
                />
            </IonModal>
        </IonPage>
    );
};
export default connect<{}, any, DispatchProps>({
    mapStateToProps: (state) => ({
        darkMode: state.user.darkMode,
        userProfile: state.user.userProfile,
        isAuthenticated: state.user.isLoggedin,
        localLogin:
            state.user.isLoggedin &&
            state.user.userProfile != null &&
            state.user.userProfile != undefined &&
            state.user.userProfile.localLogin == true,
    }),
    mapDispatchToProps: {
        setUserProfile,
        setIsLoggedIn,
    },
    component: Login,
});
