import { Dialog } from "@capacitor/dialog";

export async function showConfirm({
  title = "Confirm",
  message = "`Are you sure you'd like to press the red button?`",
}: {
  title: string;
  message: string;
}) {
  const { value } = await Dialog.confirm({
    title: title,
    message: message,
  });
  return value;
}
