import { Storage } from '@capacitor/storage';

import { IGlobalData } from '../storage/models/global-Data';
import { IStorageOnly } from '../storage/models/storage-only';
import { IUserProfile } from '../storage/models/user-profile';

let userProfile: IUserProfile = {};
let globalData: IGlobalData = {};
let lang = 'ar';
/* export const setGlobalHeaders = (_globalHeader?: GlobalHeader) => {
 
    ("GlobalHeader:(setGlobalHeaders):" + JSON.stringify(_globalHeader) );
  globalHeader = _globalHeader;
}; */
export const GET_IMAGE_BY_SIZE = (image: string,size: string) => {
    image = image.replace(/(.*)\/(.*)(\..*$)/i, '$1/$2_' + size + '$3')
    return image;
};

export async function getGlobalInfoUser() {
    const { value } = await Storage.get({ key: 'storageOnly' });
    console.log('Got item: ', value);
    return value;
}
export async function IsLoginUser() {
    const { value } = await Storage.get({ key: 'hasLoggedIn' });
    console.log('Got item: ', value);

    return value;
}

export function getIsLoginUser() {}

export const setStorageHeader = (userProfilee?: IUserProfile) => {
    userProfile.user_id = userProfilee?.user_id;
    userProfile.email = userProfilee?.email;
    userProfile.type_user = userProfilee?.type_user;
    userProfile.fullName = userProfilee?.fullName;
    userProfile.main_phone = userProfilee?.main_phone;
    userProfile.avatar = userProfilee?.avatar;
    userProfile.addresses_count = userProfilee?.addresses_count;
    userProfile.order_count = userProfilee?.order_count;
    userProfile.reviews_count = userProfilee?.reviews_count;
    userProfile.password = userProfilee?.password;
    userProfile.phone_verified = userProfilee?.phone_verified;
};

export const setStorageOnlyGlobalHeaders = (storageOnly?: IStorageOnly) => {
    globalData.access_token = storageOnly?.access_token;
    globalData.refresh_token = storageOnly?.refresh_token;
    globalData.user_id = storageOnly?.user_id;
    globalData.type_user = storageOnly?.type_user;
};

export const setCompanyGlobalHeaders = (company_id: string) => {
    globalData.company_id = company_id;
};

export const setUserFullNameGlobalHeaders = (name_user: string) => {
    globalData.name_user = name_user;
};
export const setUserGlobalHeaders = (
    user_id?: string,
    type_user?: string,
    name_user?: string,
    avatar?: string,
    main_phone?: string,
    reviews_count?: number,
    order_count?: number,
    addresses_count?: number,
    passowrd?: string,
    phone_verified?: string
) => {
    globalData.user_id = user_id;
    globalData.type_user = type_user;
    globalData.name_user = name_user;
    globalData.avatar = avatar;
    globalData.main_phone = main_phone;
    globalData.order_count = order_count;
    globalData.addresses_count = addresses_count;
    globalData.reviews_count = reviews_count;
    globalData.password = passowrd;
    globalData.phone_verified = phone_verified;
};
export const setLangTagGlobalHeaders = (lang_tag: string) => {
    globalData.lang_tag = lang_tag;
};
export const getGlobalHeaders = () => {
    return globalData;
};

export const resetGlobalHeaders = () => {
    globalData.access_token = undefined;
    globalData.refresh_token = undefined;
    globalData.company_id = undefined;
    globalData.user_id = undefined;
    globalData.account_id = undefined;
    globalData.avatar = undefined;
    globalData.name_user = undefined;
    globalData.type_user = undefined;
    globalData.main_phone = undefined;
    globalData.order_count = undefined;
    globalData.addresses_count = undefined;
    globalData.reviews_count = undefined;
    globalData.password = undefined;
    globalData.phone_verified = undefined;
};
export const ChangeLang = (langBack: string) => {
    lang = langBack;
};
export const getLangTag = () => {
    /*    const lang: any = localStorage.getItem('CapacitorStorage.app-settings');
    if (lang) {
        return JSON.parse(lang)?.lang_tag;
    }
    return 'ar'; */
    return lang;
};
/* export getL */
