import {
    IonButton,
    IonButtons,
    IonIcon,
    IonItem,
    IonLabel,
    IonThumbnail,
} from '@ionic/react';
import { heartSharp, trashOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CURRENCY_LI } from '../../../../helpers/currency';
import { ColorListPiker } from '../../../product/component/color-list-piker';
import { SizeChip } from '../../../product/component/size-chip';

import { IPropsItemCheckout } from './props.item-checkout';

import './style.scss';
export const ItemCheckout: React.FC<IPropsItemCheckout> = ({
    imgPath,
    price,
    quantity,
    name,
    options,
}) => {
    return (
        <IonItem lines="none" color="NONE" className="item-checkout">
            <IonThumbnail slot="start" className="thumbnail">
                <img src={imgPath} />
            </IonThumbnail>
            <IonLabel>
                <h2 className="title">{name}</h2>
                <p className="price">{price}</p>
                <p> </p>
                <p className="category">
                    {quantity} item x {price}
                </p>
            </IonLabel>
            <div slot="end">
                {options &&
                    options.map((data: any, index: number) => {
                        return data.name == 'color' ? (
                            <ColorListPiker
                                key={index}
                                color={data.values[0]?.label}
                            />
                        ) : (
                            data.name == 'size' && (
                                <SizeChip
                                    key={index}
                                    name={data.values[0]?.label}
                                    state="active"
                                ></SizeChip>
                            )
                        );
                    })}
            </div>
            {/*   <IonButtons slot="end">
        <IonButton style={{ color: "#bfbebe" }}>
          <IonIcon slot="icon-only" icon={trashOutline}></IonIcon>
        </IonButton>
      </IonButtons> */}
        </IonItem>
    );
};
