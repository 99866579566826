import { IpropsSlideContent } from "./props.slide-content"
import './style.slide-content.scss'
export const SideContent:React.FC<IpropsSlideContent>=({title,content})=>{

return(<div id="content" style={{width:"100%"}}>
<h1 >{title}</h1>
<p>{content}</p>
</div>)

}
