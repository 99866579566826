import {
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonThumbnail,
    useIonLoading,
} from '@ionic/react';
import React, { useState } from 'react';
import { ColorListPiker } from '../../../../product/component/color-list-piker';
import { SizeChip } from '../../../../product/component/size-chip';
import { Quantity } from '../../../../product/component/quantity/index';

import { IPropsItemProduct } from './props.item-product';
import './style.scss';
import { addOutline, removeSharp } from 'ionicons/icons';
import apis from '../../../../../services';

export const ItemProduct: React.FC<IPropsItemProduct> = React.memo(
    ({
        price,
        name,
        qty,
        options,
        optionData,
        dataStorage,
        base_image,
        productStock,
        idItemCart,
        refresh,
        lang,
        onClick,
        isLogin,
        increaseQuantity,
        decreaseToStatsCart,
        decreaseQuantity,
    }) => {
        const [qunitiy, setQunitiy] = useState(qty ?? 1);

        const [present, dismiss] = useIonLoading();
        const handelIncrease = (id: string) => {
            if (isLogin) {
                present({
                    message: 'Loading...',
                    duration: 3000,
                });

                if (
                    (!productStock && productStock !== 0) ||
                    qunitiy < productStock
                ) {
                    apis.updateItemQty(id, { qty: qunitiy + 1 })
                        .then(() => {
                            dismiss();
                            setQunitiy(qunitiy + 1);
                        })
                        .catch(() => {
                            /*     setQunitiy(qunitiy + 1); */
                        })
                        .finally(() => {
                            dismiss();
                            refresh();
                        });
                }
            } else {
                if (
                    (!productStock && productStock !== 0) ||
                    qunitiy < productStock
                ) {
                    increaseQuantity(dataStorage);
                    setQunitiy(qunitiy + 1);
                }
            }
        };
        const handelDecrease = (id: string) => {
            if (isLogin) {
                if (qunitiy > 1) {
                    present({
                        message: 'Loading...',
                        duration: 3000,
                    });
                    apis.updateItemQty(id, { qty: qunitiy - 1 })
                        .then(() => {
                            setQunitiy(qunitiy - 1);
                        })
                        .catch(() => {
                            /*   setQunitiy(qunitiy + 1); */
                        })
                        .finally(() => {
                            dismiss();
                            refresh();
                        });
                } else {
                    apis.deleteCartItem(id)
                        .then(() => {
                            decreaseToStatsCart();
                        })
                        .finally(() => {
                            refresh();
                        });
                }
            } else {
                if (qunitiy >= 1) {
                    if (qunitiy == 1) {
                        decreaseToStatsCart();
                    }

                    decreaseQuantity(dataStorage);
                    setQunitiy(qunitiy - 1);
                }
            }
        };
        return (
            <IonItem
                onClick={onClick && onClick}
                color="none"
                className="item-cart-product"
                lines="none"
            >
                <IonThumbnail slot="start" className="thumbnail">
                    <img src={base_image} />
                </IonThumbnail>
                <IonLabel>
                    <h2 className="name-product">{name}</h2>
                    <p>
                        <span className="category">Dresses</span>
                        <span
                            className={lang === 'ar' ? 'options-ar' : 'options'}
                        >
                            {' '}
                            {
                            options.map((data: any, index: number) => {
                                var selectedValueLabel = data.values[0]?.label;
                                if(optionData && data.values && optionData[data.id]){
                                    var selectedDataTmp = data.values.find((valData:any) => {
                                        return valData.id === optionData[data.id];
                                      });
                                      if(selectedDataTmp){
                                        selectedValueLabel = selectedDataTmp?.label;
                                      }
                                    }
                                return data.name == 'color' ? (
                                    <ColorListPiker
                                        key={index}
                                        color={selectedValueLabel}
                                    />
                                ) : (
                                    <SizeChip
                                        key={index}
                                        name={selectedValueLabel}
                                        state="active"
                                    ></SizeChip>
                                );
                            })}
                        </span>
                    </p>
                    <p className="padding-top-15">
                        <span className="price">{price}</span>
                        <span
                            style={{ float: lang === 'ar' ? 'left' : 'right' }}
                            className="qunitiy"
                        >
                            <div className="circal">
                                <IonButton
                                    onClick={(e) => {
                                        e.isPropagationStopped();
                                        e.stopPropagation();
                                        handelDecrease(idItemCart);
                                    }}
                                    className={
                                        lang === 'ar' ? 'button-ar' : 'button'
                                    }
                                    fill="clear"
                                    color="none"
                                >
                                    {' '}
                                    <IonIcon icon={removeSharp} />
                                </IonButton>
                            </div>

                            <IonLabel className="qunitiy-number">
                                {qunitiy}
                            </IonLabel>
                            <div className="circal">
                                {' '}
                                <IonButton
                                    onClick={(e) => {
                                        e.isPropagationStopped();
                                        e.stopPropagation();
                                        handelIncrease(idItemCart);
                                    }}
                                    className={
                                        lang === 'ar' ? 'button-ar' : 'button'
                                    }
                                    fill="clear"
                                    color="none"
                                >
                                    <IonIcon icon={addOutline} />
                                </IonButton>{' '}
                            </div>
                        </span>
                    </p>
                </IonLabel>
            </IonItem>
        );
    }
);
