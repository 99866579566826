import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import CardItem from '../model/card-item';
import { IPropsBestSelling } from './props.best-selling';
import './style.scss';

const BestSelling: React.FC<IPropsBestSelling> = ({ item, lang }) => {
    enum DIRECTION {
        HORIZONTAL = 'horizontal',
        VERTICAL = 'vertical',
    }

    return (
        <>
            <IonGrid id="Sliderer">
                <IonRow>
                    <div className="scrolling-wrapper">
                        {item &&
                            item.map((data, index: number) => (
                                <div
                                    key={index}
                                    className="card" /* sizeMd="3" sizeLg="3" size="6" */
                                >
                                    <CardItem
                                        lang={lang}
                                        slug={data.slug}
                                        type="lg"
                                        name={data.name}
                                        category={
                                            data.categories
                                                ? data.categories[0]?.name
                                                : ''
                                        }
                                        img={data.base_image?.path ?? ''}
                                        is_dis={
                                            data.special_price == null ? 0 : 1
                                        }
                                        is_wishlist={false}
                                        price={
                                            data.price.inCurrentCurrency.amount
                                        }
                                        price_discount={
                                            data.selling_price.inCurrentCurrency
                                                .amount
                                        }
                                    />
                                </div>
                            ))}
                    </div>
                </IonRow>
            </IonGrid>
        </>
    );
};
export default React.memo(BestSelling);
