import {
    IonContent,
    IonHeader,
    IonList,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HeaderSubPage } from '../../layout/Header/header-sub-page';
import { LoadingCenter } from '../../layout/loading-center';
import { getGlobalHeaders } from '../../modules/global-data';
import apis from '../../services';
import { MenuOrder } from './component/menu-orders';
import { OrderItem } from './component/order-item';
import './style.scss';
const MyOrders: React.FC = () => {
    const { t } = useTranslation('common');
    const [orders, setOrders] = useState<any>({
        data: [],
        loading: true,
        errorMessage: null,
    });

    useEffect(() => {
        apis.getOrders()
            .then((response) => {
                setOrders({
                    data: response.data.orders,
                    loading: false,
                    errorMessage: null,
                });
            })
            .catch((error) => {
                setOrders({
                    loading: false,
                    errorMessage: 'ERROR',
                });
            });
    }, []);
    return (
        <IonPage className="my-orders">
            <HeaderSubPage
                title={t('My_Orders')}
                defaultHref={'/tab/profile'}
            />
            {/*    {orders.loading == false ? (
        <IonHeader mode="md" color="none" className="header-segemnt">
          <IonToolbar mode="md" color="none">
            <MenuOrder />
          </IonToolbar>
        </IonHeader>
      ) : (
        ""
      )} */}

            <IonContent className="content-color" fullscreen>
                {orders.loading == false ? (
                    orders.errorMessage == null ? (
                        <IonList className="list" color="none">
                            {orders.data?.data?.length > 0
                                ? orders.data.data.map(
                                      (data: any, index: number) => {
                                          return (
                                              <OrderItem
                                                  images={data.products?.map(
                                                      (data: any) => {
                                                          return data?.product
                                                              ?.base_image
                                                              ?.path;
                                                      }
                                                  )}
                                                  lang={
                                                      getGlobalHeaders()
                                                          .lang_tag ?? 'ar'
                                                  }
                                                  id={data.id}
                                                  status={data.status}
                                                  key={index}
                                                  name={
                                                      t('Order_Number_') +
                                                      data.id
                                                  }
                                                  price={Number(
                                                      data.total.amount
                                                  )}
                                              />
                                          );
                                      }
                                  )
                                : t('Empty')}
                        </IonList>
                    ) : (
                        t('Error_Occurred')
                    )
                ) : (
                    <LoadingCenter />
                )}
            </IonContent>
        </IonPage>
    );
};
export default React.memo(MyOrders);
