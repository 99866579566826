import { useIonModal } from "@ionic/react";
import React, { forwardRef, useImperativeHandle, useState } from "react";

export const Modal: React.FC<any> = forwardRef((props, ref) => {
  const { Body } = props;
  useImperativeHandle(ref, () => {
    return {
      showModal: showModal,
    };
  });

  const showModal = () => {
    present({
      cssClass: "my-class",
    });
  };
  const count = 0;

  const handleIncrement = () => {};

  const handleDismiss = () => {
    dismiss();
  };
  const [present, dismiss] = useIonModal(Body, {
    props,
    backdropDismiss: false,
    keyboardClose:false,
    onDismiss: handleDismiss,
    onIncrement: handleIncrement,
  });

  return <></>;
});
