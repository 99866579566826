import { body } from 'ionicons/icons';
import { axiosApi } from '.';
interface CouponParmeter {
    coupon: string;
}
interface ShippingMethodParmeter {
    shipping_method: string;
}
interface ShowItemToCart {
    product_id: number;
    qty: number;
    options?: any;
}
interface BodyAddItemToCard {
    products: ShowItemToCart[];
}
type bodyEditQty = {
    qty: number;
};
const addCoupon = (body: CouponParmeter) => {
    return axiosApi.post('/cart/coupon', body);
};

const addItemToCart = (body: ShowItemToCart) => {
    return axiosApi.post('/cart/items', body);
};

const getCartDetails = () => {
    return axiosApi.post('/cart');
};
const deleteCoupon = () => {
    return axiosApi.delete('/cart/coupon');
};
const changeShippingMethod = (body: ShippingMethodParmeter) => {
    return axiosApi.post('/cart/shipping-method', body);
};
const updateItemQty = (cartItemId: string, body: bodyEditQty) => {
    return axiosApi.put(`/cart/items/${cartItemId}`, body);
};
const deleteCartItem = (cartItemId: string) => {
    return axiosApi.delete(`/cart/items/${cartItemId}`);
};
const addItemsToCart = (body: BodyAddItemToCard) => {
    return axiosApi.post('/cart/items', body);
};

const cartApis = {
    addCoupon,
    deleteCoupon,
    changeShippingMethod,
    deleteCartItem,
    getCartDetails,
    addItemToCart,
    updateItemQty,
    addItemsToCart,
};
export default cartApis;
