import { IonIcon, IonInput, IonItem, IonLabel, IonSelect } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React from 'react';
import { Input } from '../input-floating';

import { IPropsItemInput } from './props.item-input';
import './style.scss';
const ItemInput: React.FC<IPropsItemInput> = ({
    error,
    required,
    isIcon,
    lange,
    icon,
    lable,
    lines,
    type,
    defaultValue,
    register,
    disabledItem,
    ...props
}) => {
    return (
        <IonItem
            disabled={disabledItem}
            className={`item-input ${disabledItem ? 'disenable' : ''} ${
                error ? ' error' : ' '
            }`}
            lines={lines && lines}
        >
            <Input
                lange={lange && lange}
                required={required}
                register={register}
                lable={lable}
                defaultValue={defaultValue && defaultValue}
                {...props}
                /*   value={defaultValue&&defaultValue} */ type={
                    type
                } /*  className="input " */
            />
            {isIcon ? (
                <IonIcon className="icon" slot="end" icon={icon}></IonIcon>
            ) : (
                ''
            )}
        </IonItem>
    );
};
export default React.memo(ItemInput);
