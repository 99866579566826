import { IonContent, IonItem, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { HeaderSubPage } from "../../layout/Header/header-sub-page";
import { LoadingCenter } from "../../layout/loading-center";
import apis from "../../services";
import { ItemReview } from "./components/item-review";
import { EmptyReviews } from "../../layout/errors/empty-reviews";
import { useTranslation } from "react-i18next";
const MyReviews: React.FC = () => {
  const { t } = useTranslation("common");
  /*   const listReview = [
    {
      name: "Louise Holt",
      dataTime: "21 April 2021",
      star: 4,
      image: "https://7rkat.cc/wp-content/uploads/2019/09/1023.jpg",
      content:
        "There was also a difference in the types of dresses awarded at these.",
    },
  ]; */
  
  const [review, setReview] = useState<any>({
    data: [],
    loading: true,
    errorMessage: null,
  });
  useEffect(() => {
    apis
      .getReviews()
      .then((respone) => {
        setReview({ data: respone.data, loading: false, errorMessage: null });
      })
      .catch((error) => {
        setReview({ loading: false, errorMessage: "Error Message" });
      });
  }, []);
  return (
    <IonPage>
      <HeaderSubPage
        defaultHref="/tab/profile"
        title={t("My_Reviews")}
      ></HeaderSubPage>
      <IonContent className="content-color">
        {review.loading == false ? (
          review.data?.reviews?.data?.length > 0 ? (
            review.data?.reviews.data.map((data: any, index: number) => {
              return (
                <ItemReview
                  key={index}
                  name={data.name}
                  dataTime={data.dataTime}
                  image={data.image}
                  starNumber={data.star}
                  content={data.content}
                />
              );
            })
          ) : (
            <EmptyReviews />
          )
        ) : (
          <LoadingCenter />
        )}
      </IonContent>
    </IonPage>
  );
};
export default React.memo(MyReviews);
