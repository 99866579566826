import {
    IonButton,
    IonButtons,
    IonCard,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
} from '@ionic/react';
import React from 'react';
import { IPropsCardItemBig } from './props.card-item-big';
import { CURRENCY_LI } from '../../../../../../../helpers/currency';
import Button from '../../../../../../../components/button/index';
import {
    chatbubbleEllipsesOutline,
    heartOutline,
    shareSocialOutline,
} from 'ionicons/icons';
import { getLangTag, GET_IMAGE_BY_SIZE } from '../../../../../../../modules/global-data';
import './style.scss';
const CardItemBig: React.FC<IPropsCardItemBig> = ({
    img,
    name,
    price,
    is_discount,
    discount_price,
    category,
    slug,
    lang,
}) => {
    const iconToolbar = [
        {
            icon: heartOutline,
        },

        {
            icon: chatbubbleEllipsesOutline,
        },
        {
            icon: shareSocialOutline,
        },
    ];
    return (
        <IonCard
            routerLink={`/page/product/${slug}`}
            button
            color="none"
            className="card"
        >
            <div>
                <IonImg className="img-grid img-card-item" src={GET_IMAGE_BY_SIZE(img,"small")} />
                <IonItem color="none" lines="none" className="item-setting ">
                    {/*                     <Button color="none" height={33} text="Show" />
                     */}{' '}
                    {iconToolbar.map((data, index) => {
                        return (
                            <IonButtons
                                className="button-icon"
                                key={index}
                                slot="end"
                            >
                                <IonButton>
                                    <IonIcon
                                        slot="icon-only"
                                        icon={data.icon}
                                    />
                                </IonButton>
                            </IonButtons>
                        );
                    })}
                </IonItem>
            </div>
            <IonItem lines="none" color="none" className="item">
                <IonLabel>
                    <h2 className="title" style={{ paddingBottom: '5px' }}>
                        {name}
                    </h2>
                    <p>
                        <span className="price">
                            {is_discount == 0 ? price : discount_price}
                        </span>

                        {is_discount == 1 ? (
                            <span
                                className="discount"
                                style={{ paddingRight: '15px' }}
                            >
                                <del> {is_discount == 1 && price}</del>
                            </span>
                        ) : (
                            ''
                        )}
                        <span
                            className="category"
                            style={{
                                float: lang === 'ar' ? 'left' : 'right',
                            }}
                        >
                            {category}
                        </span>
                    </p>
                </IonLabel>
            </IonItem>
        </IonCard>
    );
};
export default React.memo(CardItemBig);
